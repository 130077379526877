/// 验证码
import { CommonData } from "./Common";
import { ResponseGenerator } from "../api/types";
import NewHttpNet from "../api/HttpNetMg";
import { Toast } from "antd-mobile";

// 发送获取验证码
export function SendGetCode(ndata: any) {
    return NewHttpNet({
        url: "/sms/sendcode",
        method: "post",
        data: ndata,
    });
}

// 发送邮箱验证码
function sendEmailCodeAPI(data: any) {
    return NewHttpNet({
        url: '/email/sendcode',
        method: 'post',
        data: { ...data },
    })
}


export default class VerificationCode {
    private static instance: any = null;
    public static get Instance(): VerificationCode {
        if (this.instance == null) {
            this.instance = new VerificationCode();
        }
        return this.instance;
    }

    public DownTimeNum = 0;
    public Smstoken: string;

    constructor() {
        this.Smstoken = '';
    }

    private SendCodeDownTime() {
        if (this.DownTimeNum > 0) {
            setTimeout(() => {
                this.DownTimeNum--;
                CommonData.Code = this;
                this.SendCodeDownTime();
            }, 1000);
        }
    }

    // 获取验证码
    public async SendGetCode(phonenumber: string, smstype: number) {
        CommonData.Common.RequestLoading = true;
        const resData: ResponseGenerator = await SendGetCode({ phonenumber, smstype }).catch(e => e);
        CommonData.Common.RequestLoading = false;
        if (resData.code === 0) {
            Toast.show("Verification code sent successfully!")

            // CommonData.Common.PopTips = "Verification code sent successfully!";
            this.Smstoken = resData.data.token;
            this.DownTimeNum = 60;
            this.SendCodeDownTime();
        }
        else {
            Toast.show(resData.msg)
            // CommonData.Common.PopTips = resData.msg;
        }
    }

    // 请求邮箱验证码
    public async SendEmailCode(email: string, codetype: number) {
        const resData: ResponseGenerator = await sendEmailCodeAPI({ codetype, email }).catch(error => error);
        if (resData.code === 0) {
            Toast.show('Successfully sent email verification code!')

            // CommonData.Common.PopTips = 'Successfully sent email verification code!';
            this.Smstoken = resData.data.token;
            this.DownTimeNum = 60;
            this.SendCodeDownTime();
        }
        else {
            Toast.show(resData.msg)

            // CommonData.Common.PopTips = resData.msg;
        }
    }
}