import { useTranslation } from 'react-i18next';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
import { CommonData } from '../../../Common/Common';
import { useEffect, useMemo } from 'react';
/** images */
const gift_icon = '/images/activityModule/noviceGift/novice_gift_icon.png';
const closeIcon = '/images/activityModule/close.png';
const game_type_btn_bg_01 = '/images/home/gametype/game_type_btn_bg_01.png';

const className = classnames.bind(styles);

export default function FirstCharge() {
  const firstpay = CommonData.FirstPay;
  const hidePop = ()=>{
    firstpay.IsShow = false;
  };
  const { t } = useTranslation()
  const tipstr = useMemo(()=>{
    const payconfig = firstpay.Config;
    if(payconfig){
      return t('signRule.signRuleTitle').replace('%amount1', payconfig.RechargeMin).replace('%amount2', payconfig.RechargeMax).replace('%amount3', (payconfig.BonusPoint*100).toString()).replace('%amount4', payconfig.BonusMax);
    }
    return '';
  },[firstpay.Config]);

  useEffect(()=>{
    firstpay.GetConfig();
  },[]);

  const OpenWallet = ()=>{
    firstpay.IsShow = false;
    CommonData.Common.GoBack('wallet');
  }

  return (
    <div className={className('popop_box')}>
      <div className={className('popup_content')}>
        <div className={className('close_icon')} onClick={hidePop}>
          <img src={closeIcon} />
        </div>
        <div className={className('gift_icon')}>
          <img src={gift_icon} alt='FirstPayGive'/>
        </div>
        <div className={className('register_message')}>
          {tipstr}
        </div>
        <div className={className('register_confirm_btn')} onClick={OpenWallet}>
          <img src={game_type_btn_bg_01} alt='game_type_btn_bg_01'/>
          <span>{t('wallet.confirm')}</span>
        </div>
      </div>
    </div>
  )
}
