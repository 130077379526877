export function validUsername (value: string) {
  /** 長度8-20字元，只能使用英文或數字，不能有特殊字元 */
  const regex = /^[a-zA-Z\d]{8,20}$/
  return regex.test(value)
}

export function validPassword (value: string) {
  // const regex = /^[A-Z](?=.*[a-z])(?=.*\d)[a-zA-Z\d]{7,20}$/       // 長度8-20字元，首字母必須為大寫，要有一個小寫字母、一個數字，不能有特殊字元
  // const regex = /^(?=.*[a-z]|[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/      // 長度8-20字元 至少要有一個字母和一個數字，不能有特殊字元
  const regex = /^[a-zA-Z\d]{8,20}$/                                  // 長度8-20字元 只能使用英文或數字，不能有特殊字元
  return regex.test(value)
}

export function validConfirmPassword (password: string, confirmPassword: string) { // 确认密码验证
  return password === confirmPassword
}

export function validTextLength (value: string) {
  /** 長度8-20字元 */
  const regex = /^.{8,20}$/
  return regex.test(value)
}

export function validContainsSymbols (value: string) {
  /** 不能有特殊字元 */
  const regex = /^([a-z]|[A-Z]|[0-9])+$/
  return regex.test(value)
}

export function validContainsEnglishNumbers (value: string) {
  /** 是否包含英文數字 */
  const regex = /(?=.*[a-zA-Z])(?=.*\d)/
  return regex.test(value)
}

export function validFirstWord (value: string) {
  /** 第一個字母是否為英文 */
  const regex = /^[a-zA-Z]/
  return regex.test(value)
}

export function validFirstWordCapital (value: string) {
  /** 第一個字母是否為英文大寫 */
  const regex = /^[A-Z]/
  return regex.test(value)
}

export function validPhoneNum (value: string) {
  /** 只能輸入0-9的數字 */
  const regex = /^[+]\d{7,}$/
  // const regex = /^\d{4,}$/
  return regex.test(value)
}

export function validNewPhoneNum (value: string) {
  /** 只能輸入0-9的數字 */
  const regex = /^\d{7,12}$/
  return regex.test(value)
}

// 区号规则判断
export function validareacode(value: string){
  const regex = /^[+]\d{2,}$/
  return regex.test(value)
}

export function validAmount (value: any) {
  /** 驗證為正整數或小數，整數時首字元不能為0 */
  // const regex = /^(?!0\d*(\.0+)?$)\d+?(\.\d{1,2})?$/;
  // if (regex.test(value)) {
  //   return true;
  // } else {
  //   return false;
  // }
  if (!value) return false
  if (value > 1 && String(value).substring(0, 1) === '0') {
    return false
  }
  return parseFloat(value)
}

export function validEmail (value: string) {
  /** 中間一定要有'@'，'@'後面為字串加'.'，'.'後面為2到4字元的英文組成 */
  const regex = /^([A-Za-z\d_\-\.])+\@([A-Za-z\d_\-\.])+\.([A-Za-z]{1,4})$/
  return regex.test(value)
}

export function validString (value: string) {
  /** 只能輸入英文或數字 */
  const regex = /^[A-Za-z0-9]+$/
  return regex.test(value)
}

export function validPostcode (value: string) {
  /** 只能輸入0-9的數字，最少5字元 */
  const regex = /^[\d\s]{4,}$/
  return regex.test(value)
}

export function validDate (value: string) {
  /** 驗證"日"，最多2字元 */
  const regex = /^[1-9]{1}[0-9]{0,1}$/
  return regex.test(String(Number(value)))
}

export function validMonth (value: string) {
  /** 驗證"月"，最多2字元 */
  const regex = /^[1-9][0-2\s]{0,1}$/
  return regex.test(String(Number(value)))
}

export function validYear (value: string) {
  /** 驗證"年"，長度固定為4字元 */
  const regex = /^[1-2][\d]{3}$/
  return regex.test(String(Number(value)))
}

export function validEnglish (value: string) {
  /** 只能輸入英文 */
  const regex = /^.[A-Za-z]+$/
  return regex.test(value)
}

export function validBirthday (day: any, month: any) {
  const oddMonth = [1, 3, 5, 7, 8, 10, 12]
  const eveMonth = [4, 6, 9, 11]
  const feb = [2]
  const dayNum = Number(day);
  const monthNum = Number(month);

  if (feb.includes(monthNum) && dayNum < 30) {
    return true
  } else if (oddMonth.includes(monthNum) && dayNum < 32) {
    return true
  } else if (eveMonth.includes(monthNum) && dayNum < 31) {
    return true
  } else {
    return false
  }
}

// 根据生日计算年龄
export function validAge (year: string, month: string, day: string) {
  let returnAge;
  const d = new Date();
  const nowYear = d.getFullYear();
  const nowMonth = d.getMonth() + 1;
  const nowDay = d.getDate();
  if(nowYear === Number(year)){
    returnAge = 0; // 同年 则为0岁
  } else{
    const ageDiff = nowYear - Number(year) ;                      // 年之差
    if(ageDiff > 0){
      returnAge = nowMonth === Number(month) ?                    // 月判断
        (nowDay - Number(day) < 0 ? ageDiff - 1 : ageDiff) :      // 日判断
        (nowMonth - Number(month) < 0 ? ageDiff - 1 : ageDiff);
    } else {
      returnAge = -1;       // 返回-1 表示出生日期输入错误 晚于今天
    }
  }
  return returnAge >= 18;   // 返回是否大于等于18岁
}

export function validNumber (value: string) {
  /** 只能輸入數字 */
  const regex = /^[0-9]+$/
  return regex.test(value)
}

// isJson
export function isJSON(value: any) {
  if (typeof value === 'string') {
    try {
      const obj = JSON.parse(value)
      return typeof obj === 'object' && obj
    } catch (e) {
      console.log("error: " + value + "!!!" + e)
      return false
    }
  }
  console.log("this is not a json string")
}

