import { useTranslation } from 'react-i18next'
import { useEffect, useRef, createRef, useState } from 'react'
import useSignUpMain from '../../../../hooks/login/useSignUpMain'
import useSignUpFormValid from '../../../../hooks/login/useSignUpFormValid'
import classnames from 'classnames/bind'
import style from './index.module.scss'
import InputHintPop from '../../../../components/InputHintPop'
import { CommonData } from '../../../../Common/Common';
import useSignNameInput from '../../../../hooks/login/useSignNameInput'
import { Radio, Toast } from 'antd-mobile'
import UserInfoClass from '../../../../Common/UserInfoData'
import NewHttpNet from '../../../../api/HttpNetMg'
import { MD5 } from 'crypto-js'
import { useNavigate } from 'react-router-dom'
const className = classnames.bind(style)


// 发送手机验证码
function sendPhoneCodeAPI(data: any) {
  return NewHttpNet({
    url: '/sms/sendcode',
    method: 'post',
    data: { ...data },
  })
}

// 发送邮箱验证码
function sendEmailCodeAPI(data: any) {
  return NewHttpNet({
    url: '/email/sendcode',
    method: 'post',
    data: { ...data },
  })
}
// 用户注册
function sendRegist(data: any) {
  return NewHttpNet({
    url: '/user/regist',
    method: 'post',
    data: {
      ...data
    }
  })
}



const SignUpLayout = ({ reference }: any) => {
  const codeData = CommonData.Code;
  /* i18n */
  const { t } = useTranslation()

  // 国际区号添加
  const [catway, setcatway] = useState(1);
  const [toggleSec, settoggleSec] = useState(false);
  const [interDomain, setinterDomain] = useState(63);



  // 函数：开始倒计时
  const startCountdown = () => {
    if (isCounting) return; // 防止在倒计时进行中再次点击

    if (!inputAccount) {
      Toast.show({
        content: t('formValidate.plsEnterPhoneOrEmail'),
        maskClassName: 'custom-tip',
      })
      return;
    }

    // 检查是否全为数字且长度等于10位
    if (/^\d+$/.test(inputAccount)) {
      if (inputAccount.length != 11) {
        Toast.show({
          content: t('formValidate.phoneLengthShort'),
          maskClassName: 'custom-tip',
        })
        return
      } else {
        sendPhoneCodeAPI({ 'smstype': 1, 'phonenumber': interDomain + inputAccount }).then((ret) => {
          if (ret.code == 0) {
            console.log(verifyToken)
            setverifyToken(ret.data.token)

          } else {
            Toast.show({
              content: ret.msg,
              maskClassName: 'custom-tip',
            })
          }
        })
      }
    } else if (!/@/.test(inputAccount)) {
      Toast.show({
        content: t('formValidate.emailFormatErr'),
        maskClassName: 'custom-tip',
      })
      return
    } else if (/@/.test(inputAccount)) {
      sendEmailCodeAPI({ 'codetype': 1, 'email': inputAccount }).then((ret) => {
        if (ret.code == 0) {
          console.log(verifyToken)
          setverifyToken(ret.data.token)
          Toast.show({
            content: t('formValidate.hadSentCode'),
            maskClassName: 'custom-tip',
          })
        } else {
          Toast.show({
            content: t('formValidate.failSentCode'),
            maskClassName: 'custom-tip',
          })
        }
      })
    }



    Toast.show({
      content: t('formValidate.hadSentCode'),
      maskClassName: 'custom-tip',
    })
    setCountdown(60);
    setIsCounting(true);


  };



  // form logic
  const [inputAccount, setInputAccount] = useState('');
  const [isAgreed, setIsAgreed] = useState(true);
  const [validateCode, setvalidateCode] = useState('');
  const [recommendCode, setrecommendCode] = useState('');
  const [countdown, setCountdown] = useState(0);
  const [isCounting, setIsCounting] = useState(false);
  const [inputPwd, setinputPwd] = useState('');
  const [verifyToken, setverifyToken] = useState('');
  const urlParams = new URLSearchParams(window.location.search);

  //form validate
  const onCheckRegister = () => {
    if (inputAccount == '') {
      Toast.show({
        content: t('formValidate.plsEnterPhoneOrEmail'),
        maskClassName: 'custom-tip',
      })
      return
    }
    // 检查是否全为数字且长度等于10位
    if (/^\d+$/.test(inputAccount)) {
      if (inputAccount.length != 11) {
        Toast.show({
          content: t('formValidate.plsEnter11Phone'),
          maskClassName: 'custom-tip',
        })
        return
      }
    } else if (!/@/.test(inputAccount)) {
      Toast.show({
        content: t('formValidate.emailFormatErr'),
        maskClassName: 'custom-tip',
      })
      return
    }


    if (inputPwd == '') {
      Toast.show({
        content: t('login.plsInputPwd'),
      })
      return
    }
    if (inputPwd.length < 8) {
      Toast.show({
        content: t('formValidate.plsEnter8Pwd'),
      })
      return
    }
    if (validateCode == '') {
      Toast.show({
        content: t('login.verifyCodeDesc'),
      })
      return
    }
    if (validateCode.length != 6) {
      Toast.show({
        content: t('formValidate.plsEnter6VerifyCode'),
      })
      return
    }
    if (!isAgreed) {
      Toast.show({
        content: t('formValidate.plsTickTerms'),
      })
      return
    }

    if (/@/.test(inputAccount)) {
      sendRegist({
        "agentid": urlParams.get('reference') ?? 0,
        "loginname": inputAccount,
        "parentid": parseInt(recommendCode),
        "password": MD5(inputAccount + inputPwd).toString(),
        "phonenumber": inputAccount,
        "regway": /@/.test(inputAccount) ? 3 : 0,
        "smscode": validateCode,
        "smstoken": verifyToken
      }).then(ret => {
        Toast.show({
          content: ret.msg,
        })
        if (ret.code == 0) {
          // if (/@/.test(inputAccount)) {
          UserInfoClass.Instance.UserLogin({ loginname: inputAccount, password: inputPwd, regway: 3 });
          // } else {
          //   UserInfoClass.Instance.UserLogin({ loginname: interDomain + inputAccount, password: inputPwd, regway: 0 });
          // }
        }
      })
    } else {
      sendRegist({
        "agentid": urlParams.get('reference') ?? 0,
        "loginname": interDomain + inputAccount,
        "parentid": parseInt(recommendCode),
        "password": MD5(interDomain + inputAccount + inputPwd).toString(),
        "phonenumber": interDomain + inputAccount,
        "regway": /@/.test(inputAccount) ? 3 : 0,
        "smscode": validateCode,
        "smstoken": verifyToken
      }).then(ret => {
        Toast.show({
          content: ret.msg,
        })
        if (ret.code == 0) {
          // if (/@/.test(inputAccount)) {
          //   UserInfoClass.Instance.UserLogin({ loginname: inputAccount, password: inputPwd, regway: 0 });
          //   window.location.reload();
          // } else {
          UserInfoClass.Instance.UserLogin({ loginname: interDomain + inputAccount, password: inputPwd, regway: 0 });
          // window.location.reload();
          // }
        }
      })
    }

  }

  // 使用 useEffect 来处理倒计时逻辑
  useEffect(() => {
    let timer: any;
    if (isCounting && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsCounting(false); // 倒计时结束
    }

    return () => clearInterval(timer); // 清理定时器
  }, [isCounting, countdown]);

  //切换密码显示隐藏
  let [toggleShow, setToggleShow] = useState(false);


  return (
    <>
      <div className={className('register_wrap')}>
        <div className={className('df', 'jcb', 'aic', 'mb30', 'h52', 'fff', 'pr40', 'logintype')} >
          <div onClick={() => { setcatway(1) }} className={className('df', 'aic')} >
            <span className={className('wh43', 'cat-type', catway == 1 ? 'sel' : '')} ></span>
            <span className={className('fz28', catway == 1 ? 'c8079F7' : '')} >{t('remain.phoneway')}</span>
          </div>
          <div onClick={() => { setcatway(2) }} className={className('df', 'aic')} >
            <span className={className('wh43', 'cat-type', catway == 2 ? 'sel' : '')} ></span>
            <span className={className('fz28', catway == 2 ? 'c8079F7' : '')} >{t('remain.emailway')}</span>
          </div>
        </div>
        <p className={className('fz28', 'fff', '')}>{catway === 1 ? t('remain.phoneNum') : t('remain.emailNum')}</p>
        {
          catway === 1 ?
            <div className={className('df', 'aic', 'bblack', 'bra15', 'mt31')} >
              <div onClick={() => settoggleSec(!toggleSec)} className={className('f1', 'posr', 'df', 'aic', 'pl20', 'left-drawdown')} >
                <span className={className('fz28', 'fff', 'mr35')} >+ {interDomain}</span>
                <span className={className('iconfont', 'icon-gengduo04-copy', 'fz28', 'fff', toggleSec ? 'transformTop' : 'transformBottom')} ></span>
                <div className={className('posa', toggleSec ? '' : 'dn', 'fz28', 'domainCode')} >
                  <div onClick={() => { settoggleSec(false); setinterDomain(63) }} className={className('mb70')} >+63</div>
                  <div onClick={() => { settoggleSec(false); setinterDomain(86) }} className={className('mb70')} >+86</div>
                  <div onClick={() => { settoggleSec(false); setinterDomain(96) }} className={className('mb70')} >+96</div>
                </div>
              </div>
              <input value={inputAccount} onChange={(e) => setInputAccount(e.target.value)} type="text" placeholder={t('login.plsInputPhone')} className={className('h64', 'fff', 'input-style', 'phone-input', 'pl20')} />
            </div> :
            <div className={className('posr', 'mt31', 'h64')} >
              <input value={inputAccount} onChange={(e) => setInputAccount(e.target.value)} type="text" placeholder={t('login.plsInputEmail')} className={className('h64', 'fff', 'input-style', 'login-form-input', 'pl20', 'posa')} />
            </div>
        }
        <p className={className('fz30', 'fff', 'mt20', 'mb20')}>{t('remain.formPwd')}</p>
        <div className={className('posr', 'h64')}>
          <input value={inputPwd} onChange={(e) => setinputPwd(e.target.value)} type={toggleShow ? 'text' : 'password'} placeholder={t('login.plsInputPwd')} className={className('fff', 'input-style', 'pl20', 'h64', 'sninput')} />
          <div className={className('posa', 'h64', 'df', 'aic', 'jce', 'eye-wrap')} >
            <span onClick={() => setToggleShow(!toggleShow)} className={className('iconfont', toggleShow ? 'icon-yingcang' : 'icon-kanjian', 'toggleShow', 'regis-eye')}></span>
          </div>
        </div>
        {/* {showPasswordHint && <InputHintPop hintState={pwdHintState} type={'password'} />} */}
        {/* 验证码 */}
        <p className={className('fz30', 'fff', 'mb20', 'mt20')}>{t('remain.verifyCode')}</p>
        <div className={className('posr', 'h64')}>
          <input value={validateCode} onChange={(e) => setvalidateCode(e.target.value)} type="text" placeholder='' className={className('fff', 'input-style', '', 'pl20', 'sninput')} />
          <div className={className('posa', 'df', 'aic', 'verify-btn')} >
            <button onClick={startCountdown} disabled={isCounting} className={className('validCode', 'regis-verify')}>{isCounting ? `${countdown}${t('login.retriveCodeAgain')}` : t('login.sendVerifyCode')}</button>
          </div>
        </div>
        {/* 推荐码 */}
        <p className={className('fz30', 'fff', 'mt20', '')}>{t('remain.recommendCode')}</p>
        <input value={recommendCode} onChange={(e) => setrecommendCode(e.target.value)} type="text" placeholder='' className={className('fff', 'input-style', 'pl20')} />
        <div className={className('df', 'aic', '', 'mt40', '')}>
          <Radio checked={isAgreed} onClick={() => setIsAgreed(!isAgreed)} />
          <span className={className('c8A', 'fz28', 'ml10', 'tick-accord')}>
            {t('login.agreeDsc')}
            <span className={className('c5C63FF', 'underline')}>&nbsp;{t('login.loginTerm')}</span>
          </span>
        </div>
        <div className={className('df', 'jcc', 'mt40')}>
          <button className={className('regis-btn', 'fz48')} onClick={onCheckRegister}>{t('login.startGame')}</button>
        </div>
        <span className={className('dib', 'split-line')}></span>
        <p className={className('fz36', 'tac', 'c8A', 'mb20')}>
          {t('login.quickLogin')}
        </p>
        <div className={className('df', 'jcc', 'pb40')}>
          <img className={className('quick-login', 'mr20')} src='/images/home/google.png' alt={'facebook'} />
          <img className={className('quick-login')} src='/images/home/facebook.png' alt={'facebook'} />

        </div>
      </div>

    </>
  )
}

export default SignUpLayout
