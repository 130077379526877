import { Toast } from "antd-mobile";
import { getItem } from "../utils/tools";
import webSocket from "../utils/webSocket";
import { CommonData } from "./Common";

class NetHeader {
    public msgid = 0;
    public userid = 0;
    public token = '';
    public data: any;
}

export enum NetID {
    MSG_ID_TICK = 0, // 心跳
    MSG_ID_LOGIN = 1, // 登录
    MSG_ID_RELOGIN = 2, // 重连
    MSG_ID_RECHARGE = 3, // 充值成功
    MSG_ID_WITHDRAW = 4, // 提现申请订单推送
    MSG_ID_RETURNCOIN = 5, // 提现失败，退币推送
    MSG_ID_RECHARGEFUND = 7, // 下注返利，奖励
    MSG_ID_KICK = 8, // 账号被踢
    MSG_ID_ACTIVITY_BONUS = 9, //参与活动获得奖金
    MSG_ID_UPDATEINFO = 10 //更新用户信息
}

export class NetManager {
    private static instance: any = null;
    public static get Instance(): NetManager {
        if (this.instance == null) {
            this.instance = new NetManager();
        }
        return this.instance;
    }

    private netdic: Map<number, any>;
    private nsocket: any = null;
    private websocketUrl: any;
    private connectNum: number;
    private heartbeatfunc: any;
    private heartbeattime = 20000;

    constructor() {
        this.websocketUrl = process.env.NEXT_PUBLIC_WEBSOCKET;
        this.netdic = new Map<number, any>();
        this.nsocket = null;
        this.connectNum = 0;
        this.AddNet(NetID.MSG_ID_LOGIN, this.SendLoginBack, this);
        this.AddNet(NetID.MSG_ID_RELOGIN, this.SendReLoginBack, this);
        this.AddNet(NetID.MSG_ID_KICK, this.KickAndLogout, this);
        this.AddNet(NetID.MSG_ID_ACTIVITY_BONUS, this.ActivityBonus, this);
        this.AddNet(NetID.MSG_ID_UPDATEINFO, this.UpdateUserinfo, this);
    }

    public AddNet(msgid: number, backfunc: Function, source?: any) {
        this.netdic.set(msgid, source ? (data: any) => { backfunc.call(source, data) } : backfunc);
    }

    public RemoveNet(msgid: number) {
        this.netdic.delete(msgid);
    }

    public RunNet(data: NetHeader) {
        const hd = this.netdic.get(data.msgid);
        if (hd) {
            let jdata = data.data;
            hd(jdata);
        }
    }

    public OpenSocket() {
        if (this.nsocket) return;
        if (!this.websocketUrl) throw Error('Configure the NEXT_PUBLIC_WEBSOCKET in the env file');
        const userInfo = CommonData.UserInfoData;
        if (userInfo) {
            this.nsocket = webSocket(this.websocketUrl, {
                onOpen: () => {
                    console.log("=====>>>socket连接成功校userInfo.uid: ", userInfo.UID);
                    this.SendLogin();
                },
                onError: () => {
                    console.log("=====>>>socket连接失败!");
                    this.nsocket = null;
                    Toast.show('Network connection failure!')
                    // CommonData.Common.PopTips = 'Network connection failure!';
                    this.connectNum++;
                    if (this.connectNum >= 3) {
                        CommonData.UserInfoData = null; // 退出登录
                        if (this.heartbeatfunc) {
                            clearTimeout(this.heartbeatfunc);
                            this.heartbeatfunc = null;
                        }
                    }
                },
                onClose: () => {
                    this.nsocket = null;
                    if (CommonData.UserInfoData) {
                        this.OpenSocket();
                    }
                }
            })
        }
    }

    private SendHeartbeat() {
        if (this.heartbeatfunc) {
            clearTimeout(this.heartbeatfunc);
        }
        this.heartbeatfunc = setTimeout(() => {
            this.heartbeatfunc = null;
            const userInfo = CommonData.UserInfoData;
            if (userInfo) {
                const netdata =
                {
                    msgid: NetID.MSG_ID_TICK,
                    userid: userInfo.UID,
                    token: userInfo.token,
                    data: ''
                }
                this.SendData(netdata);
                this.SendHeartbeat();
            }
        }, this.heartbeattime);
    }

    private SendLogin() {
        const userInfo = CommonData.UserInfoData;
        if (userInfo) {
            const netdata =
            {
                msgid: NetID.MSG_ID_LOGIN,
                userid: userInfo.UID,
                token: userInfo.token,
                data: ''
            }
            this.SendData(netdata);
        }
    }

    private SendLoginBack(data: any) {
        console.log('========>>>>SendLoginBack data: ', data);
        if (data.status !== 0) {
            console.log('webSocket login erro!');
            this.CloseSocket();
        }
        else {
            this.connectNum = 0;
            this.SendHeartbeat();
            CommonData.GameMg.GetGameRecordList();
            CommonData.Notice.GetUserMail();
            CommonData.Mail.GetUserMail();
        }
    }

    private SendReLoginBack(data: any) {
        console.log('========>>>>SendReLoginBack data: ', data);
        if (data.status === 0) {
            this.connectNum = 0;
            console.log('webSocket relogin');
            if (CommonData.UserInfoData) {
                CommonData.UserInfoData.RefreshUserInfo();
            }
        }
        else {
            console.log('webSocket relogin erro!');
            this.CloseSocket();
        }
    }

    private KickAndLogout() {
        CommonData.UserInfoData = null;
    }

    private ActivityBonus(data: any) {
        if (data.status === 0) {

        }
    }

    private UpdateUserinfo(data: any) {
        if (data.key === "key_walletmoney") {
            if (CommonData.UserInfoData) {
                CommonData.UserInfoData = { WalletMoney: data.value }
            }
        }
    }

    public CloseSocket() {
        if (this.nsocket) {
            this.nsocket.close();
            this.nsocket = null;
        }
        if (this.heartbeatfunc) {
            clearTimeout(this.heartbeatfunc);
            this.heartbeatfunc = null;
        }
        this.connectNum = 0;
    }

    public SendData(data: NetHeader) {
        if (this.nsocket) {
            this.nsocket.send(JSON.stringify(data));
        }
    }
}