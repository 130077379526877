/**
 * tool
 */
import classnames from 'classnames/bind'
import HeaderBar from '../../layout/Header'
import MobileNav from '../../layout/Nav/tas'
import styles from './index.module.scss'
//import { requestFullScreen } from '../../api/settings'
const classNames = classnames.bind(styles)

/**
 * interface
 */
interface PageContainerProps {
  children?: any,
  withHeader?: boolean,
  withFooter?: boolean
}

const PageContainer = (props: PageContainerProps) => {
  const {
    children = <></>,
    withHeader = true,
    withFooter = true
  } = props;

  return (
    <div className={classNames('page_container')}>
      {withHeader && (
        <>
          <header className={classNames('header')} >
            <HeaderBar />
          </header>
        </>
      )}

      <main
        className={classNames('main', withHeader ? 'pt94' : 'pt0', withFooter ? 'pb170' : 'pb0')}
      // style={{
      //   paddingTop: withHeader ? '60px' : '0',
      //   paddingBottom: withFooter ? '70px' : '0'
      // }}
      >
        {children}
      </main>
      {
        withFooter &&
        (<footer className={classNames('footer')}>
          <MobileNav />
        </footer>)
      }
    </div >
  )
}

export default PageContainer
