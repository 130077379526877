import { NetManager } from '../Common/NetManager';
import { WebSocketExp, WebSocketBaseFunc, WebSocketConfig } from '../types/utils/ws';

//const timeout = 1000 * 60; // 1分钟，后端推送间隔为2m
const reconnectTimeout = 1000 * 30; // 尝试重连间隔为30s
export const wsMap = new Map(); // ws 对象的集合库

/**
 * 初始化 Props
 */
function initProps(ws: WebSocketExp, wsKey?: string): void {
	// ws.onMessage = onMessageCallback; // 记录使用者的回调函数
	ws.wsKey = wsKey || 'wsKey'; // 新 ws 集成旧 ws 的 Id
	ws.timeout = null; // 声明心跳定时器
	ws.serverTimeout = null; // 声明心跳反馈定时器
	ws.debounceTimeout = wsMap.get(wsKey) ? wsMap.get(wsKey).debounceTimeout : null;
	wsMap.set(wsKey, ws); // 保存或者更新 ws 在资料库 map 的数据
}

/**
 * 清除定时器
 */
function clearTimeout(ws: WebSocketExp): void {
	ws.timeout && window.clearTimeout(ws.timeout);
	ws.serverTimeout && window.clearTimeout(ws.serverTimeout);
	ws.debounceTimeout && window.clearTimeout(ws.debounceTimeout);
}

/**
 * 重连操作
 */
function reconnect(ws: WebSocketExp): void {
	// 节流
	clearTimeout(ws); // 如果发起重连，则关闭心跳等定时器
	const callNow = !ws.debounceTimeout;
	ws.debounceTimeout = setTimeout(() => {
		ws.debounceTimeout = null;
		reconnect(ws); // 已进行过重连的，下一次必须经过 reconnectTimeout 之后才能再次发起重连
	}, reconnectTimeout);
	if (callNow) {
		console.log(`[WS RECONNECT](${ws.url})`);
	}
	wsConstructor(ws.url, { wsKey: ws.wsKey! });
}

/**
 * 收到服务器数据后的回调函数
 */
function onmessage(
	this: WebSocketExp,
	ev: MessageEvent
): void {
	try {
		console.log("==========>>>>收到服务器数据: ", ev.data);
		const res = JSON.parse(ev.data);
		NetManager.Instance.RunNet(res);
	} catch (error) {
		console.error(`[WS JSON ERROR](${this.url})`);
	}
}

/**
 * 报错时的回调函数
 */
function onerror(this: WebSocketExp, ev: Event, callbace?: WebSocketBaseFunc): void {
	console.error(`[WS ERROR](${this.url}) 异常`);
	callbace?.();
	this.close();
}

/**
 * 连接关闭后的回调函数
 */
function onclose(this: WebSocketExp, ev: CloseEvent, callbace?: WebSocketBaseFunc): void {
	// console.log(`[WS CLOSED](${this.url}) ${ev.code}: ${ev.reason}`);
	callbace?.()
	/**
	 * 1005 前端主动关掉
	 * 1006 后台主动断掉
	 */
	if ([1005, 1006].includes(ev.code)) {
		// 清除所有定时器
		clearTimeout(this);
		// 删除了 wsMap 对应存储的 ws 实例
		wsMap.delete(this.wsKey);
	} else {
		reconnect(this);
	}
}

/**
 * 连接成功后的回调函数
 */
function onopen(this: WebSocketExp, ev: Event, callbace?: WebSocketBaseFunc) {
	console.log(`ws: ${this.url} connection succeeded ~`);
	callbace?.()
}

export default function wsConstructor(url: string, config?: WebSocketConfig): WebSocketExp {
	const { onOpen, onError, onClose, wsKey } = config || {}
	const ws: WebSocketExp = new WebSocket(url);

	ws.onmessage = function (this: WebSocket, ev: MessageEvent): void {
		onmessage.call(this, ev);
	};
	ws.onerror = function (this: WebSocket, ev: Event): void {
		onerror.call(this, ev, onError);
	};
	ws.onclose = function (this: WebSocket, ev: CloseEvent): void {
		onclose.call(this, ev, onClose);
		console.log('ws.close:', ws.readyState)
	};
	ws.onopen = function (this: WebSocket, ev: Event): void {
		onopen.call(this, ev, onOpen);
		console.log('open:', ws.readyState)
	};
	// 初始化 Props，记录使用者构建 ws 所提供的信息保存在 ws 对象自身中
	initProps(ws, wsKey);

	return ws;
}
