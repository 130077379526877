import { getItem } from './tools';
import languageJson from '../locales/lang/lang.json';

export const languages = ['cn', 'en', 'pt', 'ind', 'vi'];

const captcha: Record<string, string> = {
  cn: 'zh-cn',
  en: 'en',
  pt: 'pt',
  ind: 'id',
  vi: 'vi'
}

export const i18n2recaptcha = (language: string) => {
  if (!captcha[language || 'en']) {
    throw new Error('i18n和captcha的key不一致')
  }
  return captcha[language]
}

export const getInitLanguage = (): string => {
  const language: any = getItem('language');
  const whiteList = ['en', 'cn', 'pt', 'ind', 'vi'];
  const valid = [!language, !whiteList.includes(language)];
  return valid.some(item => item) ? 'en' : language;
}

export const getlanguageJson = () => {
  const list = languageJson;
  const len = list.length;
  const result:any = {};
  languages.forEach((item) => {
    result[item] = { translation: {} };
  })
  let groupName = '';
  for (let i = 0; i < len; i++) {
    const res:any = list[i];
    // 清除分割线
    if (res?.key === 'hr--') {
      continue;
    }
    // 过滤异常数据
    if (!res.key || !res.en) {
      continue;
    }
    // 建立分组
    if (res.categroy) {
      groupName = res.categroy;
      languages.forEach((item) => {
        result[item].translation[groupName] = {}
      })
    }
    // 键值匹配
    languages.forEach((item) => {
      const group = result[item].translation[groupName];
      group[res.key] = res[item];
    })

  }
  return result;
}
