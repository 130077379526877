/**
 * tool
 */
import { useState, useEffect, useRef } from 'react';
import classnames from 'classnames/bind';
import style from './index.module.scss';
import CustomImage from '../../../components/CustomImage';
/**
 * images
 */
const ImgClose = '/images/common/close_white.png';

const className = classnames.bind(style);
interface MaskContainerProps {
	children: any,
	bgStyle?: object | undefined,
	containerStyle?: object | undefined,
	closeStyle?: object | undefined,
	closeIcon?: null | any;
	hasClose?: number,
	close?: () => void,
	outsideClick: boolean,
	showCloseIcon?: boolean
}

const MaskContainer = ({
	children,
	bgStyle = {},
	containerStyle = {},
	closeStyle = {},
	closeIcon = null,
	hasClose = 0,
	close = () => ({}),
	outsideClick = true,
	showCloseIcon = true,
}: MaskContainerProps) => {

	/* ref */
	const refMask = useRef<HTMLDivElement | null>(null);

	/* state */
	const [isOpen, setIsOpen] = useState(false);

	/* effect */
	useEffect(() => {
		if (document.body.style.overflow === 'hidden') return;
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = 'auto';
		}
	}, []);

	/* function */
	const useOutsideClick = (ref: any, callback: any) => {
		const handleClick = (e: any) => {
			if (outsideClick &&
				ref.current &&
				!ref.current.contains(e.target) &&
				!(document.getElementById('id-alert'))?.contains(e.target) &&
				!document.getElementById('id-loading') &&
				!document.getElementById('id-fairness-game-select') &&
				!document.getElementById('id-withdraw-fee') &&
				document.getElementById('id-reload-url')
			) {
				callback();
			}
		};

		useEffect(() => {
			hasClose > 0 && closeMask();
		}, [hasClose])

		useEffect(() => {
			document.addEventListener("click", handleClick);

			return () => {
				document.removeEventListener("click", handleClick);
			};
		});
	};

	useOutsideClick(refMask, () => isOpen ? close() : setIsOpen(true));

	const closeMask = (event?: any) => {
		close();
		event?.stopPropagation();
	}

	return (
		<>
			<div className={className("bg")} style={{ ...bgStyle }} id="maskContainer">
				<div ref={refMask} className={className("container")} style={{ ...containerStyle }}>
					{
						showCloseIcon &&
						<>
							{/* <div className={className("close")} style={{ ...closeStyle }}>
								<CustomImage src={closeIcon || ImgClose} alt="Close" />
							</div> */}
							<div className={className("close_mask")} onClick={e => closeMask(e)}>
								<span className={className('iconfont', 'icon-qvxiao', 'fff', 'fz28')}></span>
							</div>
						</>
					}
					{children}
				</div>
			</div>
		</>
	);
}

export default MaskContainer;
