import { useEffect, useState } from "react";
import classnames from 'classnames/bind'
import style from '../../index.module.scss'
import { Modal, Radio, Tabs, Toast } from "antd-mobile";
import Input from "../../../../components/Input";
import { CommonData } from "../../../../Common/Common";
import { ResponseGenerator } from "../../../../api/types";
import NewHttpNet from "../../../../api/HttpNetMg";
import { validEmail, validPostcode } from "../../../../utils/validation";
import { MD5 } from "crypto-js";
import { useTranslation } from 'react-i18next'


const className = classnames.bind(style)

// 发送手机验证码
function sendPhoneCodeAPI(data: any) {
    return NewHttpNet({
        url: '/sms/sendcode',
        method: 'post',
        data: { ...data },
    })
}

// 发送邮箱验证码
function sendEmailCodeAPI(data: any) {
    return NewHttpNet({
        url: '/email/sendcode',
        method: 'post',
        data: { ...data },
    })
}

//修改手机密码
function resetpasswdApi(data: any) {
    return NewHttpNet({
        url: '/user/resetpasswd',
        method: 'post',
        data: { ...data },
    })
}



const ModPassword = (props: any) => {
    const userinfo = CommonData.UserInfoData;
    const [countdown2, setCountdown2] = useState(0);
    const [isCounting2, setIsCounting2] = useState(false);
    const [inputPwd, setinputPwd] = useState('');
    const [verifyCode2, setverifyCode2] = useState('');
    const [verifyToken, setverifyToken] = useState('');
    const [inputPhone, setinputPhone] = useState('');
    /* i18n */
    const { t } = useTranslation()
    // 国际区号添加
    const [catway, setcatway] = useState(1);
    const [toggleSec, settoggleSec] = useState(false);
    const [interDomain, setinterDomain] = useState(63);

    // 函数：开始倒计时
    const startCountdown2 = () => {
        if (isCounting2) return; // 防止在倒计时进行中再次点击

        if (!inputPhone) {
            Toast.show({
                content: t('formValidate.inputPhone'),
                maskClassName: 'custom-tip',
            })
            return;
        }
        // 检查是否全为数字且长度等于11位
        if (catway == 1 && !(/^\d+$/.test(inputPhone))) {
            Toast.show({
                content: t('formValidate.plsEnter11Phone'),
                maskClassName: 'custom-tip',
            })
            return
        }


        if (catway == 1 && inputPhone.length != 11) {
            Toast.show({
                content: t('formValidate.phoneLengthShort'),
                maskClassName: 'custom-tip',
            })
            return
        }
        if (catway == 2 && !/@/.test(inputPhone)) {
            Toast.show({
                content: t('formValidate.emailFormatErr'),
                maskClassName: 'custom-tip',
            })
            return
        }
        if (catway == 1) {
            sendPhoneCodeAPI({ 'smstype': 3, 'phonenumber': interDomain + inputPhone }).then((ret) => {
                console.log(ret)
                if (ret.code == 0) {
                    console.log(verifyToken)
                    setverifyToken(ret.data.token)
                    Toast.show({
                        content: t('formValidate.hadSentCode'),
                        maskClassName: 'custom-tip',
                    })
                } else {
                    Toast.show({
                        content: t('formValidate.failSentCode'),
                        maskClassName: 'custom-tip',
                    })
                }
            })
        } else {
            sendEmailCodeAPI({ 'codetype': 3, 'email': inputPhone }).then((ret) => {
                if (ret.code == 0) {
                    console.log(verifyToken)
                    setverifyToken(ret.data.token)
                    Toast.show({
                        content: t('formValidate.hadSentCode'),
                        maskClassName: 'custom-tip',
                    })
                } else {
                    Toast.show({
                        content: t('formValidate.failSentCode'),
                        maskClassName: 'custom-tip',
                    })
                }
            })
        }

        Toast.show({
            content: t('formValidate.hadSentCode'),
            maskClassName: 'custom-tip',
        })
        setCountdown2(60);
        setIsCounting2(true);


    };

    // 使用 useEffect 来处理倒计时逻辑
    useEffect(() => {
        let timer: any;
        if (isCounting2 && countdown2 > 0) {
            timer = setInterval(() => {
                setCountdown2((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown2 === 0) {
            setIsCounting2(false); // 倒计时结束
        }

        return () => clearInterval(timer); // 清理定时器
    }, [isCounting2, countdown2]);



    const BindEmailPhone = async (ndata: any, backfunc: any = null) => {
        if (props.location != 'login' && !CommonData.UserInfoData) return

        if (!inputPhone) {
            Toast.show({
                content: t('formValidate.inputPhone'),
                maskClassName: 'custom-tip',
            })
            return;
        }
        // 检查是否全为数字且长度等于11位
        if (catway == 1) {
            if (!(/^\d+$/.test(inputPhone))) {
                Toast.show({
                    content: t('formValidate.plsEnter11Phone'),
                    maskClassName: 'custom-tip',
                })
                return
            }
            if (inputPhone.length != 11) {
                Toast.show({
                    content: t('formValidate.phoneLengthShort'),
                    maskClassName: 'custom-tip',
                })
                return
            }
        } else {
            if (!/@/.test(inputPhone)) {
                Toast.show({
                    content: t('formValidate.emailFormatErr'),
                    maskClassName: 'custom-tip',
                })
                return
            }
        }




        if (!inputPwd) {
            Toast.show({
                content: t('formValidate.inputnewPwd'),
                maskClassName: 'custom-tip',
            })
            return;
        }
        if (inputPwd.length < 6) {
            Toast.show({
                content: t('formValidate.plsEnterMorethansixCode'),
                maskClassName: 'custom-tip',
            })
            return;
        }

        if (verifyCode2 == '') {
            Toast.show({
                content: t('login.verifyCodeDesc'),
            })
            return
        }

        if (!(/^\d+$/.test(verifyCode2))) {
            Toast.show({
                content: t('formValidate.plsEnter6VerifyCode'),
                maskClassName: 'custom-tip',
            })
            return
        }
        if (verifyCode2.length < 6) {
            Toast.show({
                content: t('formValidate.plsEnter6VerifyCode'),
            })
            return
        }
        const resData: ResponseGenerator = await resetpasswdApi({
            // "loginname": userinfo.UserInfo.LoginName,
            "accountname": catway == 1 ? interDomain + inputPhone : inputPhone,
            'accounttype': catway == 1 ? 0 : 3,
            "loginname": inputPhone,
            'newpassword': catway == 1 ? MD5(interDomain + inputPhone + inputPwd).toString() : MD5(inputPhone + inputPwd).toString(),
            "smscode": verifyCode2,
            "smstoken": verifyToken,
        }).catch(error => error);
        Toast.show({
            content: resData.msg,
        })
        Modal.clear()

    }


    return (
        <div className={className('', 'fz28', '')} onClick={() => { }}>
            <p className={className('fz40', 'mb47', 'tac', 'fff')}>{props.location == 'login' ? 'Retrieve password' : 'Change Password'}</p>
            <div className={className('change-pwd')}>
                <div className={className('df', 'jcb', 'aic', 'mb30', 'h52', 'fff', 'pr40', 'logintype')} >
                    <div onClick={() => { setcatway(1) }} className={className('df', 'aic')} >
                        <span className={className('wh43', 'cat-type', catway == 1 ? 'sel' : '')} ></span>
                        <span className={className('fz28', catway == 1 ? 'c8079F7' : '')} >Phone</span>
                    </div>
                    <div onClick={() => { setcatway(2) }} className={className('df', 'aic')} >
                        <span className={className('wh43', 'cat-type', catway == 2 ? 'sel' : '')} ></span>
                        <span className={className('fz28', catway == 2 ? 'c8079F7' : '')} >Email</span>
                    </div>
                </div>
                <p className={className('fz28', 'fff', '')}>{catway === 1 ? 'Phone number' : 'Email address'}</p>
                {
                    catway === 1 ?
                        <div className={className('df', 'aic', 'bblack', 'bra15', 'mt31', 'mb30', 'h64')} >
                            <div onClick={() => settoggleSec(!toggleSec)} className={className('f1', 'posr', 'df', 'aic', 'pl20', 'left-drawdown')} >
                                <span className={className('fz28', 'fff', 'mr35')} >+ {interDomain}</span>
                                <span className={className('iconfont', 'icon-gengduo04-copy', 'fz28', 'fff', toggleSec ? 'transformTop' : 'transformBottom')} ></span>
                                <div className={className('posa', toggleSec ? '' : 'dn', 'fz28', 'domainCode')} >
                                    <div onClick={() => { settoggleSec(false); setinterDomain(63) }} className={className('mb70')} >+63</div>
                                    <div onClick={() => { settoggleSec(false); setinterDomain(86) }} className={className('mb70')} >+86</div>
                                    <div onClick={() => { settoggleSec(false); setinterDomain(96) }} className={className('mb70')} >+96</div>
                                </div>
                            </div>
                            <input value={inputPhone} onChange={(e) => { setinputPhone(e.target.value) }} className={className('pwd-input', '', '', 'fff', 'pl20', 'phone-input')} type="text" placeholder={t('login.plsInputPhone')} />
                            {/* <input value={inputPhone} onChange={(e) => setinputPhone(e.target.value)} type="text" placeholder={t('login.plsInputPhone')} className={className('h64', 'fff', 'input-style', 'phone-input', 'pl20')} /> */}
                        </div> :
                        <div className={className('posr', 'mt31', 'h64', 'mb30')} >
                            <input value={inputPhone} onChange={(e) => { setinputPhone(e.target.value) }} className={className('pwd-input', '', 'h64', 'fff', 'pl20', 'posa')} type="text" placeholder={t('login.plsInputEmail')} />
                        </div>

                    // <input value={inputPhone} onChange={(e) => setinputPhone(e.target.value)} type="text" placeholder={t('login.plsInputPhone')} className={className('h64', 'fff', 'input-style', 'login-form-input', 'pl20')} />
                }
                <p className={className('fff')}>New Password</p>
                <input value={inputPwd} onChange={(e) => { setinputPwd(e.target.value) }} className={className('pwd-input', 'mt17', 'mb28', 'fff', 'pl20', 'h64')} type="password" placeholder={t('user.inputnewPwd')} />
                <div className={className('posr')}>
                    <Input
                        label='Code'
                        placeholder='02581_'
                        value={verifyCode2}
                        changeInputValue={(value) => { setverifyCode2(value) }}
                        inputStyle={{ flex: 1, backgroundColor: "#111217" }}
                        customRightBtn={
                            <button onClick={startCountdown2} disabled={isCounting2} className={className("right_send_btn_wrap", 'posa')} >
                                {isCounting2 ? `${countdown2} ${t('login.retriveCodeAgain')}` : t('login.sendVerifyCode')}
                            </button>
                        } />
                </div>
                <div className={className('df', 'jcc', 'fdr', 'w100', 'mt40')}>
                    <button onClick={BindEmailPhone} className={className('yellow-btn', 'fz44')}>Binding</button>
                </div>
            </div>

        </div>
    )
}

export default ModPassword;