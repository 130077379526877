/**
 * tool
 */
import BeatLoader from 'react-spinners/BeatLoader'
import classnames from 'classnames/bind'
import style from './index.module.scss'
const className = classnames.bind(style)

/**
 * interface
 */
interface LoadingProps {
  show?: boolean
}

const Loading = ({ show = false }: LoadingProps) => {
  return (
    <>
      {
        show &&
        <div id='id-loading' className={className('loading_container')}>
          <div className={className('loading_block')}>
            <BeatLoader color='#ffffff' loading margin={5} size={15} />
          </div>
        </div>
      }
    </>
  )
}

export default Loading
