import { Toast } from "antd-mobile";
import NewHttpNet from "../api/HttpNetMg";
import { ResponseGenerator } from "../api/types";
import { CommonData } from "./Common";
import { NetID, NetManager } from "./NetManager";

// 发送获取验证码
export function SendGetCode(ndata: any) {
    return NewHttpNet({
        url: "/sms/sendcode",
        method: "post",
        data: ndata,
    });
}
// 获取支持绑定的卡
export function GetAllSupportCards() {
    return NewHttpNet({
        url: "/pay/withdrawaccount/gettypelist",
        method: "post",
    });
}
// 查询玩家绑卡详情
export function GetUserBindCards(userid: number) {
    return NewHttpNet({
        url: "/pay/withdrawaccount/getaccount",
        method: "post",
        data: { userid }
    });
}

// 绑卡
export function SendUserBindCard(ndata: any) {
    return NewHttpNet({
        url: "/pay/withdrawaccount/bind",
        method: "post",
        data: ndata,
    });
}
// 提现
export function SendUserWithdrawal(ndata: any) {
    return NewHttpNet({
        url: "/pay/withdrawrequest",
        method: "post",
        data: ndata,
    });
}

// 提现协议
class WithdrawNet {
    public status = 0;// 0为成功,非0为失败，不同的失败值可能表达不同的错误情况，
    public recharge = 0; // 提现的法币金额
    public amount = 0; // 应获得的游戏币数量
    public bonousamount = 0; // 赠送的游戏币数量
    public walletmoneynow = 0; // 提现后的当前身上的游戏币数量
}

export default class BindCardsData {
    private static instance: any = null;
    public static get Instance(): BindCardsData {
        if (this.instance == null) {
            this.instance = new BindCardsData();
        }
        return this.instance;
    }

    public static set Instance(v: any) {
        this.instance = v;
    }

    public BindCardData: any;// 绑卡信息
    public Currency: string[];
    public SupportCards: any;
    public SelctCardType: string[];
    private smstoken: string;
    private currencySelect = 0;
    private bankNameSelect: string;
    public SelectBank: any;

    constructor() {
        console.log("");
        this.Currency = [];
        this.SelctCardType = [];
        this.SupportCards = null;
        this.bankNameSelect = '';
        this.smstoken = '';
        NetManager.Instance.AddNet(NetID.MSG_ID_WITHDRAW, (data: any) => { this.WithdrawFinish(data) });
        NetManager.Instance.AddNet(NetID.MSG_ID_RETURNCOIN, (data: any) => { this.WithdrawFail(data) });
    }

    public get CurrencySelect(): number {
        return this.currencySelect;
    }

    public set CurrencySelect(v: number) {
        if (this.currencySelect !== v) {
            this.currencySelect = v;
            this.SelctCardType = this.SupportCards[this.Currency[v]].typelist;
            this.bankNameSelect = this.SelctCardType[0];
            this.SelectBank = this.GetSelectBank();
            CommonData.CardsData = this;
        }
    }

    public GetSelectBank(): any {
        if (this.BindCardData) {
            for (let index = 0; index < this.BindCardData.length; index++) {
                if (this.BindCardData[index].AccountType === this.bankNameSelect) {
                    return this.BindCardData[index];
                }
            }
        }
        return null;
    }

    public get BankNameSelect(): string {
        return this.bankNameSelect;
    }

    public set BankNameSelect(v: string) {
        this.bankNameSelect = v;
        this.SelectBank = this.GetSelectBank();
        CommonData.CardsData = this;
    }

    public DownTimeNum = 0;
    private SendCodeDownTime() {
        if (this.DownTimeNum > 0) {
            setTimeout(() => {
                this.DownTimeNum--;
                CommonData.CardsData = this;
                this.SendCodeDownTime();
            }, 1000);
        }
    }

    // 获取验证码
    public async SendGetCode(phonenumber: string, smstype: number) {
        CommonData.Common.RequestLoading = true;
        const resData: ResponseGenerator = await SendGetCode({ phonenumber, smstype });
        console.log('=====>>>>获取验证码: ', resData);
        CommonData.Common.RequestLoading = false;
        if (resData.code === 0) {
            console.log('=====>>>>SendCode ok!');
            Toast.show("Verification code sent successfully!")
            // CommonData.Common.PopTips = "Verification code sent successfully!";
            this.smstoken = resData.data.token;
            this.DownTimeNum = 60;
            this.SendCodeDownTime();
        }
        else {
            Toast.show(resData.msg)

            // CommonData.Common.PopTips = resData.msg;
        }
    }

    // 获取用户绑卡信息
    public async GetBindCards() {
        if (CommonData.UserInfoData) {
            const resData: ResponseGenerator = await GetUserBindCards(CommonData.UserInfoData.UID);
            if (resData.code === 0) {
                this.BindCardData = resData.data;
            }
            else {
                Toast.show(resData.msg)
                // CommonData.Common.PopTips = resData.msg;
            }
            CommonData.CardsData = this;
        }
    }

    // 获取支持卡类型
    public async GetSupportCards() {
        if (!this.SupportCards) {
            const resData: ResponseGenerator = await GetAllSupportCards();
            if (resData.code === 0) {
                console.log('=====>>>>GetAllSupportCards ok!');
                this.SupportCards = resData.data.accountmap;
                const nameList = [];
                let key: keyof any;
                for (key in this.SupportCards) {
                    nameList.push(key);
                }
                this.Currency = nameList;
                this.currencySelect = 0;
                this.SelctCardType = this.SupportCards[this.Currency[0]].typelist;
                this.bankNameSelect = this.SelctCardType[0];
                this.SelectBank = this.GetSelectBank();
            }
            else {
                Toast.show(resData.msg)
                // CommonData.Common.PopTips = resData.msg;

            }
            CommonData.CardsData = this;
        }
    }

    // 发送绑卡
    public async SendBindCard(ndata: any) {
        CommonData.Common.RequestLoading = true;
        const resData: ResponseGenerator = await SendUserBindCard({ ...ndata });
        console.log('=====>>>>SendBindCard: ', resData);
        CommonData.Common.RequestLoading = false;
        if (resData.code === 0) {
            console.log('=====>>>>SendCode ok!');
            Toast.show("Binding successful")
            // CommonData.Common.PopTips = "Binding successful";
            this.GetBindCards();
        }
        else {
            Toast.show(resData.msg)
            // CommonData.Common.PopTips = resData.msg;
        }
    }

    // 发送提现
    public async SendWithdrawal(ndata: any) {
        CommonData.Common.RequestLoading = true;
        const resData: ResponseGenerator = await SendUserWithdrawal({
            ...ndata,
            AccountID: this.SelectBank.ID,
            ChannelIndex: this.bankNameSelect,
            CurrencyIndex: this.Currency[this.currencySelect],
            UID: CommonData.UserInfoData.UID,
        });
        console.log('=====>>>>SendWithdrawal: ', resData);
        CommonData.Common.RequestLoading = false;
        if (resData.code === 0) {
            console.log('=====>>>>SendWithdrawal ok!');
            Toast.show('Submitted successfully!')
            // CommonData.Common.PopTips = "Submitted successfully!";
        }
        else {
            Toast.show(resData.msg)
            // CommonData.Common.PopTips = resData.msg;
        }
    }

    // 提现成功
    private WithdrawFinish(data: WithdrawNet) {
        if (data.status === 0 && CommonData.UserInfoData) {
            CommonData.UserInfoData = { WalletMoney: data.walletmoneynow };
            Toast.show("Successfully withdrawn " + data.amount + " game coins")
            // CommonData.Common.PopTips = "Successfully withdrawn " + data.amount + " game coins";
            CommonData.UserInfoData.RefreshUserInfo();
        }
    }

    // 提现失败
    private WithdrawFail(data: WithdrawNet) {
        if (data.status === 0 && CommonData.UserInfoData) {
            CommonData.UserInfoData = { WalletMoney: data.walletmoneynow };
            Toast.show("Withdrawal failed")
            // CommonData.Common.PopTips = "Withdrawal failed";
        }
    }

    public Quit() {
        this.Currency = [];
        this.SelctCardType = [];
        this.SupportCards = null;
        this.bankNameSelect = '';
        this.smstoken = '';
    }
}