import NewHttpNet from "../api/HttpNetMg";
import { ResponseGenerator } from "../api/types";
import { CommonData } from "./Common";

// 查询用户充值记录
function getUserOrderAPI(data: any) {
    return NewHttpNet({
        url: '/pay/rechargeorder/getuserorder',
        method: 'post',
        data: { ...data },
    })
}
// 查询用户充值记录
function getUserCoinOrderAPI(data: any) {
    return NewHttpNet({
        url: '/status/recordmoney/getall',
        method: 'post',
        data: { ...data },
    })
}

class HistoryCl {
    public OrderAmount = 0;// 充值金额
    public OrderRealityAmount = 0;// 实际到账金额
    public GameAmount = 0;// 游戏币
    public BonousAmount = 0;// 额外赠送
    /*
    1:未知错误
    2:等待付款，等待提现审核
    3:充值支付失败
    4:已充值，尚未派币
    5:已充值，实际到账金额不足
    6:已充值已派币 或 已提现
    7:已经申请提现，支付等待中
    8:支付错误
    9:系统内部错误，后期还可以再补单
    10:退币成功
    */
    public Status = 0; // 状态码
}

export default class RechargeOrderData {
    private static instance: any = null;
    public static get Instance(): RechargeOrderData {
        if (this.instance == null) {
            this.instance = new RechargeOrderData();
        }
        return this.instance;
    }

    private historyData: any;
    private totalSize: number;
    private npage: number;
    private pagesize: number;
    constructor() {
        this.historyData = null;
        this.totalSize = 1;
        this.npage = 0;
        this.pagesize = 10;
    }

    public get HistoryData(): HistoryCl {
        return this.historyData;
    }

    public set HistoryData(v: HistoryCl) {
        this.historyData = v;
        CommonData.Orderr = this;
    }

    public get TotalSize() {
        return this.totalSize;
    }

    public get IsNext() {
        return !((this.npage + 1) * this.pagesize >= this.totalSize);
    }

    public get IsPrev() {
        return !(this.npage === 0)
    }

    public NextPage(next: boolean) {
        if (next && this.IsNext) {
            this.GetUserOrderAPI(this.npage + 1);
        }
        else if (!next && this.IsPrev) {
            this.GetUserOrderAPI(this.npage - 1);
        }
    }

    public async GetUserOrderAPI(pagestart = 0, pagesize = 10) {
        if (this.historyData && this.npage === pagestart && this.pagesize === pagesize) {
            return;
        }
        if (CommonData.UserInfoData) {
            const resData: ResponseGenerator = await getUserOrderAPI({ pagestart, pagesize, userid: CommonData.UserInfoData.UID }).catch(error => error);
            if (resData.code === 0) {
                this.historyData = resData.data;
                this.pagesize = pagesize;
                this.npage = pagestart;
                this.totalSize = resData.count || 0;
                CommonData.Orderr = this;
            }
        }
    }
    public async GetUserCoinOrderAPI(timebegin: number, timeend: number, recordtype: string, pagestart = 0, pagesize = 10000) {
        if (this.historyData && this.npage === pagestart && this.pagesize === pagesize) {
            return;
        }
        if (CommonData.UserInfoData) {
            const resData: ResponseGenerator = await getUserCoinOrderAPI({ timebegin, timeend, recordtype, pagestart, pagesize, userid: CommonData.UserInfoData.UID }).catch(error => error);
            if (resData.code === 0) {
                this.historyData = resData.data;
                this.pagesize = pagesize;
                this.npage = pagestart;
                this.totalSize = resData.count || 0;
                CommonData.CoinFlow = this;
            }
        }
    }

    // 退出登录时清空
    public Quit() {
        this.historyData = null;
    }
}