// 邮件
import { CommonData } from "./Common";
import { ResponseGenerator } from "../api/types";
import NewHttpNet from "../api/HttpNetMg";
import { Toast } from "antd-mobile";
// 查询公告 pagesize pagestart userid
function getNotifyAPI(data: any) {
    return NewHttpNet({
        url: '/notify/getall',
        method: 'post',
        data: { ...data },
    })
}

// 删除公告 id:[]
function deleteNotifyAPI(data: any) {
    return NewHttpNet({
        url: '/notify/delete',
        method: 'post',
        data: { ...data },
    })
}

export default class NoticeData {
    private static instance: any = null;
    public static get Instance(): NoticeData {
        if (this.instance == null) {
            this.instance = new NoticeData();
        }
        return this.instance;
    }

    private mailData: any;
    private totalSize: number;
    private npage: number;
    private pagesize: number;
    private hasreddata: boolean;

    constructor() {
        this.mailData = null;
        this.totalSize = 1;
        this.npage = 0;
        this.pagesize = 10;
        this.hasreddata = false;
    }

    public get MailData(): [] {
        return this.mailData;
    }

    public set MailData(v: []) {
        this.mailData = v;
        CommonData.Notice = this;
    }

    public get HasRedData(): boolean {
        return this.hasreddata;
    }

    public set HasRedData(v: boolean) {
        this.hasreddata = v;
        CommonData.Notice = this;
    }

    public get TotalSize() {
        return this.totalSize;
    }

    public get IsNext() {
        return !((this.npage + 1) * this.pagesize >= this.totalSize);
    }

    public get IsPrev() {
        return !(this.npage === 0)
    }

    public get TotalPage(): number {
        return this.totalSize === 0 ? 0 : (this.totalSize / this.pagesize + 1);
    }

    public NextPage(next: boolean) {
        if (next && this.IsNext) {
            this.GetUserMail(this.npage + 1);
        }
        else if (!next && this.IsPrev) {
            this.GetUserMail(this.npage - 1);
        }
    }

    public async GetUserMail(pagestart = 0, pagesize = 10) {
        if (this.mailData !== null && this.npage === pagestart && this.pagesize === pagesize) {
            return;
        }
        const resData: ResponseGenerator = await getNotifyAPI({ pagestart, pagesize }).catch(error => error);
        if (resData.code === 0) {
            this.mailData = resData.data;
            this.pagesize = pagesize;
            this.npage = pagestart;
            this.totalSize = resData.count || 0;
            this.HasRedData = this.totalSize > 0;
            CommonData.Notice = this;
        }
    }

    public async DeleteUserMail(id: number) {
        const resData: ResponseGenerator = await deleteNotifyAPI({ id }).catch(error => error);
        if (resData.code === 0) {
            CommonData.Notice = this;
        }
        else {
            Toast.show(resData.msg)

            // CommonData.Common.PopTips = resData.msg;
        }
    }

    public Quit() {
        this.mailData = null;
    }
}