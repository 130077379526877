/**
 * tool
 */
import { useState, useRef, useMemo } from 'react'
import { useNavigate } from "react-router-dom";
import classnames from 'classnames/bind'
import style from './index.module.scss'
import { useTranslation } from 'react-i18next';
import CustomImage from '../../../components/CustomImage'
import { CommonData } from '../../../Common/Common'
/**
 * image
 */
const goldIcon = '/images/components/currency/BTC.png'
const scoreIcon = '/images/components/currency/BCH.png'
const noticeIcon = '/images/layout/website_email2.png';
const register_btn_bg = '/images/layout/register_btn_bg.png';
const register_bonus_bg = '/images/layout/register_bonus_bg.png';
const register_bonus_icon = '/images/layout/register_bonus_icon.png';

const className = classnames.bind(style)

const HeaderRight = () => {
  const { t } = useTranslation();
  const router = useNavigate();
  /* ref */
  const refWallet: any = useRef()
  const notice = CommonData.Notice;

  const onOpenWallet = (e: any) => {
    router('/wallet');
  }

  const toNotice = () => {
    if (CommonData.UserInfoData?.UID) {
      router('/emails');
    }
  }

  const toLogin = (type?: string) => {
    CommonData.Common.ShowLogin = type === 'register' ? 2 : 1;
  }

  const headImg: any = useMemo(() => {
    let faceid = CommonData.UserInfoData?.UserInfo.FaceID;
    // let faceid = CommonData.UserInfoData?.UserInfo.FaceID + 1;
    // if (faceid < 0 || faceid > 12) faceid = 1;
    return '/images/user/list/' + faceid + '.png';
  }, [CommonData.UserInfoData]);

  const gotopersonal = () => {
    if (CommonData.UserInfoData?.UID) {
      router("/user");
    }
  }

  return (
    <>
      {CommonData.UserInfoData ? (
        <>
          <div className={className('df', 'aic')}>
            <div className={className('wallet_section')}>
              <div
                className={className('wallet_block')}
                ref={refWallet}
              >
                <div className={className('wallet_number', 'fz28', 'cFFBE00')}>
                  <img className={className('square35', 'mr5')} src="/images/share/gold-egg.png" alt="" />
                  {CommonData.UserInfoData.WalletMoney}</div>
              </div>
              <div className={className('divblock')} />

              <div className={className('headimg')} onClick={gotopersonal}>
                <img src={headImg} alt="head_photo" />
              </div>
              <div onClick={() => { router('/purse') }} className={className('circle-add')}>
                <span>+</span>
              </div>

            </div>
            <div className={className('posr', 'ml40')} onClick={() => { toNotice() }}>
              <img className={className('email-info')} src='/images/home/notice.png' alt="vault" />
              {/* {notice.HasRedData && <span className={className('', 'red-dot')}></span>} */}
            </div>
          </div>
        </>
      )
        : (
          <div className={className('not_login_wrap')}>
            <div className={className('login_btn')} onClick={() => { toLogin() }}>{t('login.login')}</div>
            <div className={className('register_btn')} onClick={() => { toLogin('register') }}>
              <span>{t('login.register')}</span>
            </div>
          </div>
        )
      }
    </>
  )
}

export default HeaderRight
