import classnames from 'classnames/bind';
import style from './index.module.scss';

const className = classnames.bind(style);
interface MaskContainerProps {
	children: any,
    bgStyle?: object | undefined,
}

const BoxCommon = ({children,bgStyle = {}}:MaskContainerProps)=>{
    return(
        <div className={className("bg")} style={{ ...bgStyle }} id="maskContainer">
            <div className={className("container")}>
            {children}
            </div>
        </div>
    )
}

export default BoxCommon;