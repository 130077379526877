import { CommonData } from "./Common";
import { ResponseGenerator } from "../api/types";
import NewHttpNet from "../api/HttpNetMg";

function getClientConfig() {
    return NewHttpNet({
        url: "/customer/getconfig",
        method: "post"
    });
}

class CConfig {
    public facebookurl = '';
    public telegremurl = '';
    public whatappurl = '';
    ActivityConfig = {
        NoviceTask: 0,
        DailyRotation: 0,
        DailyCheckIn: 0,
        BettingBonus: 0,
        SavingsFund: 0,
        LuckyCode: 0,
        FirstRecharge: 0,
        RechargeFund: 0,
    }
}

export default class CommonClass {
    private static instance: any = null;
    public static get Instance(): CommonClass {
        if (this.instance == null) {
            this.instance = new CommonClass();
        }
        return this.instance;
    }

    private router: any;
    private authInited = false; // 初始化
    private requestLoading = false;// loading 加载界面
    private showLogin = 0;  // 登录注册界面
    private gamePopupData = null; // 提示确认框
    private popTips = '';// 文字提示
    private messageTarget = null;// 左上角消息提示
    private clientConfig: any = null;// 客户端配置
    private referenceid = '';
    private showLuckyCode = false;

    public get RequestLoading(): boolean {
        return this.requestLoading;
    }

    public set RequestLoading(v: boolean) {
        this.requestLoading = v;
        CommonData.Common = this;
    }

    public get PopTips(): string {
        return this.popTips;
    }

    public set PopTips(v: string) {
        this.popTips = v;
        CommonData.Common = this;
    }

    public get ShowLogin(): number {
        return this.showLogin;
    }

    public set ShowLogin(v: number) {
        this.showLogin = v;
        CommonData.Common = this;
    }

    public get ShowLuckyCode(): boolean {
        return this.showLuckyCode;
    }

    public set ShowLuckyCode(v: boolean) {
        this.showLuckyCode = v;
        CommonData.Common = this;
    }

    public get GamePopupData(): any {
        return this.gamePopupData;
    }

    public set GamePopupData(v: any) {
        this.gamePopupData = v;
        CommonData.Common = this;
    }

    public get MessageTarget(): any {
        return this.messageTarget;
    }

    public set MessageTarget(v: any) {
        this.messageTarget = v;
        CommonData.Common = this;
    }

    public get ClientConfig(): CConfig {
        return this.clientConfig;
    }

    public set ClientConfig(v: CConfig) {
        this.clientConfig = v;
        CommonData.Common = this;
    }

    public get AuthInited(): boolean {
        return this.authInited;
    }

    public set AuthInited(v: boolean) {
        this.authInited = v;
        CommonData.Common = this;
    }

    public get ReferenceId(): string {
        if (this.referenceid === '') {
            this.referenceid = localStorage?.getItem('referenceid') || '';
        }
        return this.referenceid;
    }

    public set ReferenceId(v: string) {
        this.referenceid = v;
    }

    public async GetClientConfig() {
        if (!this.ClientConfig) {
            const resData: ResponseGenerator = await getClientConfig().catch(e => e);
            if (resData.code === 0) {
                this.ClientConfig = resData.data;
            }
        }
    }

    public get Router(): any {
        return this.router;
    }
    public set Router(v: any) {
        this.router = v;
    }

    public GoBack(psth?: any) {
        if (this.router) {
            console.log('=========>>>>psth: ', psth);
            this.router(psth ? psth : '/');
        }
        else {
            console.log('=========>>>>the router is null!');
        }
    }
}