/**
 * tool
 */
import { useTranslation } from 'react-i18next'
import classnames from 'classnames/bind'
import style from './index.module.scss'
import PopupContainer from '../../../components/Popup/PopupContainer';
import MonthSignContent from './MonthSignContent';
import { CommonData } from '../../../Common/Common';
/** images */
const closeIcon = '/images/activityModule/close.png';

const className = classnames.bind(style)
const MonthSignPopup = () => {
  /* i18n */
  const { t } = useTranslation();
  // 关闭弹窗
  const closePop = () => {
    CommonData.Checkin.IsShow = false;
  }

  return (
    <PopupContainer closeIcon={closeIcon} close={closePop} outsideClick={false} containerStyle={{display: 'flex', flexDirection: 'column'}}>
      <div className={className('month_sign_title')}>{t('Menu.dailyCheckIn')}</div>
      <MonthSignContent type={0}/>
    </PopupContainer>
  )
}

export default MonthSignPopup
