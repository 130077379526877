import { makeAutoObservable, runInAction } from 'mobx';

class UserStore {
    userData: any = null;
    token: string | null = null;
    expirestime: string | undefined = undefined;

    constructor() {
        makeAutoObservable(this);
        this.load();
    }

    setUserData(data: any) {
        runInAction(() => {
            this.userData = data;
        });
    }

    setToken(token: string | null) {
        runInAction(() => {
            this.token = token;
        });
    }

    setExpirestime(expirestime: string | undefined) {
        runInAction(() => {
            this.expirestime = expirestime;
        });
    }

    clearUserData() {
        runInAction(() => {
            this.userData = null;
            this.token = null;
            this.expirestime = undefined;
        });
    }

    save() {
        localStorage.setItem('userStore', JSON.stringify({
            userData: this.userData,
            token: this.token,
            expirestime: this.expirestime,
        }));
    }

    load() {
        const savedState = localStorage.getItem('userStore');
        // console.log('Loaded state:', savedState);
        if (savedState) {
            const { userData, token, expirestime } = JSON.parse(savedState);
            runInAction(() => {
                this.userData = userData;
                this.token = token;
                this.expirestime = expirestime;
            });
        }
    }
}

const userStore = new UserStore();

export { userStore };
