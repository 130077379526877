// 游戏数据相关记录
import { Toast } from "antd-mobile";
import NewHttpNet from "../api/HttpNetMg";
import { ResponseGenerator } from "../api/types";
import { CommonData } from "./Common";

function getRecordMoney(data: any) {
    return NewHttpNet({
        url: '/user/getmoneyrecord',
        method: 'post',
        data: { ...data },
    })
}

export enum RecordType {
    All_data = 0,//查询所有数据
    P_TO_Game = 1, //平台到游戏
    Game_TO_P = 2, //游戏回平台
    GameRecord = 3, //
    RECHARGE = 4, //用户充值
    AGENTSHARE = 5, //暂无
    ACTIVITY = 6,
    Withdraw = 7, //用户提现
    ReturnCoin = 8, //用户提现失败回退金币
    AdminAdd = 9, //管理员添加
    BetBonus = 10, //下注返利
    Activity_BindTask = 11, //新手绑定任务奖励
    Activity_RechargeTask = 12, //新手充值任务奖励
    Activity_WithdrawTask = 13, //新手提现任务奖励
    Activity_ReviveTask = 14, //新手复活任务奖励
    Activity_DailyRotation = 15, //每日转盘奖励
    Activity_DailyCheckIn = 16, //每日登陆签到奖励
    Activity_FirstRecharge = 17, //首冲奖励
    Activity_RechargeFund = 18, //充值活动奖励
    Reward_LevelUP = 19, //升级奖励
    Activity_LuckyCode = 20, //幸运码
    Activity_END = 99,//预留活动id
    AffiliateAgent_FirstRecharge = 100, //全民代理，下级首冲自己获得奖励
    AffiliateAgent_BetBonus = 101, //全民代理，下级下注自己获得奖励
    AffiliateAgent_FirstRechargeCount = 102, //全民代理，邀请下级首充满足一定数量，自己获得奖励
}

export class RecordData {
    public recordtype: RecordType;
    public pagesize: number;
    public pagestart: number;
    public timebegin: number;
    public timeend: number;
    public totalSize = 0;
    private records: any;
    constructor(dtype: RecordType, pagesize?: number, timebegin?: number, timeend?: number) {
        this.recordtype = dtype;
        this.pagesize = pagesize || 10;
        this.pagestart = 0;
        this.timeend = timeend || Math.floor(Date.now() / 1000);
        this.timebegin = timebegin || this.timeend - 604800;// 默认一周
    }

    public get Records(): any {
        return this.records;
    }

    public get IsNext() {
        return !((this.pagestart + 1) * this.pagesize >= this.totalSize);
    }

    public get IsPrev() {
        return !(this.pagestart === 0);
    }

    public NextPage(next: boolean) {
        if (next && this.IsNext) {
            this.pagestart++;
            this.GetRecord();
        }
        else if (!next && this.IsPrev) {
            this.pagestart--;
            this.GetRecord();
        }
    }

    public SetPage(pageindex: number) {
        if (pageindex !== this.pagestart) {
            this.pagestart = pageindex;
            this.GetRecord();
        }
        else if (!this.records) {
            this.GetRecord();
        }
    }

    public GetRecord() {
        const userinfo = CommonData.UserInfoData;
        if (userinfo) {
            this.records = null;
            RecordMoneyData.Instance.GetReCord({
                pagesize: this.pagesize,
                pagestart: this.pagestart,
                recordtype: this.recordtype,
                timebegin: this.timebegin,
                timeend: this.timeend,
                userid: userinfo.UID
            }).then(dt => {
                this.records = dt?.data;
                this.totalSize = dt?.count || 0;
                CommonData.Record = RecordMoneyData.Instance;
            });
        }
    }
}

export default class RecordMoneyData {
    private static instance: RecordMoneyData;
    public static get Instance(): RecordMoneyData {
        if (this.instance == null) {
            this.instance = new RecordMoneyData();
        }
        return this.instance;
    }

    private recordDataArray: RecordData[];

    constructor() {
        this.recordDataArray = [];
    }

    // 代理下级首冲奖励
    public get AgentFirstPay(): RecordData {
        let recordDt = this.recordDataArray[RecordType.AffiliateAgent_FirstRecharge];
        if (!recordDt) {
            recordDt = new RecordData(RecordType.AffiliateAgent_FirstRecharge);
            this.recordDataArray[RecordType.AffiliateAgent_FirstRecharge] = recordDt;
        }
        return recordDt;
    }

    // 代理下级下注奖励
    public get AgentBetBonus(): RecordData {
        let recordDt = this.recordDataArray[RecordType.AffiliateAgent_BetBonus];
        if (!recordDt) {
            recordDt = new RecordData(RecordType.AffiliateAgent_BetBonus);
            this.recordDataArray[RecordType.AffiliateAgent_BetBonus] = recordDt;
        }
        return recordDt;
    }

    // 代理邀请下级首充满足一定数量，自己获得奖励
    public get AgentFirstPaySuper(): RecordData {
        let recordDt = this.recordDataArray[RecordType.AffiliateAgent_FirstRechargeCount];
        if (!recordDt) {
            recordDt = new RecordData(RecordType.AffiliateAgent_FirstRechargeCount);
            this.recordDataArray[RecordType.AffiliateAgent_FirstRechargeCount] = recordDt;
        }
        return recordDt;
    }

    public async GetReCord(data: any) {
        const resData: ResponseGenerator = await getRecordMoney(data);
        if (resData.code === 0) {
            return resData;
        }
        else {
            Toast.show(resData.msg)

            // CommonData.Common.PopTips = resData.msg;
            return null;
        }
    }
}