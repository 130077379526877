import axios from 'axios'
import dayjs from 'dayjs'
import { getItem, getDevice, getIp, setItem } from '../utils/tools'
import { CommonData } from '../Common/Common'

const apiBase = process.env.NEXT_PUBLIC_API_BASE
let timeout = 30000
const configTimeout = process.env.AXIOS_TIMEOUT
if (typeof configTimeout === 'string' && configTimeout.length && !isNaN(Number(configTimeout))) {
  timeout = Number(configTimeout)
}

const axiosInstance = axios.create({
  baseURL: apiBase,
  timeout // 请求超时时间
})
// request拦截器
axiosInstance.interceptors.request.use(
  async config => {
    config.headers['X-HTTP-Method-Override'] = config.overrideMethod
    config.headers['Access-Control-Allow-Origin'] = '*'
    config.headers.withCredentials = true
    config.headers.crossDomain = true
    config.headers['Content-Type'] = 'application/json; charset=utf-8'
    const userinfo = CommonData.UserInfoData;
    if (userinfo && userinfo.token && userinfo.expirestime) {
      config.headers.token = userinfo.token
      config.headers.expirestime = userinfo.expirestime
    }
    else {
      const auth = getItem('auth')
      if (auth?.token) {
        config.headers.token = auth.token
        config.headers.expirestime = auth.expirestime
      }
    }

    const isRefresh = isRegetIp(config?.url)

    // 登录注册等操作必须重新获取ip而不使用缓存。
    // const ip = await getIp(isRefresh)
    // if (ip) {
    //   config.headers.ip = ip
    // }

    const { userAgent, fingerprint, screenWidth, screenHeight } = await getDevice(isRefresh)
    const realFingerprint = fingerprint + '-' + screenWidth + '-' + screenHeight + '-' + userAgent.replace(/\s+/g, "")
    if (realFingerprint) {
      config.headers.fingerprint = realFingerprint
    }

    return config
  },
  error => {
    console.error('Api Request Error: ' + error)
  }
)

// respone拦截器
axiosInstance.interceptors.response.use(
  response => {
    const auth = {}
    const headers = response.headers;
    const expiresIn = headers['expires-in']
    const refreshExpiresIn = headers['refresh-expires-in']
    auth.refreshToken = headers['refresh-token'];
    auth.token = headers.token;

    if (auth.refreshToken || auth.token) {
      const cacheAuth = getItem('auth');
      if (!auth.refreshToken) auth.refreshToken = cacheAuth.refreshToken;
      if (!auth.token) auth.token = cacheAuth.token;
      auth.expiresAt = expiresIn ? dayjs().add(expiresIn, 's').unix() : cacheAuth.expiresAt
      auth.refreshExpiresAt = refreshExpiresIn ? dayjs().add(refreshExpiresIn, 's').unix() : cacheAuth.refreshExpiresAt;
      setItem('auth', auth);
    }
    return response.data
  },
  error => {
    console.error('Api Response Error: ' + error)
    // 兼容状态码为400的空数据情况
    try {
      const whiteStatus = [400, 408];
      if (whiteStatus.includes(error.response?.status)) {
        if (error.response.data.message === 'NO_CONTENT') {
          return {}
        }
        if (error.response.data.message === 'Request Timeout') return { message: error.response.data.message };
      }
    } catch (e) {
      return Promise.reject(e)
    }
    return Promise.reject(error)
  }
)

const isRegetIp = (url) => {
  if (typeof url !== 'string' || !url?.length) return null

  url = url.split('?')[0]
  return ['/register', '/login', '^/[0-9a-zA-Z_-]*/login$'].some((item) => new RegExp(item).test(url))
}

export default axiosInstance
