import { useState, useEffect } from 'react'

interface CustomImageProps {
  src: string
  alt?: string
  width?: number | undefined
  height?: number | undefined
  priority?: boolean
  className?: string | undefined;
}

const CustomImage = ({ src, alt = '', width, height, priority = false, className }: CustomImageProps) => {
  /* state */
  const [srcMap, setSrcMap] = useState(src)

  /* effect */
  useEffect(() => {
    if (src) setSrcMap(src)
  }, [src])

  if (!srcMap) return null

  // 自定義寬高
  if (width && height) {
    return (
      <img
        src={srcMap}
        alt={alt}
        width={width}
        height={height}
        className={className}
      />
    )
  }
  // 自適應寬高
  else {
    return (
      <img
        src={srcMap}
        alt={alt}
        className={className}
      />
    )
  }
}

export default CustomImage
