// 支付相关
import { Toast } from "antd-mobile";
import NewHttpNet from "../api/HttpNetMg";
import { ResponseGenerator } from "../api/types";
import { CommonData } from "./Common";
import { NetID, NetManager } from "./NetManager";

// 查询充值配置
export function RechargeAmount(ndata: string) {
    return NewHttpNet({
        url: "/pay/rechargeamount/getall",
        method: "post",
        data: { CurrencyType: ndata }
    });
}

// 查询充值配置
export function RechargeConfig() {
    return NewHttpNet({
        url: "/pay/rechargeconfig",
        method: "post",
    });
}

// 充值请求 
export function RechargeRequest(ndata: any) {
    return NewHttpNet({
        url: "/pay/rechargerequest",
        method: "post",
        data: { ...ndata }
    });
}

class RechargeNet {
    public status = 0;// 0为成功,非0为失败，不同的失败值可能表达不同的错误情况，
    public recharge = 0; // 充值的法币金额
    public amount = 0; // 应获得的游戏币数量
    public bonousamount = 0; // 赠送的游戏币数量
    public walletmoneynow = 0; // 充值后的当前身上的游戏币数量
}

export class RechargeData {
    private static instance: any = null;
    public static get Instance(): RechargeData {
        if (this.instance == null) {
            this.instance = new RechargeData();
        }
        return this.instance;
    }

    public static set Instance(v: any) {
        this.instance = v;
    }

    public PayNameList: any[];
    public PayChannelData: any[];
    private payNameSelect = 0;
    private payChannelSelect: string;
    public PayDataList = []; // 充值列表
    public IsNoData = false;
    private payUrl: string;

    private AllPayChannelData: any;
    private AllPayDataList: any;

    public get PayNameSelect(): number {
        return this.payNameSelect;
    }

    public set PayNameSelect(v: number) {
        if (this.payNameSelect !== v) {
            this.payNameSelect = v;
            this.PayChannelData = this.AllPayChannelData[this.PayNameList[v]];
            this.IsNoData = this.PayChannelData === null;
            this.payChannelSelect = this.PayChannelData[0];
            CommonData.WalletData = this;
        }
        this.GetRechargeByType(this.PayNameList[v]);
    }

    public get PayChannelSelect(): string {
        return this.payChannelSelect;
    }

    public set PayChannelSelect(v: string) {
        this.payChannelSelect = v;
    }

    public get PayUrl(): string {
        return this.payUrl;
    }

    public set PayUrl(v: string) {
        if (this.payUrl !== v) {
            this.payUrl = v;
            CommonData.WalletData = this;
        }
    }

    constructor() {
        this.AllPayDataList = {};
        this.AllPayChannelData = {};
        this.PayNameList = [];
        this.PayChannelData = [];
        this.payNameSelect = 0;
        this.payChannelSelect = '';
        this.PayDataList = [];
        this.IsNoData = false;
        this.payUrl = '';
        NetManager.Instance.AddNet(NetID.MSG_ID_RECHARGE, (data: any) => { this.RechargedFinish(data) });
    }

    public async GetPayConfig() {
        if (this.PayNameList.length === 0) {
            CommonData.Common.RequestLoading = true;
            const resData: ResponseGenerator = await RechargeConfig().catch(e => e);
            CommonData.Common.RequestLoading = false;
            if (resData.code === 0) {
                const payNameList = [];
                this.AllPayChannelData = {};
                let key: keyof any;
                for (key in resData.data) {
                    payNameList.push(key);
                    console.log("========>>>>>PayConfig key: ", key);
                    this.AllPayChannelData[key] = resData.data[key].PayChannelList;
                }
                this.PayNameList = payNameList;
            }
        }
        this.PayChannelData = this.AllPayChannelData[this.PayNameList[0]];
        if (this.PayChannelData) {
            this.payChannelSelect = this.PayChannelData[0];
            this.PayNameSelect = 0;
        }
    }

    public async GetAllRecharge() {
        const resData: ResponseGenerator = await RechargeAmount("").catch(e => e);
        if (resData.code === 0) {
            this.AllPayDataList = {};
            for (let index = 0; index < resData.data.length; index++) {
                const datatp = resData.data[index];
                if (!this.AllPayDataList[datatp.CurrencyType]) {
                    this.AllPayDataList[datatp.CurrencyType] = [];
                }
                this.AllPayDataList[datatp.CurrencyType].push(datatp);
            }
        }
        CommonData.WalletData = this;
    }

    public async GetRechargeByType(currencyType: string) {
        if (currencyType) {
            if (!this.AllPayDataList[currencyType]) {
                CommonData.Common.RequestLoading = true;
                const resData: ResponseGenerator = await RechargeAmount(currencyType).catch(e => e);
                if (resData.code === 0) {
                    this.AllPayDataList[currencyType] = resData.data;
                }
            }
            this.PayDataList = this.AllPayDataList[currencyType];
        }
        else {
            this.PayDataList = [];
        }
        CommonData.Common.RequestLoading = false;
        CommonData.WalletData = this;
    }

    // 发送支付请求
    public async PayRequest(participate: any = '', payid: number, currencyType: string) {
        if (CommonData.UserInfoData) {
            CommonData.Common.RequestLoading = true;
            const paydata = {
                UID: CommonData.UserInfoData.UID,
                RechargeID: payid,
                CurrencyIndex: currencyType ? currencyType : "PHP",
                ChannelIndex: this.payChannelSelect,
                ActivityData: participate
            };
            const resData: ResponseGenerator = await RechargeRequest(paydata).catch(e => e);
            console.log("=====>>>发送支付请求: ", resData);
            if (resData.code === 0) {
                this.PayUrl = resData.data;
            }
            else {
                CommonData.Common.RequestLoading = false;
                Toast.show(resData.msg)

                // CommonData.Common.PopTips = resData.msg;
            }
        }
        else {
            CommonData.Common.ShowLogin = 1;
        }
    }

    public RechargedFinish(data: RechargeNet) {
        if (data.status === 0 && CommonData.UserInfoData) {
            this.PayUrl = '';
            CommonData.UserInfoData = { WalletMoney: data.walletmoneynow };
            Toast.show("Recharged successfully to obtain " + data.amount + " game coins")
            // CommonData.Common.PopTips = ;
            CommonData.UserInfoData.RefreshUserInfo();
        }
    }
}