import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

interface Window {
  deferredPrompt?: Event;
}

// Register the service worker if it's not in production
if (process.env.NODE_ENV === 'production') {
  const { Workbox } = require('workbox-window');

  const wb = new Workbox('/service-worker.ts');

  wb.addEventListener('installed', (event: any) => {
    if (event.isUpdate) {
      console.log('New content is available; please refresh.');
    } else {
      console.log('Content is cached for offline use.');
    }
  });

  wb.register();

  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    window.deferredPrompt = e as BeforeInstallPromptEvent;
  });
}


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
