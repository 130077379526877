import { useTranslation } from 'react-i18next'
import { useEffect, useMemo } from 'react'
import classnames from 'classnames/bind'
import style from './index.module.scss'
import { TitleDom, ProgressDom, RuleDom } from '../../commonDom'
import { CommonData } from '../../../../Common/Common'
import ComState from '../../../../Common/ComState'
import { useNavigate } from 'react-router-dom'
import { ProgressBar } from 'antd-mobile'

const receive_success = '/images/activityModule/receive_success.png'
const rule_icon = '/images/activityModule/rule_icon.png'
const star = '/images/activityModule/star.png'
const sign_light_bg = '/images/activityModule/monthSign/sign_light_bg.png'
const month_sign_icon = '/images/activityModule/monthSign/month_sign_icon.png'
const sign_error = '/images/activityModule/monthSign/sign_error.png'
const month_sign_icon_min = '/images/activityModule/monthSign/month_sign_icon_min.png'
const saving_sprite_01 = '/images/activityModule/saving/saving_sprite_01.png'
const saving_sprite_02 = '/images/activityModule/saving/saving_sprite_02.png'
const saving_sprite_03 = '/images/activityModule/saving/saving_sprite_03.png'
const goldIcon = '/images/components/currency/BTC.png'
const scoreIcon = '/images/components/currency/BCH.png'


const initialState = {
  continueSignDay: 0, // 签到天数
  showRule: false // 签到规则内容显示/隐藏
}
const className = classnames.bind(style)

interface MonthSignContentProps {
  type: number
}
const MonthSignContent = ({ type }: MonthSignContentProps) => {
  const router = useNavigate();
  const checkin = CommonData.Checkin;
  /* i18n */
  const { t } = useTranslation()
  /** state */
  const { state, updateState } = ComState(initialState)
  const { showRule, continueSignDay } = state.mdata;
  // status:  0: 不可签到 1: 可签到 2: 已签到 3: 已过期
  // 全部签到列表
  const allSignList = useMemo(() => {
    if (!checkin.DataConfig) return [];
    const signData = checkin.DataConfig.ItemList;
    const todayID = checkin.DataConfig.TodayID;
    const instatus = checkin.UserData?.CheckInStatus || 0;
    let signDays = 0;
    for (let index = 0; index < signData.length; index++) {
      const issign = (instatus & (1 << index)) === (1 << index);
      if (issign) {
        signDays++;
        signData[index].status = 2;
      }
      else {
        if (todayID > signData[index].DayNO) {
          signData[index].status = issign ? 2 : 3;
        }
        else if (todayID === signData[index].DayNO) {
          signData[index].status = issign ? 2 : 1;
        }
        else {
          signData[index].status = 0;
        }
      }
      signData[index].BonusType = checkin.DataConfig.BonusType;
    }
    updateState({ continueSignDay: signDays });
    console.log(signData);
    return signData;
  }, [checkin.DataConfig, checkin.UserData])

  const extra_rewards = useMemo(() => {
    if (!checkin.DataConfig) return [];
    const signData: any = [];
    const instatus = checkin.UserData?.BonusStatus || 0;
    for (let index = 0; index < 4; index++) {
      let status = (instatus & (1 << index)) + 1;
      const day_count = (index + 1) * 7;
      if (status === 1 && continueSignDay < day_count) {
        status = 0;
      }
      const dataitem = {
        day_count,
        Amount: checkin.DataConfig.BonusList[index],
        status
      };
      signData.push(dataitem);
    }
    return signData;
  }, [checkin.DataConfig, checkin.UserData, continueSignDay]);

  // 最高奖励
  const highestReward = useMemo(() => {
    let maxreward = 0;
    if (checkin.DataConfig) {
      maxreward = checkin.DataConfig?.BonusList[3]
    }
    return maxreward
  }, [checkin.DataConfig])


  /** function */
  // 请求签到
  const onSendSign = (item: any) => {
    if (item.status !== 1) return
    checkin.ReceiveaWard(() => {
      CommonData.Common.MessageTarget = {
        type: 'success',
        title: 'success',
        message: t('piggybank.receiveSuccess')
      }
    });
  }
  // 领取额外奖励
  const onExtraReward = (item: any) => {
    if (item.status !== 1) return
    checkin.ReceiveSigninreWard(() => {
      CommonData.Common.MessageTarget = {
        type: 'success',
        title: 'success',
        message: t('piggybank.receiveSuccess')
      }
    });
  }
  // 显示全部签到列表
  const onViewAllSignList = () => {
    console.log('显示全部签到列表');
    CommonData.Common.GoBack('/activity/monthSign');
    checkin.IsShow = false;
  }
  const getStatusText = (status: number) => {
    // 状态文案
    switch (status) {
      case 0:
        return t('securityCenter.unSuccess')
      case 1:
        return t('spinActivity.receive')
      case 2:
        return t('proxy.received')
    }
  }
  const getCurrencyBg = (status: number) => {
    switch (status) {
      case 0:
        return saving_sprite_02
      case 1:
        return saving_sprite_01
      case 2:
        return saving_sprite_03
      case 3:
        return saving_sprite_03
    }
  }

  /* effect */
  useEffect(() => {
    // 初始化信息
    checkin.GetCheckinConfig();
    checkin.GetUserCheckin();
  }, [])

  return (
    <div className={className('month_sign_content_box')} onClick={() => showRule && updateState({ showRule: false })}>
      <div className={className('fz42', 'df', 'jcc', 'aic', 'fff', 'h100px', 'posr', 'mb10', 'mt10')}>
        <span onClick={() => router(-1)} className={className('iconfont', 'icon-gengduo04', 'fff', 'fz28', 'posa', 'l0')}></span>
        Daily Sign-in
      </div>

      <div className={className('week-rearad', 'mb30')}>
        <p className={className('mb50', 'pl25')}>
          <span className={className('fz24', 'fff', 'mr15')}>{t('everySign.getWeekPrize')}</span>
          <span className={className('fz24', 'fff', 'c8A')}>{t('everySign.accumalateAdd')}</span>
        </p>
        <div className={className('mt100', 'posr', 'mr80', 'ml50')}>
          <ProgressBar
            percent={(continueSignDay / 28) * 100}
            style={{
              '--fill-color': '#F8A600',
              '--track-color': '#696968',
            }}
          />
          <div className={className('posa', 'servenDay')}>
            <div className={className('df', 'aic', 'mb30')}>
              <p className={className('fz33', 'df', 'aic', 'fff')}>
                <img className={className('wh25')} src="/images/share/gold-egg.png" alt="" />
                {extra_rewards?.length ? extra_rewards[0].Amount : 200}</p>
            </div>
            <img className={className('gift1', 'mb15')} src="/images/everydaySign/gift1.png" alt="" />
            <p className={className('fz25', 'fff')} >&nbsp;&nbsp;7&nbsp;day</p>
          </div>
          <div className={className('posa', 'Day14')}>
            <div className={className('df', 'aic', 'mb15')}>
              <p className={className('fz33', 'df', 'aic', 'fff')}>
                <img className={className('wh25')} src="/images/share/gold-egg.png" alt="" />
                {extra_rewards?.length ? extra_rewards[1].Amount : 200}</p>
            </div>
            <img className={className('gift2', 'mb10')} src="/images/everydaySign/gift2.png" alt="" />
            <p className={className('fz25', 'fff')} >&nbsp;&nbsp;14&nbsp;day</p>
          </div>
          <div className={className('posa', 'Day21')}>
            <div className={className('df', 'aic', 'mb15')}>
              <p className={className('fz33', 'df', 'aic', 'fff')}>
                <img className={className('wh25')} src="/images/share/gold-egg.png" alt="" />
                {extra_rewards?.length ? extra_rewards[2].Amount : 200}</p>
            </div>
            <img className={className('gift2', 'mb10')} src="/images/everydaySign/gift2.png" alt="" />
            <p className={className('fz25', 'fff')} >&nbsp;&nbsp;21&nbsp;day</p>
          </div>
          <div className={className('posa', 'Day28')}>
            <div className={className('df', 'aic', 'mb15')}>
              <p className={className('fz33', 'df', 'aic', 'fff')}>
                <img className={className('wh25')} src="/images/share/gold-egg.png" alt="" />
                {extra_rewards?.length ? extra_rewards[3].Amount : 200}</p>
            </div>
            <img className={className('gift2', 'mb10')} src="/images/everydaySign/gift2.png" alt="" />
            <p className={className('fz25', 'fff')} >&nbsp;&nbsp;28&nbsp;day</p>
          </div>
        </div>
      </div>

      <div className={className('month_sign_detail_wrap')}>
        <p className={className('fz28', 'fff', 'posa', 'day-tip')}>{t('everySign.dailyReward')}
          <span className={className('cF9C52C')}>&nbsp;&nbsp;{t('everySign.onlyVip')}</span></p>
        {/* <TitleDom icon={star} title={t('monthDaily.monthPayHint')} /> */}
        <div className={className('month_sign_list_wrap')}>
          {allSignList?.length > 0 &&
            allSignList.map((item: any, i: number) => {
              // status:  0: 不可签到 1: 可签到 2: 已签到 3: 已过期                                               
              const { DayNO, BonusType, Amount, status } = item
              return (
                <div key={i} className={className('sign_item_wrap')} onClick={() => onSendSign(item)}>
                  {status === 0 && (
                    <div className={className('received_mask', i % 7 == 0 ? 'bE55179' : 'b4F93FF')}>
                      <p className={className('fz25', 'tac', '')}>Day{DayNO}</p>
                      <div className={className('sec-box', 'b272624', 'df', 'jcc', 'aic', 'c8A', 'fz33')}>
                        {i % 7 == 0 ? <img className={className('tick')} src="/images/everydaySign/gift2.png" alt="" /> : <div><img className={className('gold-egg')} src="/images/share/gold-egg.png" alt="" />{Amount}</div>}
                      </div>
                    </div>
                  )}
                  {status === 1 && (
                    <div className={className('received_mask', i % 7 == 0 ? 'bE55179' : 'b4F93FF')}>
                      <p className={className('fz25', 'tac', '')}>Day{DayNO}</p>
                      <div className={className('sec-box', 'b272624', 'df', 'jcc', 'aic', 'c8A', 'fz33')}>
                        {i % 7 == 0 ? <img className={className('tick')} src="/images/everydaySign/gift2.png" alt="" /> : <div><img className={className('gold-egg')} src="/images/share/gold-egg.png" alt="" />{Amount}</div>}
                      </div>
                    </div>
                  )}
                  {status === 2 && (
                    <div className={className('received_mask', 'bF8B500')}>
                      <p className={className('fz25', 'tac', '')}>Day{DayNO}</p>
                      <div className={className('sec-box', 'b272624', 'df', 'jcc', 'aic', 'c8A', 'fz33')}>
                        <img className={className('tick')} src="/images/everydaySign/tick.png" alt="" />
                      </div>
                    </div>
                  )}
                  {status === 3 && (
                    <div className={className('received_mask', 'bA3A2A0')}>
                      <p className={className('fz25', 'tac', '')}>Day{DayNO}</p>
                      <div className={className('sec-box', 'b272624', 'df', 'jcc', 'aic', 'c8A', 'fz33')}>
                        miss
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
        </div>
        {/* {type === 0 && (
          <div className={className('view_all_list')}>
            <span onClick={onViewAllSignList}>{t('monthDaily.allReward')}</span>
          </div>
        )} */}
      </div>
    </div>
  )
}

export default MonthSignContent
