/**
 * tool
 */
import { useEffect } from 'react'
import classnames from 'classnames/bind'
import style from './index.module.scss'
import PageContainer from '../../layout/PageContainer'
import ActivityList from './components/activityList'

import { CommonData } from '../../Common/Common'
const className = classnames.bind(style)

function OldActivity() {
  useEffect(() => {
    CommonData.Common.GetClientConfig();
  }, []);

  return (
    <PageContainer>
      <section className={className('activity_wrap')}>
        <ActivityList />
      </section>
    </PageContainer>
  )
}

export default OldActivity
