import { Toast } from "antd-mobile";
import NewHttpNet from "../api/HttpNetMg";
import { ResponseGenerator } from "../api/types";
import { CommonData } from "./Common";

// 查询首充活动配置
export function GetFirstreCharge() {
    return NewHttpNet({
        url: "/firstrecharge/getconfig",
        method: "post",
    });
}

export default class FirstChargeData {
    private static instance: any = null;
    public static get Instance(): FirstChargeData {
        if (this.instance == null) {
            this.instance = new FirstChargeData();
        }
        return this.instance;
    }

    private config: any;
    public get Config(): any {
        return this.config;
    }

    public get IsActive(): boolean {
        let isactive = false;
        const userinfo = CommonData.UserInfoData;
        if (userinfo && userinfo.PayTotal === 0) {
            let activityConfi: any = CommonData.Common.ClientConfig?.ActivityConfig;
            isactive = activityConfi && activityConfi.FirstRecharge === 1;
        }
        return isactive;
    }

    private isshow = false;

    public get IsShow(): boolean {
        return this.isshow;
    }

    public set IsShow(v: boolean) {
        this.isshow = v;
        CommonData.FirstPay = this;
    }

    public async GetConfig() {
        const userinfo = CommonData.UserInfoData;
        if (userinfo && userinfo.PayTotal === 0) {
            const resData: ResponseGenerator = await GetFirstreCharge();
            if (resData.code === 0) {
                this.config = resData.data;
                CommonData.FirstPay = this;
            }
            else {
                Toast.show(resData.msg)
                // CommonData.Common.PopTips = resData.msg;
            }
        }
    }
}