import classnames from 'classnames/bind';
import style from './index.module.scss';
import { useEffect, useRef } from 'react';
import { CommonData } from '../../../Common/Common';

const className = classnames.bind(style);

const TipsPop = () => {
    const refMask = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        setTimeout(() => {
            CommonData.Common.PopTips = '';
        }, 3000);
    }, []);
    return (
        <div className={className('bg')}>
            <div ref={refMask} className={className('container')}>
                <div className={className('tipsbg')}>
                    <div className={className('tipsmsg')}>{CommonData.Common.PopTips}</div>
                </div>
            </div>
        </div>
    )
}


export default TipsPop;