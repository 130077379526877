/**
 * tool
 */
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import classnames from 'classnames/bind'
import style from './index.module.scss'
import MenuLayout from './menuLayout';
import { CommonData } from '../../Common/Common';
import ComState from '../../Common/ComState';

/** images */
const footer_bg = '/images/layout/footer/footer_bg.png';
const activity = '/images/layout/footer/activity.png';
const activity_active = '/images/layout/footer/activity_active.png';
const casino = '/images/layout/footer/casino.png';
const casino_active = '/images/layout/footer/casino_active.png';
const menu = '/images/layout/footer/menu.png';
const menu_active = '/images/layout/footer/menu_active.png';
const personal = '/images/layout/footer/personal.png';
const personal_active = '/images/layout/footer/personal_active.png';
const wallet = '/images/layout/footer/wallet.png';
const wallet_active = '/images/layout/footer/wallet_active.png';

const className = classnames.bind(style);
const initialState = {
  menuState: false,
}
const navList = [
  { type: 0, path: '/', title: 'Menu.home', iconActive: casino_active, icon: 'icon-shouye' },
  { type: 1, path: '/memberShip', title: 'Menu.vip', iconActive: personal_active, icon: 'icon-vip' },
  { type: 2, path: '/activity', title: 'orderCoin.share', iconActive: activity_active, icon: 'icon-mubiao' },
  { type: 3, path: '/everySign', title: 'footerMenu.task', iconActive: wallet_active, icon: 'icon-fenxiang' },
  { type: 4, path: '/user', title: 'footerMenu.menu', iconActive: menu_active, icon: 'icon-wode' },
]
const MobileNav = () => {
  /* i18n */
  const { t } = useTranslation()
  /* router */
  const router = useNavigate();
  const routerPathname = useLocation().pathname;
  const { state, updateState } = ComState(initialState);
  const { menuState } = state.mdata;

  const navMode = useMemo(() => {
    const routerModes = ['/', '/memberShip', '/activity', '/everySign', '/user'];
    for (let index = 0; index < routerModes.length; index++) {
      // if (routerPathname.startsWith(routerModes[index])) {
      if (routerPathname === routerModes[index]) {
        return routerModes[index];
      }
    }
    return 'none';
  }, [routerPathname])

  /* function */
  const onGoPage = (path: string, item: any) => {
    const { type } = item;
    router(path);
  }

  const updateMenuState = (state: boolean) => {
    updateState({ menuState: state });
  }

  return (
    <>
      <div className={className('nav_container')}>
        {/* <div className={className('nav_bg')}>
          <img src={footer_bg} alt='footer_bg' className={className('nav_bg')} />
        </div> */}
        <div className={className('nav_section')}>
          {
            navList.map((item, i) => {
              const { title, icon, iconActive, type, path } = item;
              return (
                <div
                  key={i}
                  className={className('nav_item')}
                  onClick={() => { onGoPage(path, item) }}>
                  <div className={className('nav_item_icon')}>
                    {/* <img src={path.startsWith(navMode) ? iconActive : icon} alt={'navitem' + type} /> */}
                    <span className={className('iconfont', 'fz43', path === navMode ? 'cF9C52C' : 'c5A5958', icon)}></span>
                    {/* {(item.type === 3) && <div className={className('red')}></div>} */}
                  </div>
                  <div className={className('text-xs', { active: path === navMode })}>{t(title)}</div>
                </div>
              )
            })
          }
        </div>
      </div>
      <MenuLayout showState={menuState} updateMenuState={updateMenuState} />
    </>
  )
}

export default MobileNav
