import React, { useEffect } from 'react'
import classnames from 'classnames/bind';
import { getItem } from '../../../utils/tools';
/**
 * tool
 */
import style from './index.module.scss';
/**
 * components
 */
import PopupContainer from '../PopupContainer'
import Switch from './Switch';
import SignInLayout from './SignInLayout';
import SignUpLayout from './SignUpLayout';
import { CommonData } from '../../../Common/Common';
import SignInIframe from './SignInIframe';
import ComState from '../../../Common/ComState';
import { Radio } from "antd-mobile";

/**
 * utils
 */
const className = classnames.bind(style);
const initialState = {
  type: 1, // 1: login 2: register
  invitation: '', // 注册码
  logingway: 0,
}

export default function LoginPopup() {

  const common = CommonData.Common;
  const { state, updateState } = ComState(initialState);

  const { type, invitation, logingway } = state.mdata;

  const close = () => {
    common.ShowLogin = 0;
  }

  useEffect(() => {
    if (common.ShowLogin < 0) return;
    const invitation = getItem('invitation') || '';
    updateState({ type: common.ShowLogin, invitation });
  }, [common.ShowLogin])

  const closeGoogleLogin = () => {
    updateState({ logingway: 0 });
  }

  const showLoginType = (type: string) => {
    CommonData.Common.RequestLoading = true;
    if (type === 'google') {
      updateState({ logingway: 1 });
    }
    else {
      updateState({ logingway: 2 });
    }
  }

  return (
    <>
      <PopupContainer outsideClick={false} close={close}>
        <section className={className('main', 'pt55')}>
          {/* <div className={className('block')}></div> */}
          <Switch type={type} onChange={(value: number) => updateState({ type: value })} />
          <div className={className('w100')}>
            <div className={className('b272624', '', 'mr25', 'ml25', 'mt25', 'brs27')}>
              <div className={className('block_second')}></div>

              {type === 1
                ? <SignInLayout showGoogleLogin={showLoginType} />
                : <SignUpLayout reference={invitation} />
              }

            </div>
          </div>
        </section>
      </PopupContainer>
      {logingway > 0 && <SignInIframe hidePop={closeGoogleLogin} regway={logingway} />}
    </>
  )
}
