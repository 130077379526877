import { publicIpv4 } from "public-ip";
import { CSSProperties, RefObject } from "react";
import { AES, enc } from "crypto-js"

const envSecretKey = process.env.NEXT_PUBLIC_USER_SECRET
// 加密信息
export function decodeAES<T>(str: T, secretKey?: string): string {
  if (!secretKey?.length && !envSecretKey?.length) {
    throw Error('请配置NEXT_PUBLIC_USER_SECRET或传入secretKey');
  }
  secretKey = secretKey || envSecretKey

  return AES.encrypt(JSON.stringify(str), secretKey as string).toString();
}

// 解密信息
export const encodeAES = (str: string, secretKey?: string) => {
  if (!secretKey?.length && !envSecretKey?.length) throw Error('请配置NEXT_PUBLIC_USER_SECRET或传入secretKey');

  secretKey = secretKey || envSecretKey

  let data: any = AES.decrypt(str, secretKey as string).toString(enc.Utf8);
  try {
    data = JSON.parse(data)
  } catch (e) {
  }

  return data
}

// 格式化冷却时间
export const fomatSecond = (time: number) => {
  const push0 = (num: number) => (num <= 9 ? ("0" + num) : num.toString());

  return `${push0(Math.floor(time / 60 / 60))}:${push0(
    Math.floor((time / 60) % 60)
  )}:${push0(time % 60)} `;
};

/**
 *
 * @param {*} refresh true重新获取，false从缓存获取（缓存没有则重新获取）
 * @returns ip: string
 */
export const getIp = async (refresh?: boolean): Promise<string | null> => {
  if (isServer()) return null
  try {
    let ip: string | null = null

    if (refresh) {
      ip = await publicIpv4();
    } else {
      ip = window?.localStorage?.getItem("ip");

      if (!ip) {
        ip = await publicIpv4();
      }
    }

    window?.localStorage?.setItem?.("ip", ip)

    return ip
  } catch (err) {
    return null
  }
}

export const isServer = (): boolean => typeof window === 'undefined';
export const isClient = (): boolean => !isServer()

export const getItem = (key: string) => {
  const value = localStorage?.getItem?.(key)
  if (value) {
    try { // 防止出现undefined等不可被JSON.parse解析的值抛错
      return JSON.parse(value)
    } catch (e) {
      return null
    }
  }
  return null
}

export const setItem = (key: string, item: any): void => {
  localStorage?.setItem(key, JSON.stringify(item));
}

export const removeItem = (key: string): void => {
  localStorage?.removeItem(key);
}

/**
 *deviceType // 设备类型
  OS //操作系统
  OSVersion? //操作系统版本
  screenHeight? //屏幕高
  screenWidth? //屏幕宽
  language //当前使用的语言-国家
  netWork //联网类型
  orientation //横竖屏
  browserInfo //浏览器信息
  fingerprint //浏览器指纹
  userAgent //包含 appCodeName,appName,appVersion,language,platform 等
  geoPosition //地理位置
  date //阳历日期时间
  week //周几
  UUID //通用唯一标识 Universally Unique Identifier
 */
// type DeviceConfigInfo = 'deviceType' | 'OS' | 'OSVersion' | 'screenHeight' | 'screenWidth' | 'language' | 'netWork' | 'orientation' | 'browserInfo' | 'fingerprint' | 'userAgent' | 'geoPosition' | 'date' | 'week' | 'UUID'
type DeviceConfigInfo = 'deviceType' | 'OS' | 'OSVersion' | 'screenHeight' | 'screenWidth' | 'language' | 'netWork' | 'orientation' | 'browserInfo' | 'fingerprint' | 'userAgent' | 'date' | 'week' | 'UUID'

interface DeviceConfig {
  domain?: string;
  info?: DeviceConfigInfo[];
  refresh?: boolean;
}

const _getDevice = async ({ info, domain }: DeviceConfig) => {
  const config: DeviceConfig = {}
  if (domain) config.domain = domain
  if (info) config.info = info
  try {
    const Device: any = await import('@skillnull/device-js');
    const device = await Device.default.Info(config)
    if (device) {
      setItem('device', device)
    }
    return device
  } catch (e) {
    return {}
  }
}

export const getDevice = async (config?: DeviceConfig) => {
  const { info, domain, refresh } = config || {}

  if (typeof window !== "undefined") {
    if (refresh) {
      return _getDevice({ info, domain })
    } else {
      const device = getItem('device')

      if (device) {
        return device
      } else {
        return _getDevice({ info, domain })
      }
    }
  }

  return {}
}

// todo 如果有图片的内容高度显示有误监听图片的onError或onLoad事件做处理
export const getIframeHeight = (iframeRef: RefObject<HTMLIFrameElement>, defaultHeight = 0) => {
  try {
    if (iframeRef?.current) {
      const ref = iframeRef?.current?.contentWindow
      const { body, documentElement } = ref?.document || {};

      return Math.max(body?.scrollHeight || 0, documentElement?.scrollHeight || 0, defaultHeight)
    } else {
      return defaultHeight
    }
  } catch (ex) {
    return defaultHeight
  }
}

// 中划线转驼峰
export const camelize = (str: string) => {
  return (str + "").replace(/-\D/g,
    function (match) {
      return match.charAt(1).toUpperCase();
    });
}

// 驼峰转中划线
export const hyphenate = (str: string) => {
  return (str + "").replace(/[A-Z]/g,
    function (match) {
      return "-" + match.toLowerCase();
    });
}

// 设置富文本默认样式并添加自定义样式
export const iframeSetDefaultStyle = (docs: string | undefined, style: any = {}) => {
  if (!docs) return ''

  if (!style?.color) {
    style.color = '#fff'
  }

  const wordBreak: CSSProperties = {
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap'
  }

  style = { ...wordBreak, ...style }

  let styleString = ''
  for (const key in style) {
    styleString = `${styleString}${hyphenate(key)}: ${style[key]}; `
  }

  if (typeof docs === 'string' && docs?.length) {
    return `<style type="text/css">
    html, body {
      margin: 0
    }
    </style>
    <div style='${styleString}'>${docs}</div>`
  }
}

/**
 *
 * @param page
 * @param pageSize
 * @param total
 * @param state true为下一页  false为上一页
 * @returns
 */
export const getPageIndex = (page: number, pageSize: number, total: number, state: boolean): number | string => {
  const prevPage = page <= 0 ? 0 : page - 1;
  const restTotal = total - pageSize * page
  const nextPage = restTotal <= pageSize ? page : page + 1;
  const pageIndex = state ? nextPage : prevPage;
  return pageIndex === page ? 'none' : pageIndex;
}

/**
 * 根据语言适配图片资源
 * @param imgList   图片资源集合
 * @param lang      当前语言
 */
export const imgAdaptiveLang = (imgList: any, lang: string) => {
  return imgList[lang] || imgList.en;
}
