// 全民代理
import { Toast } from "antd-mobile";
import NewHttpNet from "../api/HttpNetMg";
import { ResponseGenerator } from "../api/types";
import { CommonData } from "./Common";

// 查询全民代理配置
export function GetAllAffiliate() {
    return NewHttpNet({
        url: "/affiliateconfig/getall",
        method: "post",
    });
}

// 查询用户全民代理数据
export function GetUserAffiliate(userid: number) {
    return NewHttpNet({
        url: "/affiliateagent/getlist",
        method: "post",
        data: { userid }
    });
}

export default class AgentData {
    private static instance: any = null;
    public static get Instance(): AgentData {
        if (this.instance == null) {
            this.instance = new AgentData();
        }
        return this.instance;
    }

    private config: any;
    private useragent: any;

    public get Config(): any {
        return this.config;
    }

    public get UserAgent(): any {
        return this.useragent;
    }

    public async GetConfig() {
        if (!this.config) {
            const resData: ResponseGenerator = await GetAllAffiliate();
            if (resData.code === 0) {
                this.config = resData.data;
                CommonData.Agent = this;
            }
            else {
                Toast.show(resData.msg)
                // CommonData.Common.PopTips = resData.msg;
            }
        }
    }

    public async GetUserAgent() {
        const userinfo = CommonData.UserInfoData;
        if (userinfo) {
            const resData: ResponseGenerator = await GetUserAffiliate(userinfo.UID);
            if (resData.code === 0) {
                this.useragent = resData.data;
                CommonData.Agent = this;
            }
            else {
                Toast.show(resData.msg)
                // CommonData.Common.PopTips = resData.msg;
            }
        }
    }

    public Quit() {
        this.useragent = null;
    }
}