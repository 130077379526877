import classnames from 'classnames/bind';
import style from './index.module.scss';
import CustomImage from '../../components/CustomImage';

const USDTIcon = '/images/components/currency/USDT.png';

const className = classnames.bind(style);
export function GuideSuccessTips(props: any) {
  const { loginAuth } = props;
  return (
    <div className={className('message', 'guide-msg-box')}>
      <div className={className('guide-message')}> You received </div>
      <div className={className('guide-message')}> 1.00000000 </div>
      <CustomImage src={USDTIcon} alt={'Message'} width={14} height={14} />
      <div className={className('guide-message')}> from</div>
      <div className={className('guide-message')}> { loginAuth.username }</div>
    </div>
  )
}

export function UserLoginCompleteTips(props: any) {
  const { text, account } = props;
  return (
    <div className={className('message', 'login-complete-box')}>
      <div className={className('complete-message')}>{ text }</div>
      <div className={className('complete-message')}>{ account } !</div>
    </div>
  )
}
