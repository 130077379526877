"use client"
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useMemo } from 'react'
import classnames from 'classnames/bind'
import style from './index.module.scss'
import SpinPop from '../../../components/ActivityModule/Spin/SpinPop'
import { imgAdaptiveLang } from '../../../utils/tools'
import { CommonData } from '../../../Common/Common'
import BoxCommon from '../../Popup/BoxCommon'
import ComState from '../../../Common/ComState'

/** images */
const result_bg = '/images/activityModule/spin/result_bg.png';        // 结果背景
const close = '/images/activityModule/spin/close.png';
const circle_icon = '/images/activityModule/spin/circle_icon.png';     // 圆点
const circle_icon_light = '/images/activityModule/spin/circle_icon_light.png';      // 发光圆点
const max_reward_title_cn = '/images/activityModule/spin/max_reward_title_cn.png';      // 最大奖励标题
const max_reward_title_en = '/images/activityModule/spin/max_reward_title_en.png';      // 最大奖励标题
const max_reward_title_pt = '/images/activityModule/spin/max_reward_title_pt.png';      // 最大奖励标题
const max_reward_title_ind = '/images/activityModule/spin/max_reward_title_ind.png';      // 最大奖励标题
const max_reward_title_vi = '/images/activityModule/spin/max_reward_title_vi.png';      // 最大奖励标题
const max_reward_bg = '/images/activityModule/spin/max_reward_bg.png';            // 最大奖励背景
const rule_bg = '/images/activityModule/spin/rule_bg.png';            // 规则背景
const spin_arrow = '/images/activityModule/spin/spin_arrow.png';
const spin_arrow2 = '/images/activityModule/spin/spin_arrow2.png';
const spin_bg = '/images/activityModule/spin/spin_bg.png';
const spin_blue_bg = '/images/activityModule/spin/spin_blue_bg.png';
const spin_blue_bg_light = '/images/activityModule/spin/spin_blue_bg_light.png';
const spin_start_btn_bg = '/images/activityModule/spin/spin_start_btn_bg.png';
const spin_start_btn_text_cn = '/images/activityModule/spin/spin_start_btn_text_cn.png';
const spin_start_btn_text_en = '/images/activityModule/spin/spin_start_btn_text_en.png';
const spin_start_btn_text_pt = '/images/activityModule/spin/spin_start_btn_text_pt.png';
const spin_start_btn_text_ind = '/images/activityModule/spin/spin_start_btn_text_ind.png';
const spin_start_btn_text_vi = '/images/activityModule/spin/spin_start_btn_text_vi.png';
const goldIcon = '/images/components/currency/BTC.png'
const scoreIcon = '/images/components/currency/BCH.png'

const className = classnames.bind(style);    // 旋转圈数, PS: 不能小于2圈, 否则动画会有明显的停顿
const initialState = {      // 当前页局部变量
  spinState: 0,                // 转盘状态 0: 初始化未启动,  1: 转盘旋转中  2: 转盘旋转结束, 结算中
  flickerState: 0,                // 周围灯光闪烁状态   0: 奇数亮偶数暗 1: 奇数暗偶数亮  2: 全亮  3: 全暗
  resultLightAnimateState: false,      // 中奖灯光闪烁动画状态
  showSpinResult: false,              // 转盘结果显示/隐藏
  showSpinPop: false,                 // 余额不足提示弹窗显示/隐藏
  openType:0,
}
const itemDeg = 22.5;   // 每个奖励占据的角度
const spinEndState = false                 // 转盘活动是否已结束
const rotateNum = 6; // 6圈后停下
const Spin = () => {
  const {t, i18n} = useTranslation();
  const rotation = CommonData.Rotation;
  const userinfo = CommonData.UserInfoData;
  // ref
  const spinDom: any = useRef();          // 转盘容器
  const goldIconDom: any = useRef();      // 金币dom
  const spinContentWrap: any = useRef()   // 转盘内容
  /** state */
  const { state, updateState } = ComState(initialState);
  const { spinState, flickerState, resultLightAnimateState, showSpinResult, showSpinPop, openType } = state.mdata;

  const prizeList = useMemo(() => {       // 奖品列表
    if(!rotation.DataConfig) return null;
    return rotation.DataConfig;
  }, [rotation.DataConfig]);

  const resultItem = useMemo(() => {        // 中奖元素
    if(rotation.SpinResultID < 0) return null;
    return prizeList.find((item: any) => item.ID === rotation.SpinResultID);
  }, [rotation.SpinResultID, prizeList]);

  /** effect */
  useEffect(() => {           // 初始化请求spinInfo接口
    if(spinEndState) {
      activeEnd();      // 活动结束
    } else {
      if(spinState !== 0) {         // 如果初始化时转盘状态不为0, 就修改为0
        updateState({spinState: 0});
      }
      rotation.GetRotationConfig();
    }
  }, []);

  // 监听获取到中奖结果后修改转盘状态
  useEffect(() => {
    if (resultItem) {
      updateState({ spinState: 1 })
    }
  }, [resultItem]);

   // 监听转盘旋转中灯光闪烁状态
   useEffect(() => {
    if(spinState === 0 || spinState === 1) {       // 转盘未启动状态和启动中
      setTimeout(() => {
        updateState({ flickerState: flickerState === 0 ? 1 : 0 });
      }, spinState === 0 ? 500 : 200);
    }
    if(spinState === 2) {         // 转盘启动结束
      setTimeout(() => {
        updateState({ flickerState: flickerState === 2 ? 3 : 2 });
      }, 400);
    }
  }, [flickerState, spinState]);

   // 监听中奖区域闪烁
   useEffect(() => {
    if(spinState === 2) {
      setTimeout(() => {
        updateState({ resultLightAnimateState: !resultLightAnimateState });
      }, 300)
    }
  }, [resultLightAnimateState, spinState]);

  // 监听转盘状态的变化
  useEffect(() => {
    switch(spinState) {
      case 0:       // 转盘开始前初始化
        spinDom.current.style.transform = 'rotate(0deg)';
        spinDom.current.style.transition = 'none';
        updateState({ resultLightAnimateState: false });                      // 停止结果发光动画       
        rotation.SpinResultID = -1; // 初始化清空转盘上一轮的结果数据
        break;
      case 1:
        startSpinAnimate();
        break;
      case 2:
        updateState({showSpinResult: true});
        setTimeout(() => {
          updateState({showSpinResult: false});
        }, 1500);
        userinfo?.RefreshUserInfo();
        break;
    }
  }, [spinState])

  /** function */
  // 活动结束提示弹窗
  const activeEnd = () => {
    // 活动已结束弹窗
    CommonData.Common.GamePopupData = {
      title: t('spinActivity.hint'),
      message: t('noviceGift.loseEfficacyTips'),
      cancelState: false,
      confirmCallback: () => {
        CommonData.Common.GamePopupData = null;
        hidePop();   // 隐藏转盘
      }
    }
  }
  const hidePop = () => {     // 关闭转盘
    if(spinState === 1) return;     // 旋转中不可关闭弹窗
    updateState({spinState: 0});
    rotation.IsShow = false;
  }
  // start spin
  const onStartSpin = () => {
    if (spinState !== 0) return;
    if(rotation.UserData?.RotationAmount <= 0) {     // 次数不够, 显示提示弹窗
      updateState({showSpinPop: true});
      return;
    }
    rotation.SpinRotation();
  }
  // 启动转盘动画
  const startSpinAnimate = async (time=600, index=0) => {
    const spinCom = spinDom.current;
    if(index > rotateNum) {          // 转盘转动结束
      const i = resultItem?.index || 0;      // 中奖序号下标
      const rotate = 360 * (index + 1) + 90 - i * itemDeg;       // 结果角度
      const animateTime = time * ((i * itemDeg + 360) / 360);    // 最后一圈需要的时间
      spinCom.style.transform = 'rotate('+rotate+'deg)';
      spinCom.style.transition = 'transform ' + animateTime + 'ms linear';
      setTimeout(() => {
        // 转盘结束处理
        updateState({spinState: 2});
        goldIconAnimate(i);
        setTimeout(() => {
          updateState({spinState: 0});      // 改回初始化状态
        }, 3500)
      }, animateTime + 100);
      return;
    }
    spinCom.style.transform = 'rotate(' + ( 360 * ( index + 1 )) + 'deg)';
    spinCom.style.transition = 'transform ' + time + 'ms linear';

    const onTransitionend = () => {
      if(index > rotateNum - 2) {     // 减速
        time += 250;
      }
      index++;
      startSpinAnimate(time, index);
      spinCom.removeEventListener('transitionend', onTransitionend);
    };
    spinCom.addEventListener('transitionend', onTransitionend);
  };

  // 金币飞入钱包动画
  const goldIconAnimate = (index = 0) => {
    const dom = goldIconDom.current;
    // const pos = setGoldPos(index * 20 - 90);     // 动态位置
    const pos = setGoldPos(4 * itemDeg - 90);      // 修改为转盘转动, 指针不动, 因此中奖位置固定
    dom.style.left = pos.left;
    dom.style.top = pos.top;
    // console.log('pos: ', pos);
    document.styleSheets[0].insertRule(
      `@keyframes goldAnimate {
        0% {
          opacity: 0;
          transform: rotateY(0);
          left: ${pos.left};
          top: ${pos.top};
        }
        10% {
          opacity: 1;
          transform: rotateY(0);
          left: ${pos.left};
          top: ${pos.top};
        }
        90%{
          opacity: 1;
          transform: rotateY(1440deg);
          left: ${pos.endLeft};
          top: ${pos.endTop};
        }
        100%{
          opacity: 0;
          transform: rotateY(1440deg);
          left: ${pos.endLeft};
          top: ${pos.endTop};
        }
      }`, 0);
    dom.style.animation = 'goldAnimate 1.5s ease-in forwards';
    setTimeout(() => {
      document.styleSheets[0].deleteRule(0);      // 动画执行完成后删除设置的keyframe样式
    }, 1600);
  }
  // 根据结果更新金币位置
  const setGoldPos = (angle = 0) => {
    const dom = goldIconDom.current;            // 金币
    const spinWrap = spinContentWrap.current;     // 转盘容器
    const rect = spinWrap.getBoundingClientRect();
    // 初始化设置金币位置在圆心点
    dom.style.left = "50%";
    dom.style.top = "50%";

    const startPosX = Number(getComputedStyle(spinWrap).width.split('px')[0]) * 0.15;
    const left = Number(getComputedStyle(dom).left.split('px')[0]);
    const top = Number(getComputedStyle(dom).top.split('px')[0]);
    const dis = left - startPosX;     // 中心点到中奖位置的距离

    const endTop = (20 - rect.y) + 'px';
    const endLeft = (Number(getComputedStyle(document.body).width.split('px')[0]) * 0.31 - rect.x) + 'px';

    return {
      top: (top + dis * Math.sin(angle/360*2*3.14)) + 'px',
      left: (left + dis * Math.cos(angle/360*2*3.14)) + 'px',
      endTop,
      endLeft
    }
  }
  // 根据状态获取周围灯图片
  const getLightIcon = (i: number) => {
    if(flickerState === 0) {
      return i % 2 === 0 ? circle_icon : circle_icon_light;
    }
    switch(flickerState) {
      case 0: return i % 2 === 0 ? circle_icon : circle_icon_light;     // 奇数亮偶数暗
      case 1: return i % 2 === 0 ? circle_icon_light : circle_icon;     // 奇数暗偶数亮
      case 2: return circle_icon_light;                                 // 全亮
      case 3: return circle_icon;                                       // 全暗
      default: return circle_icon;
    }
  }

  return (
    <BoxCommon bgStyle={{visibility: spinEndState ? 'hidden' : 'visible'}}>
        <div className={className('pop_contrainer')}>
          <div className={className('spin_box')}>
            {/* 转盘背景 */}
            <div className={className('spin_bg_wrap')}>
              <img src={spinState === 2 ? spin_blue_bg_light : spin_blue_bg} alt="spin_bg"/>
            </div>
            {/* close btn */}
            <div className={className('pop_close_btn')} onClick={hidePop}>
              <img src={close} alt="close"/>
            </div>
            {/* 转盘内容 */}
            <div className={className('spin_content_wrap')} ref={spinContentWrap}>
              {/* 转盘旋转区域 */}
              <div className={className('spin_rotate_wrap')} ref={spinDom}>
                <img src={spin_bg} alt="spin_content_bg"/>
                {/* 奖励列表 */}
                <div className={className('spin_prize_list_wrap')}>
                  {
                    prizeList?.map((item: Record<string, any>, i: number) => {
                      return (
                        <div className={className('spin_prize_item_wrap')} key={i}>
                          {/* 编号{item.id}:  */}
                          <div className={className('currency_money')}>{ Number(item.Amount).toFixed(0) }</div>
                          <div className={className('currency_icon')}>
                            <img src={item.AmountType === 1?scoreIcon:goldIcon} width={20} height={20} alt="itemtype"/>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              {/* 转盘周边灯光 */}
              <div className={className('around_light_wrap')}>
                {
                  prizeList?.map((item: Record<string, any>, i: number) => {
                    return (
                      <div key={i} className={className('spin_light_icon')}>
                        <img src={getLightIcon(i)} alt="spin_light1"/>
                      </div>
                    )
                  })
                }
              </div>
              {/* 转盘指针 */}
              <div className={className('spin_arrow_wrap')}>
                <img src={resultLightAnimateState ? spin_arrow2 : spin_arrow} alt="spin_arrow"/>
              </div>
              {/* 金币 */}
              <div className={className('gold_icon_wrap')} ref={goldIconDom}>
                <img src={openType === 1?scoreIcon:goldIcon} width={20} height={20} alt="currency"/>
              </div>
              {/* 转盘启动按钮 */}
              <div className={className('spin_start_btn_wrap')} onClick={onStartSpin}>
                <img src={spin_start_btn_bg} alt="spin_start_btn_bg"/>
                <img
                  className={className('spin_start_btn_text')}
                  src={imgAdaptiveLang({cn: spin_start_btn_text_cn, en: spin_start_btn_text_en, pt: spin_start_btn_text_pt, ind: spin_start_btn_text_ind, vi: spin_start_btn_text_vi}, i18n.language)}
                  alt="spin_start_btn_text"/>
              </div>
            </div>
            {/* 超级大奖 */}
            <div className={className('super_prize_wrap')}>
              <div className={className('super_prize_bg')}>
                <img src={max_reward_bg} alt="max_reward_bg"/>
              </div>
              <div className={className('super_prize_content_wrap')}>
                <div className={className('super_prize_title')}>
                  <img
                    src={imgAdaptiveLang({cn: max_reward_title_cn, en: max_reward_title_en, pt: max_reward_title_pt, ind: max_reward_title_ind, vi: max_reward_title_vi}, i18n.language)}
                    alt="max_reward_title"/>
                </div>
                { rotation.SuperJackpot && <div className={className('super_prize_money')}>
                  <span>{ Number(rotation.SuperJackpot.Amount).toFixed(0) }</span>
                  <img src={rotation.SuperJackpot.AmountType === 1?scoreIcon:goldIcon} width={20} height={20} alt="currency"/>
              </div>  }
              </div>
            </div>
          </div>
          {/* 转盘结束后展示结果 */}
          <div className={className('spin_result_wrap', {show_spin_result_wrap: showSpinResult} )}>
            <div className={className('spin_result_bg')}>
              <img src={result_bg} alt="result_bg"/>
            </div>
            <div className={className('spin_result_content_wrap')}>
              <span>{t('spinActivity.congratulate')} </span>
              <span className={className('result_money')}>{resultItem?.Amount}</span>
              <img src={resultItem?.AmountType === 1?scoreIcon:goldIcon} width={20} height={20} alt="currency"/>
            </div>
          </div>
          <div className={className('spin_number_wrap')}>
            <div className={className('spin_number_text')}>{t('spinActivity.freeSpin') + ': ' + (rotation.UserData?.RotationAmount)}</div>
          </div>
          {/* 规则 */}
          <div className={className('spin_rule_wrap')}>
            <img src={rule_bg} alt="rule_bg"/>
            <div className={className('spin_rule_list')}>
              <p>{'•'}&nbsp;&nbsp;{t('spinActivity.freeSpinDaily')}</p>
              <p>{'•'}&nbsp;&nbsp;{t('spinActivity.spinHint')}</p>
              <p>{'•'}&nbsp;&nbsp;{t('spinActivity.spinHint2')}</p>
            </div>
          </div>
        </div>
        {showSpinPop && <SpinPop hidePop={() => updateState({showSpinPop: false}) }/>}
    </BoxCommon>
  )
}

export default Spin;
