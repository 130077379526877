import { ResponseGenerator } from "../api/types";
import { CommonData } from "./Common";
import { MD5 } from "crypto-js";
import { getItem } from "../utils/tools";
import NewHttpNet from "../api/HttpNetMg";
import { Toast } from "antd-mobile";
import { userStore } from "../store/userStore";

function refreshtoken() {
    return NewHttpNet({
        url: '/user/refreshtoken',
        method: 'post',
    })
}

function getUserInfoByIdAPI(data: any) {
    return NewHttpNet({
        url: '/user/getuser',
        method: 'post',
        data: { ...data },
    })
}

function userLogin(data: any) {
    const password = MD5(data.loginname + data.password).toString();
    console.log('登录password: ', password)
    return NewHttpNet({
        url: '/user/login',
        method: 'post',
        data: { ...data, password }
    })
}

function userLoginOut() {
    return NewHttpNet({
        url: '/user/logout',
        method: 'post'
    })
}

function createAccountAPI(data: any) {
    const password = MD5(data.loginname + data.password).toString();
    return NewHttpNet({
        url: '/user/regist',
        method: 'post',
        data: {
            ...data,
            password
        }
    })
}

function updatePasswdAPI(data: any) {
    return NewHttpNet({
        url: '/user/updatepasswd',
        method: 'post',
        data: { ...data },
    })
}

export default class UserInfoClass {
    private static instance: any = null;
    public static get Instance(): UserInfoClass {
        if (this.instance == null) {
            this.instance = new UserInfoClass();
        }
        return this.instance;
    }

    public UID = 0;
    public PlayMoney = 0;
    public PayTotal = 0;

    public FreeWithdrawMonth = 0;
    public BetTotal = 0;
    public ScoreTotal = 0;
    public ScoreCurrent = 0;
    public ScoreWithdraw = 0;
    public RotationAmount = 0;
    public RotationTodayNumbers = 0;
    public RotationLastTime = 0;
    public DeviceCode = "";
    public DeviceType = "";
    public LastLoginTime = 0;
    public LastLoginIP = "";
    public UserInfo = {
        FaceID: 0,
        LoginName: "",
        Password: "",
        Phonenumber: "",
        Email: "",
        NickName: "",
        ParentID: 0,
        Viplevel: 0,
        Regway: 0,
        RegIP: "",
        RegMAC: "",
        RegDate: 0,
        AgentID: 0,
        ChannelID: 0,
        GoogleAuth: "",
        Desc: "",
        Lang: "",
        BMonthlyReceive: 0,
        BUpgradeReceive: 0,
        BWeeklyReceive: 0
    }
    public WalletMoney = 0;
    public WinLostTotal = 0;
    public WithdrawBetMin = 0; // 最低提现下注额度
    public WithdrawFeePoint = 0;
    public WithdrawMax = 0;
    public WithdrawMoney = 0;
    public WithdrawTotal = 0;

    public token: any;
    public expirestime: any;

    public InitData(data: any, classTb: any) {
        let key: keyof any;
        for (key in data) {
            if (key === 'UserInfo') {
                this.InitData(data[key], this.UserInfo);
            }
            else {
                classTb[key] = data[key];
            }
        }
    }

    private isonRefresh = false; // 防止多次刷新
    // 刷新用户信息
    public async RefreshUserInfo() {
        if (this.isonRefresh) { return }
        console.log('==========刷新用户信息: ');
        const authstr = getItem('auth');
        if (authstr) {
            if (!this.token || !this.expirestime) {
                this.token = authstr.token;
                this.expirestime = authstr.expirestime;
            }
            let uid = this.UID;
            if (uid === 0) {
                const userInfostr: any = localStorage?.getItem('userInfo');
                if (!userInfostr) {
                    CommonData.UserInfoData = null;
                    return;
                }
                const userInfo = JSON.parse(userInfostr);
                if (!userInfo) {
                    CommonData.UserInfoData = null;
                    return;
                }
                uid = userInfo.UID;
            }
            this.isonRefresh = true;
            const resData: ResponseGenerator = await getUserInfoByIdAPI({ uid }).catch(error => error);
            this.isonRefresh = false;
            const { code, data } = resData;
            if (code !== 0) {
                CommonData.UserInfoData = null;
            } else {
                CommonData.UserInfoData = data;
                if (this.timesHander) {
                    clearTimeout(this.timesHander);
                    this.timesHander = null;
                }
                this.RefreshtokenTime(1000);
            }
        }
    }

    public LoginOut() {
        localStorage?.removeItem('userInfo');
        CommonData.UserInfoData = null;
        userLoginOut();
    }

    public async UserLogin(ndata: any) {
        const common = CommonData.Common;
        const resData: ResponseGenerator = await userLogin(ndata).catch(error => error);
        common.RequestLoading = false;
        const { code, data, msg } = resData;
        if (code === 0) {
            common.ShowLogin = 0;
            localStorage?.setItem('userInfo', JSON.stringify({ ...data }))
            // common.MessageTarget = {
            //     type: 'welcome',
            //     title: 'Welcome',
            //     config: { kind: 11, messageType: 'welcome', privateQuery: { account: data.UserInfo.LoginName } }
            // }
            CommonData.UserInfoData = data;
            userStore.setUserData(data);
            if (resData.token) {
                userStore.setToken(resData.token);
                userStore.setExpirestime(resData.expirestime);
                this.token = resData.token;
                this.expirestime = resData.expirestime;
                localStorage?.setItem('auth', JSON.stringify({ token: resData.token, expirestime: resData.expirestime }));
                this.RefreshtokenTime();
            }
        }
        else {
            Toast.show({
                content: resData.msg,
                maskClassName: 'custom-tip',
            })
            // console.log('========>>>>>login erro! ', msg);
            // common.PopTips = msg;
            // common.MessageTarget =
            // {
            //     type: 'error',
            //     title: 'Mistake',
            //     message: msg || 'Login failed, please contact your administrator'
            // };
        }
    }

    public async UserReGister(ndata: any) {
        const common = CommonData.Common;
        const resData = await createAccountAPI(ndata).catch(error => error);
        common.RequestLoading = false;
        const { code, data, msg } = resData;
        if (code === 0) {
            common.ShowLogin = 0;
            localStorage?.setItem('userInfo', JSON.stringify({ ...data }))
            common.MessageTarget = {
                type: 'welcome',
                title: 'Welcome',
                config: { kind: 11, messageType: 'welcome', privateQuery: { account: data.UserInfo.LoginName } }
            }
            CommonData.UserInfoData = data;
            if (resData.token) {
                this.token = resData.token;
                this.expirestime = resData.expirestime;
                localStorage?.setItem('auth', JSON.stringify({ token: resData.token, expirestime: resData.expirestime }));
                this.RefreshtokenTime();
            }
        }
        else {
            common.PopTips = resData.msg;
            common.MessageTarget = {
                type: 'error',
                title: 'Mistake',
                config: { kind: 1, messageType: msg }
            }
        }
    }

    private async Refreshtoken() {
        const authstr = localStorage?.getItem('auth');
        if (!authstr) {
            if (this.token) {
                localStorage?.setItem('auth', JSON.stringify({ token: this.token, expirestime: this.expirestime }));
            }
            else {
                console.log('=======>>>>> Refreshtoken Erro');
                return;
            }
        }
        const resData = await refreshtoken().catch(error => error);
        if (resData.code === 0) {
            this.token = resData.token;
            this.expirestime = resData.expirestime;
            localStorage?.setItem('auth', JSON.stringify({ token: resData.token, expirestime: resData.expirestime }));
            if (CommonData.UserInfoData) {
                this.RefreshtokenTime();
            }
        }
        else {
            CommonData.UserInfoData = null;
        }
    }

    private timesHander: any;
    private RefreshtokenTime(waittime: number = 1200000) {
        if (!this.timesHander) {
            this.timesHander = setTimeout(() => {
                this.timesHander = null;
                this.Refreshtoken();
            }, waittime);
        }
    }

    public async UpdatePasswd(ndata: any) {
        const resData = await updatePasswdAPI(ndata).catch(error => error);
        if (resData.code === 0) {
            Toast.show('Password changed successfully!')

            // CommonData.Common.PopTips = 'Password changed successfully!';
        }
        else {
            // CommonData.Common.PopTips = resData.msg;
            Toast.show(resData.msg)

        }
    }

    // 退出
    public Quit() {
        this.token = null;
        this.expirestime = null;
        this.isonRefresh = false;
        localStorage?.removeItem('auth');
        if (this.timesHander) {
            clearTimeout(this.timesHander);
            this.timesHander = null;
        }
    }
}