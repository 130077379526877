import classnames from 'classnames/bind';
import style from './index.module.scss'
const loading_bg = '/images/common/loading_bg.png';
const loading_content = '/images/common/loading_content.png';
const className = classnames.bind(style)

export default function RequestLoading() {
  return (
    <section className={className('regiter_loading_wrap')}>
      <div className={style.mask}></div>
      <section className={className('regiter_loading')}>
        <img className={className('loading_bg' )}src={loading_bg} alt={'loading_bg'}/>
        <img className={className('loading_content')} src={loading_content} alt={'loading_content'}/>
      </section>
  </section>
  )
}
