import classnames from 'classnames/bind';
import style from './index.module.scss';
import { CommonData } from '../../../../Common/Common';
import { useEffect, useRef, useState } from 'react';
import UserInfoClass from '../../../../Common/UserInfoData';
import { Toast } from 'antd-mobile';
declare const window: any;
const className = classnames.bind(style);

interface popinterface {
  regway: 1,
  hidePop: () => void
}
const SignInIframe = (popdata: popinterface) => {
  const { regway, hidePop } = popdata;
  const originUrl = process.env.THIRD_PARTY_LOGIN;
  let openurl = originUrl + '/#/' + (regway === 1 ? "GoogleLogin" : "FacebookLogin");

  console.log('=========>>>>>login url: ', openurl);
  const refMask = useRef<HTMLDivElement | null>(null);
  const refIframe = useRef<any>()
  const [reloadIframe] = useState(Date.now());

  const handleLoad = () => {
    CommonData.Common.RequestLoading = false;
    console.log('加载完成');
    const iframe = refIframe.current;
    iframe.addEventListener('load', handleLoad);
    iframe.addEventListener('error', handleErro, true);
    iframe.addEventListener('unhandledrejection', handleErro);
    window.addEventListener('message', listenMessage);
  };

  const handleErro = () => {
    onCloseSign();
  }

  const onCloseSign = () => {
    openurl = '';
    hidePop();
    const iframe = refIframe.current;
    if (refIframe.current) {
      refIframe.current.src = '';
    }
    iframe.removeEventListener('load', handleLoad);
    iframe.removeEventListener('error', handleErro);
    iframe.removeEventListener('unhandledrejection', handleErro);
    window.removeEventListener('message', listenMessage);
  }

  const listenMessage = (event: any) => {
    if (event.origin === originUrl) {
      onCloseSign();
      const logindata = JSON.parse(event.data);
      if (logindata && logindata.token) {
        CommonData.Common.RequestLoading = true;
        UserInfoClass.Instance.UserLogin({
          loginname: logindata.token,
          password: logindata.token,
          regway
        });
      }
      else {
        Toast.show('Login Fail')

        // CommonData.Common.PopTips = 'Login Fail';
      }
    }
  };

  return (
    <div className={className('bg')}>
      <div ref={refMask} className={className('container')}>
        <iframe
          key={reloadIframe}
          className={className('frame')}
          width={'100%'}
          height={'100%'}
          src={openurl}
          frameBorder="0"
          ref={refIframe}
          onLoad={handleLoad}
          onError={handleErro}
        ></iframe>
      </div>
    </div>
  )
}

export default SignInIframe;