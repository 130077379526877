
import { useState } from 'react';
import { LoginForm } from '../../types/login';
import { useTranslation } from 'react-i18next'

import {
  validPassword,
  validTextLength,
  validContainsSymbols,
  validContainsEnglishNumbers,
  validFirstWordCapital,
} from '../../utils/validation'
import { CommonData } from '../../Common/Common';
import UserInfoClass from '../../Common/UserInfoData';

const initialForm = {
  username: '',
  password: '',
  areacode: ''
}


export default function useSignUpMain() {
  const {t} = useTranslation();
  const [loginForm, setLoginForm] = useState<LoginForm>(initialForm);
  const {password} = loginForm;
  const [invalidLogin, setInvalidLogin] = useState({
    password: {
      flag: false,
      errMsg: ''
    }
  })
  const onLogin = (pnames:string) => {
    if(CommonData.Common.RequestLoading) return;
    if (validPassword(password)) {
      CommonData.Common.RequestLoading = true;
      UserInfoClass.Instance.UserLogin({loginname: pnames, password, regway:0});
    }
    setInvalidLogin({
      password: {
        flag: password === '' || !validPassword(password),
        errMsg: passwordVaild()
      }
    })
  }

  const passwordVaild = () => {
    if (password === '') {
      // 檢查密碼是否為空
      return t('validation.passwordEmpty')
    } else if (!validTextLength(password)) {
      // 檢查長度是否為8-20字元
      return t('validation.userpwdLength')
    } else if (!validContainsSymbols(password)) {
      // 檢查是否包含特殊符號
      return t('validation.passwordSymbols')
    } else if (!validContainsEnglishNumbers(password)) {
      // 檢查是否包含英文及數字
      return t('validation.passwordTypes')
    } else if (!validFirstWordCapital(password)) {
      // 檢查第一個字元是否為大寫英文字母
      return t('validation.passwordFirst')
    } else {
      return 'Password must contain English in small caps'
    }
  }

  // 修改表单项
  const onUpdateLoginForm = (key: string, value: string | string[]): void => {
    const temp:any = {}
    temp[key] = value;
    setLoginForm(Object.assign({}, loginForm, temp))
  }

  return {
    onLogin,
    onUpdateLoginForm,
    loginForm,
    invalidLogin,
  }
}
