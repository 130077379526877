import { Suspense, useEffect } from 'react';
import { CommonData } from './Common/Common';
import RouterView from './RouterView';
import Loading from './components/Loading';
import MuseMessage from './components/MuseMessage';
import LoginPopup from './components/Popup/LoginPopup';
import ConfirmPopup from './components/ConfirmPopup';
import MonthSignPopup from './components/ActivityModule/MonthSignPopup';
import RequestLoading from './components/Loading/RequestLoading';
import TipsPop from './components/Popup/TipsPop';
import Spin from './components/ActivityModule/Spin';
import UserInfoClass from './Common/UserInfoData';
import style from './index.module.scss';
import { NetManager } from './Common/NetManager';
import './locales/i18n';
import './styles/globals.css';
import FirstCharge from './components/ActivityModule/FirstCharge';
import { fullscreen } from './api/settings';
import LuckyCode from './components/ActivityModule/LuckyCode';
import "amfe-flexible"

function App() {
  CommonData.Init();
  const common = CommonData.Common;
  const rotation = CommonData.Rotation;
  const checkin = CommonData.Checkin;
  const userInfo = CommonData.UserInfoData;
  const firstpay = CommonData.FirstPay;
  /**
    * 只触发一次的api, 如需再次触发某个api，则需指定trigger类型
    * @param trigger
    */
  const onceInit = async () => {
    if (common.AuthInited) {
      return;
    }
    common.AuthInited = true;
    await UserInfoClass.Instance.RefreshUserInfo();
  }

  useEffect(() => {
    if (!userInfo) return;
    NetManager.Instance.OpenSocket();
    return () => {
      NetManager.Instance.CloseSocket();
    }
  }, [userInfo]);

  useEffect(() => {
    onceInit();
    fullscreen();
  }, []);

  return (
    <div className={style.maindiv}>
      <div className={style.gameiframe}>
        <RouterView></RouterView>
        <MuseMessage />
        {
          common.AuthInited &&
          <Suspense fallback={<Loading />}>
            {common.ShowLogin > 0 && <LoginPopup />}
            {common.RequestLoading && <RequestLoading />}
            {checkin.IsShow && <MonthSignPopup />}
            {rotation.IsShow && <Spin />}
            {firstpay.IsShow && <FirstCharge />}
            {common.GamePopupData && <ConfirmPopup />}
            {common.PopTips && common.PopTips !== '' && <TipsPop />}
            {common.ShowLuckyCode && <LuckyCode />}
          </Suspense>
        }
      </div>
    </div>
  );
}

export default App;
