// 转盘活动

import { Toast } from "antd-mobile";
import NewHttpNet from "../api/HttpNetMg";
import { ResponseGenerator } from "../api/types";
import { CommonData } from "./Common";

function getRotationConfig(userid: number) {
    return NewHttpNet({
        url: '/dailyrotation/getall',
        method: 'post',
        data: { userid }
    })
}

function spinRotation(userid: number) {
    return NewHttpNet({
        url: '/dailyrotation/spin',
        method: 'post',
        data: { userid }
    })
}

export default class RotationData {
    private static instance: any = null;
    public static get Instance(): RotationData {
        if (this.instance == null) {
            this.instance = new RotationData();
        }
        return this.instance;
    }

    private dataConfig: any;
    private dataSetting: any;
    private userData: any;
    private spinResultID: any;
    private isShow = false;
    private superPrizeIndex = 0;

    constructor() {
        this.spinResultID = -1;
        this.dataSetting = null;
        this.dataConfig = null;
    }

    public get IsShow(): boolean {
        return this.isShow;
    }

    public set IsShow(v: boolean) {
        this.isShow = v;
        CommonData.Rotation = this;
    }

    public get DataConfig(): any {
        return this.dataConfig;
    }

    public get DataSetting(): any {
        return this.dataSetting;
    }

    public get UserData(): any {
        return this.userData;
    }

    public get SpinResultID(): number {
        return this.spinResultID;
    }

    public set SpinResultID(v: number) {
        this.spinResultID = v;
        CommonData.Rotation = this;
    }

    public get SuperJackpot(): any {
        if (this.dataConfig) {
            return this.dataConfig[this.superPrizeIndex];
        }
        return null;
    }

    // 大转盘配置
    public async GetRotationConfig() {
        const userinfo = CommonData.UserInfoData;
        if (!this.dataConfig && userinfo) {
            const resData: ResponseGenerator = await getRotationConfig(userinfo.UID).catch(error => error);
            if (resData.code === 0) {
                this.dataConfig = resData.data.RotationConfig.RotationItems;
                this.dataSetting = resData.data.RotationConfig.RotationSetting;
                this.userData = resData.data.UserData;
                let Amount = 0;
                this.superPrizeIndex = 0;
                for (let index = 0; index < this.dataConfig.length; index++) {
                    this.dataConfig[index].index = index;
                    if (this.dataConfig[index].Amount > Amount) {
                        this.superPrizeIndex = index;
                        Amount = this.dataConfig[index].Amount;
                    }
                }
                CommonData.Rotation = this;
            }
        }
    }

    // 开始大转盘抽奖
    public async SpinRotation() {
        const userinfo = CommonData.UserInfoData;
        if (userinfo) {
            const resData: ResponseGenerator = await spinRotation(userinfo.UID).catch(error => error);
            if (resData.code === 0) {
                this.spinResultID = resData.data.Index;
                this.userData = {
                    ...this.userData,
                    RotationAmount: resData.data.RotationAmount,
                    RotationTodayNumbers: resData.data.RotationTodayNumbers
                }
                const rnumber = userinfo.RotationAmount - 1;
                CommonData.UserInfoData = {
                    RotationAmount: rnumber,
                    WalletMoney: resData.data.WalletMoney,
                    ScoreCurrent: resData.data.Score
                };
                CommonData.Rotation = this;
            }
            else {
                Toast.show(resData.msg)

                // CommonData.Common.PopTips = resData.msg;
            }
        }
    }

    public Quit() {
        this.spinResultID = -1;
        this.dataSetting = null;
        this.dataConfig = null;
        this.isShow = false;
    }
}