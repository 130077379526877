import { Toast } from "antd-mobile";
import NewHttpNet from "../api/HttpNetMg";
import { ResponseGenerator } from "../api/types";
import { CommonData } from "./Common";

function peekGameUrlAPI(ndata: any) {
  return NewHttpNet({
    url: "/game/logingame",
    method: "post",
    data: { ...ndata },
  });
}

function sendQuitGameAPI(params: any) {
  return NewHttpNet({
    url: "/game/logout",
    method: "get",
    params,
  });
}

function getgamelistAPI() {
  return NewHttpNet({
    url: '/game/gamelist',
    method: 'get'
  })
}

// 玩家最近游戏
function getGameRecordAPI(uid: number) {
  return NewHttpNet({
    url: "/user/getgamerecordlist",
    method: "post",
    data: { uid },
  });
}

export default class GameManger {
  private static instance: any = null;
  public static get Instance(): GameManger {
    if (this.instance == null) {
      this.instance = new GameManger();
    }
    return this.instance;
  }
  private gameid = 0;
  private uid = 0;
  public gameUrl = '';
  private gamelists: any;
  private allGame: any;
  private gametags: any;
  private gamegroudta: any;
  private gamegroudty: any;
  private popularlist: any;
  private gamerecords: any;
  private gametypes: any;

  constructor() {
    this.allGame = null;
    this.gamelists = null;
    this.gametags = null;
    this.popularlist = [];
    this.gamegroudta = {};
    this.gamerecords = null;
  }

  // 流行游戏
  public get PopularList(): any {
    return this.popularlist;
  }

  // 游戏组合 tag
  public get GameGroudTa(): any {
    return this.gamegroudta;
  }

  // 游戏类型 tag
  public get GameTags(): any {
    return this.gametags;
  }

  // 游戏组合 厂商
  public get GameGroudTy(): any {
    return this.gamegroudty;
  }

  // 游戏类型 厂商类型
  public get GameTypes(): any {
    return this.gametypes;
  }

  // 游戏列表, 索引
  public get GameLists(): any {
    return this.gamelists;
  }
  public get AllGame(): any {
    return this.allGame;
  }

  // 游戏记录
  public get GameRecords(): any {
    return this.gamerecords;
  }

  public AddGameRecord(gameid: number) {
    if (this.gamerecords && this.gamerecords.length > 0) {
      if (this.gamerecords[0] !== gameid) {
        const gamelist: number[] = [gameid];
        for (let index = 0; index < this.gamerecords.length && index < 16; index++) {
          if (this.gamerecords[index] !== gameid) {
            gamelist.push(this.gamerecords[index]);
          }
        }
        this.gamerecords = gamelist;
      }
    }
    else {
      this.gamerecords = [gameid];
    }
  }

  // 获取游戏列表
  public async GetGameList() {
    if (this.gamelists !== null) {
      return;
    }
    //game/gamelist
    const resData: ResponseGenerator = await getgamelistAPI().catch(e => e);
    const { code, data, msg } = resData;
    if (code === 0) {
      this.UpdataGameRes(data);
    }
  }

  private UpdataGameRes(data: any) {
    this.gamelists = [];
    this.allGame = data.gamelists;
    this.popularlist = [];
    this.gametags = [];
    this.gamegroudta = {};
    for (let index = 0; index < data.gametags.length; index++) {
      const tagitem = data.gametags[index];
      this.gametags[tagitem.ID] = tagitem;
      this.gamegroudta[tagitem.ID] = [];
    }
    this.gametypes = [];
    this.gamegroudty = [];
    for (let index = 0; index < data.gametypes.length; index++) {
      const element = data.gametypes[index];
      this.gametypes[index] = element;
      this.gamegroudty[element.id] = [];
    }
    for (let index = 0; index < data.gamelists.length; index++) {
      const gameitem = data.gamelists[index];
      this.gamelists[gameitem.id] = gameitem;
      const tagitem = this.gametags[gameitem.tag];
      if (tagitem && this.gamegroudta[gameitem.status]) {
        this.gamegroudta[gameitem.status].push(gameitem);
      }
      const tygitem = this.gamegroudty[gameitem.gametype];
      if (tygitem && this.gamegroudty[gameitem.gametype]) {
        this.gamegroudty[gameitem.gametype].push(gameitem);
      }
    }

    for (let index = 0; index < data.popularlist.length; index++) {
      this.popularlist.push(this.gamelists[data.popularlist[index]]);
    }
    CommonData.GameMg = this;
  }

  public OpenGame(gameid: number) {
    if (CommonData.UserInfoData) {
      this.uid = CommonData.UserInfoData.UID;
      CommonData.Common.RequestLoading = true;
      this.getGameUrl({
        gameid, uid: this.uid, callback: (resData: any) => {
          CommonData.Common.RequestLoading = false;
          if (resData.code !== 0) {
            Toast.show(resData.msg)
            // CommonData.Common.PopTips = resData.msg;
          }
          else if (resData.data !== null && resData.data !== '') {
            this.AddGameRecord(gameid);
            this.gameid = gameid;
            this.gameUrl = resData.data;
            CommonData.Common.GoBack('/game');
          }
        }
      });
    }
    else {
      CommonData.Common.ShowLogin = 1;
    }
  }

  public QuieGame() {
    CommonData.Common.RequestLoading = true;
    this.sendQuitGame({
      userid: this.uid, gameid: this.gameid, callback: (code: number, _message: string) => {
        CommonData.Common.RequestLoading = false;
        if (code !== 0) {
          // CommonData.Common.GamePopupData = { title:'Quit Game Erro', message:_message};
          if (_message) {
            Toast.show(_message)
            // CommonData.Common.PopTips = _message;
          }
        }
        else {
          if (CommonData.UserInfoData) {
            CommonData.UserInfoData.RefreshUserInfo();
          }
          CommonData.Common.GoBack();
        }
      }
    });
  }

  // 获取游戏url地址
  private async getGameUrl(action: any) {
    const { gameid, uid, callback } = action;
    const resData: ResponseGenerator = await peekGameUrlAPI({ gameid, uid }).catch(e => e);
    const bdata = { ...resData, gameid };
    callback && await callback(bdata);
  }

  private async sendQuitGame(action: any) {
    const { userid, gameid, callback } = action;
    const resData: ResponseGenerator = await sendQuitGameAPI({ userid, gameid }).catch(e => e);
    const { code, data, msg } = resData;
    if (code === 0) {
      CommonData.UserInfoData = { WalletMoney: data };
    }
    callback && callback(code, msg);
  }

  public async GetGameRecordList() {
    const userinfo = CommonData.UserInfoData;
    if (userinfo && this.gamerecords === null) {
      this.gamerecords = [];
      const resData: ResponseGenerator = await getGameRecordAPI(userinfo.UID).catch(e => e);
      if (resData.code === 0) {
        this.gamerecords = resData.data;
        CommonData.GameMg = this;
      }
      else {
        this.gamerecords = null;
      }
    }
  }
}