import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import classNames from 'classnames/bind';
import style from './index.module.scss';
import { setItem } from '../../../utils/tools';
import { CommonData } from '../../../Common/Common';
import ComState from '../../../Common/ComState';

const logo_text = '/images/common/logo_text.png';
const home = '/images/layout/menu/home.png';
const activity_icon_01 = '/images/layout/menu/activity_icon_01.png';
const activity_icon_02 = '/images/layout/menu/activity_icon_02.png';
const activity_icon_03 = '/images/layout/menu/activity_icon_03.png';
const menu_activity = '/images/layout/menu/menu_activity.png';
const menu_arrow = '/images/layout/menu/menu_arrow.png';
const menu_lang = '/images/layout/menu/menu_lang.png';
const menu_lives = '/images/layout/menu/menu_lives.png';
const menu_original = '/images/layout/menu/menu_original.png';
const menu_recently = '/images/layout/menu/menu_recently.png';
const menu_security = '/images/layout/menu/menu_security.png';
const menu_slot = '/images/layout/menu/menu_slot.png';
const menu_sport = '/images/layout/menu/menu_sport.png';
const menu_vip = '/images/layout/menu/menu_vip.png';
const menu_download = '/images/layout/menu/menu_download.png';

interface MenuLayoutProps {
  showState: boolean,
  updateMenuState: (state: boolean) => void
}

const activityList = [
  {id: '0', bg: activity_icon_01, text: 'Menu.dailyCheckIn'},
  {id: '1', bg: activity_icon_02, text: 'activity.rechargeFundTitle2'},
  {id: '2', bg: activity_icon_03, text: 'Menu.spin'},
];

const initialState = {
  menuList: [
    {id: 0, icon: menu_recently, sort: '0', text: 'Menu.recently', path: '/home'},
    {id: 1, icon: menu_original, sort: '2', text: 'Menu.original', path: '/search/3'},
    {id: 2, icon: menu_slot, sort: '1', text: 'Menu.slots', path: '/search/1'},
    {id: 3, icon: menu_lives, sort: '3', text: 'Menu.live', path: '/search/4'},
    {id: 4, mb: 'mb_13', icon: menu_sport, sort: '4', text: 'Menu.sport', path: '/search/5'},
    {id: 5, mb: 'mb_0', icon: menu_activity, sort: '5', text: 'Menu.bonus', path: '/activity'},
    // {id: 6, mb: 'mb_0', icon: menu_kefu, sort: '6', text: 'Live Support'},
    {id: 7, mb: 'mb_0', icon: menu_vip, sort: '7', text: 'Menu.vip', path: '/personal/vip'},
    {id: 8, mb: 'mb_13', icon: menu_security, sort: '8', text: 'Menu.securityCenter', path: '/personal/securityCenter'},
    {id: 9, mb: 'mb_13', icon: menu_download, sort: '10', text: 'Menu.downloadApp', path: '/downloadTips'},
  ],
  langListState: false,
  showAnimateState: false,     // PS: 当前属性是为了防止弹窗在动画结束前关闭
}
const className = classNames.bind(style);
export default function MenuLayout (props: MenuLayoutProps) {
  const { showState, updateMenuState } = props;
  const { t, i18n } = useTranslation();
  const router = useNavigate();
  const routerPathname = useLocation().pathname;
  const { state, updateState } = ComState(initialState);
  const { menuList, langListState, showAnimateState } = state.mdata;

  useEffect(() => {
    document.body.style.overflow = showState ? 'hidden' : 'auto';
    if(showState) {
      updateState({showAnimateState: showState});
    }
  }, [showState]);

  const langList = useMemo(() => {       // 语言列表
    return Object.keys(i18n?.options?.resources || {})
  }, [i18n])

  const getLangText = (lang: string) => {     // 获取语言类型对应的文本
    switch(lang) {
      case "cn": return '中文';
      case "en": return 'English';
      case "pt": return 'Português';
      case "ind": return 'Bahasa Indonesia';
      case "vi": return 'Việt nam';
      default: return 'English';
    }
  };

  const updateLang = (lang: string) => {
    if (lang !== i18n.language) {
      setItem('language', lang);
      i18n.changeLanguage(lang);
    }
  } 
  // 菜单列表内容跳转
  const gotoPath = (item: any) => {
    closeMune();
    const needLoginState = item.id === 8;        // vip和安全中心需要登录才能跳转进入
    if(!CommonData.UserInfoData && needLoginState) {
      CommonData.Common.ShowLogin = 1;
      return;
    }
    if(item.id < 5) {     // 跳转到search, 游戏列表搜索页
      router(item.path);
      return
    }
    if(item.path) {
      router(item.path);
    }
  }
  // 活动跳转
  const gotoActivity = (item: any) => {
    closeMune();
    if(!CommonData.UserInfoData) {
      CommonData.Common.ShowLogin = 1;
      return;
    }
    switch(item.id) {
      case '0':         // 月签到
        CommonData.Checkin.IsShow = true;
        console.log('click_month_time');
        break;
      case '1':         // 储蓄金
        console.log('act_fund_time');
        CommonData.FirstPay.IsShow = true;
        break;
      case '2':         // 转盘
        CommonData.Rotation.IsShow = true;
        console.log('click_spin');
        break;
    }
  }
  // 跳转到首页
  const gotoHome = () => {
    if(routerPathname === '/home' || routerPathname === '/') {
      closeMune();
    }
    else{
      updateMenuState(false);
      router('/');
    }
  }
  // 关闭菜单栏
  const closeMune = () => {
    updateState({showAnimateState: false});
    setTimeout(() => {
      updateMenuState(false);
    }, 200);
  }
  return (
    <div className={className('menu_box')} style={{visibility: showState ? 'visible' : 'hidden'}}>
      <div className={className('menu_mask')} onClick={closeMune}></div>
      <div className={className('menu_content_wrap', {show_animate: showAnimateState})}>
        <div className={className('menu_top_wrap')}>
          <div className={className('logo_img')}>
            <img src={logo_text} alt='logo_text'/>
          </div>
          <div className={className('home_wrap')} onClick={gotoHome}>
            <img src={home} alt='home'/>
            <span>{t('Menu.home')}</span>
          </div>
        </div>
        <div className={className('menu_activity_wrap')}>
          {
            activityList.map((item: any, i: number) => {
              return (
                <div key={i} className={className('menu_activity_item', {allWidth: i === activityList.length - 1})} onClick={() => gotoActivity(item)}>
                  <div className={className('menu_activity_bg')}>
                    <img src={item.bg} alt="activity_bg"/>
                  </div>
                  <div className={className('menu_activity_content_wrap')}>
                    <span>{t(item.text)}</span>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className={className('menu_list_wrap')}>
          {
            menuList.map((item: any, i: number) => {
              return (
                <div
                  key={i}
                  className={className('menu_item_wrap', item?.mb)}
                  onClick={() => gotoPath(item)}
                  style={{order: item.sort}}>
                  <div className={className('menu_left_icon')}>
                    <img src={item.icon} alt={'menu_left_icon'}/>
                  </div>
                  <div className={className('menu_center_text')}>{t(item.text)}</div>
                </div>
              )
            })
          }
          <div
            className={className('menu_item_wrap', 'mb_13', {menu_item_wrap_show: langListState})}
            style={{order: menuList.length - 1, flexWrap: 'wrap'}}>
            <div className={className('menu_left_icon')}>
              <img src={menu_lang} alt={'menu_lang'}/>
            </div>
            <div className={className('menu_center_text')} onClick={() => updateState({langListState: !langListState})}>{t('Menu.language')}</div>
            <div className={className('menu_right_icon', {menu_right_icon_show: langListState})}>
              <img src={menu_arrow} alt='menu_right_icon'/>
            </div>
            <div className={className('menu_select_list')}>
              { langListState &&
                langList.map((item: string, index: number) => {
                  return (
                    <div key={index} className={className('menu_select_item', {menu_select_item_active: item === i18n.language})} onClick={(e) => {e.stopPropagation(); updateLang(item)}}>{getLangText(item)}</div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
