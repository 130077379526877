import classnames from 'classnames/bind';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import Input from '../../Input';
import { useEffect } from 'react';
import { CommonData } from '../../../Common/Common';
import NewHttpNet from '../../../api/HttpNetMg';
import { ResponseGenerator } from '../../../api/types';
import ComState from '../../../Common/ComState';
import { Toast } from 'antd-mobile';

const className = classnames.bind(styles);
const closeIcon = '/images/activityModule/close.png';
const game_type_btn_bg_01 = '/images/home/gametype/game_type_btn_bg_01.png';

function GetLuckCode(data: any) {
  return NewHttpNet({
    url: '/luckycode/receive',
    method: 'post',
    data: { ...data },
  })
}

const initialState = {
  authcode: '',
  authcodetag: { flag: false, errMsg: '' },
};

const LuckyCode = () => {
  const { t } = useTranslation()
  const { state, updateState } = ComState(initialState);
  const { authcode, authcodetag } = state.mdata;
  const hidePop = () => {
    CommonData.Common.ShowLuckyCode = false;
  };

  const GetGiftByCode = async () => {
    if (!CommonData.UserInfoData) {
      CommonData.Common.ShowLogin = 1;
      hidePop();
      return;
    }
    if (authcode === '') {
      updateState({ authcodetag: { flag: true, errMsg: t('LuckyCode.LuckyCodeEmpty') } });
    }
    else {
      updateState({ authcodetag: { flag: false, errMsg: '' } });
      const resData: ResponseGenerator = await GetLuckCode({ CodeID: authcode, UID: CommonData.UserInfoData.UID });
      if (resData.code === 0) {
        const { WalletMoney, BonusAmount } = resData.data;
        CommonData.UserInfoData = { WalletMoney }
        Toast.show('You receive ' + BonusAmount + ' rewards')

        // CommonData.Common.PopTips = 'You receive '+ BonusAmount +' rewards';
      }
      else {
        Toast.show(resData.msg)

        // CommonData.Common.PopTips = resData.msg;
      }
    }
  }

  useEffect(() => {
    if (authcode !== '') {
      updateState({ authcodetag: { flag: false, errMsg: '' } });
    }
  }, [authcode]);

  return (
    <div className={className('popop_box')}>
      <div className={className('popup_content')}>
        <div className={className('close_icon')} onClick={hidePop}>
          <img src={closeIcon} />
        </div>
        <div className={className('title')}>
          {t('LuckyCode.Title')}
        </div>

        <div className={className('inputdiv')}>
          <Input
            required
            mode='general'
            label={t('LuckyCode.InputTip')}
            value={authcode}
            changeInputValue={value => updateState({ authcode: value })}
            invalidFlag={authcodetag.flag}
            invalidMsg={authcodetag.errMsg}
            placeholder={t('LuckyCode.InputTip')}
            inputBlockStyle={{ width: '100%' }}
          />
        </div>
        <div className={className('confirm_btn')} onClick={GetGiftByCode}>
          <img src={game_type_btn_bg_01} alt='game_type_btn_bg_01' />
          <span>{t('wallet.confirm')}</span>
        </div>
      </div>
    </div>
  )
}

export default LuckyCode;