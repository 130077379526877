import { Toast } from "antd-mobile";
import NewHttpNet from "../api/HttpNetMg";
import { ResponseGenerator } from "../api/types";
import { CommonData } from "./Common";

function getCheckinConfig() {
    return NewHttpNet({
        url: '/dailycheckinconfig/getinfo',
        method: 'post'
    })
}

function receiveaWard(userid: number) {
    return NewHttpNet({
        url: '/dailycheckin/receiveaward',
        method: 'post',
        data: { userid }
    })
}

function receiveSigninreWard(userid: number) {
    return NewHttpNet({
        url: '/dailycheckin/receivesigninreward',
        method: 'post',
        data: { userid }
    })
}

function getUserCheckin(userid: number) {
    return NewHttpNet({
        url: '/dailycheckin/getinfo',
        method: 'post',
        data: { userid }
    })
}

export default class CheckinData {
    private static instance: any = null;
    public static get Instance(): CheckinData {
        if (this.instance == null) {
            this.instance = new CheckinData();
        }
        return this.instance;
    }

    private dataConfig: any;
    private userData: any;
    private isShow = false;

    public get IsShow(): boolean {
        return this.isShow;
    }

    public set IsShow(v: boolean) {
        this.isShow = v;
        CommonData.Checkin = this;
    }

    public get DataConfig(): any {
        return this.dataConfig;
    }

    public get UserData(): any {
        return this.userData;
    }

    public async GetCheckinConfig() {
        if (!this.dataConfig) {
            const resData: ResponseGenerator = await getCheckinConfig().catch(error => error);
            if (resData.code === 0) {
                this.dataConfig = resData.data;
                CommonData.Checkin = this;
            }
        }
    }

    public async GetUserCheckin() {
        const userinfo = CommonData.UserInfoData;
        if (userinfo) {
            const resData: ResponseGenerator = await getUserCheckin(userinfo.UID).catch(error => error);
            if (resData.code === 0) {
                this.userData = resData.data;
                CommonData.Checkin = this;
            }
        }
    }

    public async ReceiveaWard(backfunc?: any) {
        const userinfo = CommonData.UserInfoData;
        if (userinfo) {
            const resData: ResponseGenerator = await receiveaWard(userinfo.UID).catch(error => error);
            if (resData.code === 0) {
                backfunc?.();
                this.GetUserCheckin();
            }
            else {
                Toast.show(resData.msg)

                // CommonData.Common.PopTips = resData.msg;
            }
        }
    }

    public async ReceiveSigninreWard(backfunc?: any) {
        const userinfo = CommonData.UserInfoData;
        if (userinfo) {
            const resData: ResponseGenerator = await receiveSigninreWard(userinfo.UID).catch(error => error);
            if (resData.code === 0) {
                backfunc?.();
                this.GetUserCheckin();
            }
            else {
                Toast.show(resData.msg)
                // CommonData.Common.PopTips = resData.msg;
            }
        }
    }

    public Quit() {
        this.isShow = false;
        this.userData = null;
    }
}