import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import style from './index.module.scss';
import { CommonData } from "../../../../Common/Common";
import { useNavigate } from "react-router-dom";
/** images */
const activity_hot_icon = '/images/activity/activity_hot_icon.png';
const activity_new_icon = '/images/activity/activity_new_icon.png';
const activity_icon_01 = '/images/activity/activity_icon_01.png';
const activity_icon_02 = '/images/activity/activity_icon_02.png';
const activity_icon_03 = '/images/activity/activity_icon_03.png';
const activity_icon_04 = '/images/activity/activity_icon_04.png';
const activity_icon_05 = '/images/activity/activity_icon_05.png';
const activity_icon_06 = '/images/activity/activity_icon_06.png';

const className = classNames.bind(style);
const activityList = [
  {id: 0, key:'LuckyCode', titleIcon: 'activity.luckycode', status: 'activity.inProgess', intro: 'activity.luckycode', icon: activity_icon_01, tag: 'HOT',tagBg: activity_hot_icon},
  {id: 1, key:'RechargeFund', titleIcon: 'activity.rechargeFundTitle2', status: 'activity.inProgess', intro: 'activity.rechargeFundTitle', icon: activity_icon_02, tag: 'HOT',tagBg: activity_hot_icon},
  {id: 2, key:'DailyCheckIn', titleIcon: 'activity.dailyBonusTitle2', status: 'activity.inProgess', intro: 'activity.dailyBonusTitle', icon: activity_icon_03, tag: 'NEW',tagBg: activity_new_icon},
  {id: 3, key:'NoviceTask', titleIcon: 'activity.noviceBonus2', status: 'activity.inProgess', intro: 'activity.noviceBonus', icon: activity_icon_04, tag: 'HOT',tagBg: activity_hot_icon},
  {id: 4, key:'DailyRotation', titleIcon: 'activity.wheelBonus2', status: 'activity.inProgess', intro: 'activity.wheelBonus', icon: activity_icon_05, tag: 'HOT',tagBg: activity_hot_icon},
  {id: 5, key:'AffiliateAgent', titleIcon: 'activity.affiliate2', status: 'activity.inProgess', intro: 'guide.affiliate', icon: activity_icon_06, tag: 'HOT',tagBg: activity_hot_icon},
]
export default function ActivityList() {
  const { t } = useTranslation();
  const rotation = CommonData.Rotation;
  const router = useNavigate();
  const common = CommonData.Common;
  /** function */
  const onGoActivity = (item: any) => {
    if(!CommonData.UserInfoData) {
      common.ShowLogin = 1;
      return;
    }
    switch(item.id) {
      case 0:         // 幸运码
        console.log('click_newplay_time')
        CommonData.Common.ShowLuckyCode = true;
        break;
      case 1:         // 首充
        console.log('act_fund_time')
        CommonData.FirstPay.IsShow = true;
        break;
      case 2:         // 月签到
        CommonData.Checkin.IsShow = true;
        break;
      case 3:    // 新手任务
        router('/task');
        break;
      case 4:         // 转盘
        rotation.IsShow = true;
        break;
      case 5:
        router('/agent');
        break;
    }
  }

  const activityArray = useMemo(()=>{
    const datalist = [];
    const activityConfi:any = common.ClientConfig?.ActivityConfig;
    if(activityConfi){
      for (let index = 0; index < activityList.length; index++) {
        if(activityConfi[activityList[index].key]){
          datalist.push(activityList[index]);
        }
      }
    }
    return datalist;
  },[activityList, common.ClientConfig])

  useEffect(()=>{
    CommonData.Common.GetClientConfig();
  },[]);

  return (
    <div className={className("activity_list_box")}>
      <div className={className("activity_list_title")}>{t('activity.bonusCenter')}</div>
      <div className={className("activity_list_wrap")}>
        {
          activityArray.length > 0 && activityArray.map((item: any, i: number) => {
            return (
              <div key={i} className={className("activity_item_wrap")}>
                <div className={className("activity_tag_wrap")}>
                  <img src={item.tagBg} alt='tagBg'/>
                  <div className={className("tag_text")}>{item.tag}</div>
                </div>
                <div className={className("activity_top_wrap")} onClick={() => onGoActivity(item)}>
                  <div className={className("activity_bg")}>
                    <img src={item.icon} alt='activity_bg'/>
                  </div>
                  <div className={className("activity_content_wrap")}>
                    <div className={className("activity_title_text")}>
                      <span>{t(item.titleIcon)}</span>
                    </div>
                    <div className={className("activity_btn_wrap")} >{t('game.more')} ▶</div>
                  </div>
                </div>
                <div className={className("activity_bottom_wrap")}>
                  <div className={className("left_wrap")}>
                    <div className={className("bet_win_text")}>{t(item.intro)}</div>
                  </div>
                  <div className={className("right_status_wrap")}>{t(item.status)}</div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
