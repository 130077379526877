import React, { Suspense } from 'react';
import { HashRouter, Route, Routes } from "react-router-dom";
import Loading from '../components/Loading'
import Purse from '../pages/purse';
import OldActivity from '../pages/oldactivity';
import Bincards from '../pages/wallet/withdraw/bincards';
let EverydaySign = React.lazy(() => import('../pages/everydaySign'));
let HistoryDetail = React.lazy(() => import('../pages/history'));
let Promotion = React.lazy(() => import('../pages/promotion'));
let NewerTask = React.lazy(() => import('../pages/newerTask'));
let EventHall = React.lazy(() => import('../pages/eventHall'));
let Membership = React.lazy(() => import('../pages/membership'));
let EmailDetail = React.lazy(() => import('../pages/emailDetal'));
let RotationPlate = React.lazy(() => import('../pages/rotationPlate'));
let EmailInfo = React.lazy(() => import('../pages/emailInfo'));
let Home = React.lazy(() => import('../pages/home'));
let User = React.lazy(() => import('../pages/user'));
let Game = React.lazy(() => import('../pages/game'));
let Search = React.lazy(() => import('../pages/search'));
let Personal = React.lazy(() => import('../pages/personal'));
let Task = React.lazy(() => import('../pages/task'));
let Notifications = React.lazy(() => import('../pages/notifications'));
let Activity = React.lazy(() => import('../pages/activity'));
let Wallet = React.lazy(() => import('../pages/wallet'));
let Aboutus = React.lazy(() => import('../pages/aboutus'));
let SecurityCenter = React.lazy(() => import('../pages/personal/securityCenter'));
let Vip = React.lazy(() => import('../pages/personal/vip'));
let Agent = React.lazy(() => import('../pages/agent'));
let MonthSign = React.lazy(() => import('../pages/activity/monthSign'));

export default function RouterView(children: any) {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Suspense fallback={<Loading />}><Home /></Suspense>}></Route>
        <Route path="/home" element={<Suspense fallback={<Loading />}><Home /></Suspense>}></Route>
        <Route path="/user" element={<Suspense fallback={<Loading />}><User /></Suspense>}></Route>
        <Route path="/home/:uid" element={<Suspense fallback={<Loading />}><User /></Suspense>}></Route>
        <Route path="/game" element={<Suspense fallback={<Loading />}><Game /></Suspense>}></Route>
        <Route path="/search" element={<Suspense fallback={<Loading />}><Search /></Suspense>}></Route>
        <Route path="/search/:categroy" element={<Suspense fallback={<Loading />}><Search /></Suspense>}></Route>
        <Route path="/personal" element={<Suspense fallback={<Loading />}><Personal /></Suspense>}></Route>
        <Route path="/task" element={<Suspense fallback={<Loading />}><Task /></Suspense>}></Route>
        <Route path="/notifications" element={<Suspense fallback={<Loading />}><Notifications /></Suspense>}></Route>
        <Route path="/activity" element={<Suspense fallback={<Loading />}><Activity /></Suspense>}></Route>
        <Route path="/wallet" element={<Suspense fallback={<Loading />}><Wallet /></Suspense>}></Route>
        <Route path="/wallet/:type" element={<Suspense fallback={<Loading />}><Wallet /></Suspense>}></Route>
        <Route path="/aboutus" element={<Suspense fallback={<Loading />}><Aboutus /></Suspense>}></Route>
        <Route path="/personal/securityCenter" element={<Suspense fallback={<Loading />}><SecurityCenter /></Suspense>}></Route>
        <Route path="/personal/securityCenter/:type" element={<Suspense fallback={<Loading />}><SecurityCenter /></Suspense>}></Route>
        <Route path="/personal/vip" element={<Suspense fallback={<Loading />}><Vip /></Suspense>}></Route>
        <Route path="/agent" element={<Suspense fallback={<Loading />}><Agent /></Suspense>}></Route>
        <Route path="/activity/monthSign" element={<Suspense fallback={<Loading />}><MonthSign /></Suspense>}></Route>
        <Route path="/everySign" element={<Suspense fallback={<Loading />}><EventHall /></Suspense>}></Route>
        <Route path="/everyDaySign" element={<Suspense fallback={<Loading />}><EverydaySign /></Suspense>}></Route>
        <Route path="/memberShip" element={<Suspense fallback={<Loading />}><Membership /></Suspense>}></Route>
        {/* <Route path="/purse" element={<Suspense fallback={<Loading />}><Purse /></Suspense>}></Route> */}
        <Route path="/purse" element={<Suspense fallback={<Loading />}><Wallet /></Suspense>}></Route>
        <Route path="/purse/:type" element={<Suspense fallback={<Loading />}><Wallet /></Suspense>}></Route>
        <Route path="/historyDetail" element={<Suspense fallback={<Loading />}><HistoryDetail /></Suspense>}></Route>
        <Route path="/emails" element={<Suspense fallback={<Loading />}><EmailInfo /></Suspense>}></Route>
        <Route path="/emailDetail" element={<Suspense fallback={<Loading />}><EmailDetail /></Suspense>}></Route>
        <Route path="/oldActivity" element={<Suspense fallback={<Loading />}><OldActivity /></Suspense>}></Route>
        <Route path="/rotationPlate" element={<Suspense fallback={<Loading />}><RotationPlate /></Suspense>}></Route>
        <Route path="/newerTask" element={<Suspense fallback={<Loading />}><NewerTask /></Suspense>}></Route>
        <Route path="/promotion" element={<Suspense fallback={<Loading />}><Promotion /></Suspense>}></Route>
      </Routes>
    </HashRouter>
  );
};