import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from "react-router-dom";
import classnames from 'classnames/bind';
import styles from './index.module.scss';
import HeaderRight from '../../layout/Header/MuseHeader';
import { Button, Popup } from "antd-mobile";
import Panel from '../../pages/panel';

const ImgLogo = '/images/home/logo.png';
const ImgReturn = '/images/common/arrow_left_white.png';


const classNames = classnames.bind(styles)

const HeaderBar = () => {
  /* i18n */
  const { t } = useTranslation();
  /* router */
  const router = useNavigate();
  const location = useLocation().pathname;

  const logoValid = useMemo(() => {
    const pathList = ['/', '/home', '/activity', '/search'];
    let showLogo = location === '/';
    if (!showLogo) {
      for (let index = 0; index < pathList.length; index++) {
        if (location.startsWith(pathList[index])) {
          showLogo = true;
          break;
        }
      }
    }
    return {
      src: showLogo ? ImgLogo : ImgReturn,
      alt: showLogo ? 'Muse' : 'Back to Home',
      showLogo,
    }
  }, [location])

  const onClickLogo = useCallback(() => {
    router('/');
  }, [logoValid])

  // 左侧滑动面板
  const [visible3, setVisible3] = useState(false)
  const [visibleCloseRight, setVisibleCloseRight] = useState(false)

  return (
    <>
      <nav className={classNames("nav_container")}>
        {/* <span className={classNames('fz28', 'fff')} >{t('home.addDeskTip')}</span>
        <div className={classNames('df', 'aic')}>
          <button className={classNames('addhome', 'fff', 'bdn', 'nav_button')}>{t('home.addHome')}</button>
          <i className={classNames('iconfont', 'icon-qvxiao', 'fff', 'pl23')} ></i>
        </div> */}
        {/* <Button className={classNames('nav_button')} >click</Button> */}
        <div className={classNames('logo_section')}
        // onClick={() => onClickLogo()}
        >
          <img
            className={classNames('brand-logo')}
            src={logoValid.src}
            alt={logoValid.alt}
            onClick={() => {
              setVisible3(!visible3)
            }}
          />
        </div>
        <HeaderRight />
        <Popup
          visible={visible3}
          onMaskClick={() => {
            setVisible3(false)
          }}
          position='left'
          bodyStyle={{ width: '60vw' }}
          className={classNames('left-panel')}
        >
          <Panel hidePanel={() => { setVisible3(false) }} />
        </Popup>
      </nav>
    </>
  );
}

export default HeaderBar;
