import classNames from "classnames/bind";
import style from './index.module.scss';

const className = classNames.bind(style);

interface ButtonProps {
  text: string,                           // 按钮文本
  classname: any,                         // 按钮样式类
  onClickBack: (data: any) => void,       // 按钮点击事件回调
  icon?: any,                 // 按钮图标
  iconDir?: string,                       // 按钮上图标的位置(值: left/right)
  buttonBg?: any,             // 按钮背景图
  style?: any
  textStyle?: any                         // 按钮文本样式
  iconStyle?: any                         // 图标样式
}
export default function Button(props: ButtonProps) {
  const {text, icon, iconDir = 'left', buttonBg, onClickBack, classname, style, textStyle, iconStyle} = props;
  return (
    <div className={classname + ' ' + className('btn_wrap')} onClick={onClickBack} style={style}>
      {buttonBg && <img src={buttonBg} alt='buttonBg'/>}
      <div className={className("btn_content_wrap")}>
        {icon && <img src={icon} alt='icon' style={Object.assign({}, iconStyle, {order: iconDir === 'right' ? '2' : '0'})}/>}
        <span style={textStyle}>{text}</span>
      </div>
    </div>
  )
}
