import { useTranslation } from 'react-i18next'
import classnames from 'classnames/bind'
import style from './index.module.scss'
import PopupContainer from '../../../Popup/PopupContainer'
import Button from '../../../../components/Button';
import { CommonData } from '../../../../Common/Common';
 /** image */
const popupCloseBtn = '/images/common/close_white.png'


 const className = classnames.bind(style)

 interface SpinPopProps {
   hidePop: () => void,
 }
const SpinPop = (props: SpinPopProps) => {
  const { hidePop } = props;
  const rotation = CommonData.Rotation;
   /* i18n */
   const { t } = useTranslation()
  /* function */
  const onConfirm = () => {
    hidePop();
    rotation.IsShow = false;
    CommonData.Common.GoBack('wallet');
  }

  return (
    <PopupContainer bgStyle={undefined} closeIcon={popupCloseBtn} close={hidePop} outsideClick={false}>
    <section className={className('pop_top_wrap')}>
      <div className={className('pop_title_text')}>{t("spinActivity.hint")}</div>
    </section>
    <section className={className('pop_content_wrap')}>
      <p>{t('exceptionErrorMsg.spinError2')}</p>
      <Button
        classname={className('confirm_btn')}
        onClickBack={onConfirm}
        text={t('spinActivity.recharge')}
        ></Button>
    </section>
    </PopupContainer>
  )
}

export default SpinPop
