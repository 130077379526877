// 新手活动
import { Toast } from "antd-mobile";
import NewHttpNet from "../api/HttpNetMg";
import { ResponseGenerator } from "../api/types";
import { CommonData } from "./Common";

function getNoviceTaskConfig() {
    return NewHttpNet({
        url: '/novicetaskconfig/getall',
        method: 'post',
    })
}

function getUserNoviceTask(userid: number) {
    return NewHttpNet({
        url: '/novicetask/getnovicetask',
        method: 'post',
        data: { userid }
    })
}

function receiveaWardTask(ndata: any) {
    return NewHttpNet({
        url: '/novicetask/receiveaward',
        method: 'post',
        data: ndata
    })
}


export default class NoviceTaskData {
    private static instance: any = null;
    public static get Instance(): NoviceTaskData {
        if (this.instance == null) {
            this.instance = new NoviceTaskData();
        }
        return this.instance;
    }

    private taskConfig: any;
    private userTask: any;

    public get TaskConfig(): any {
        return this.taskConfig;
    }

    public get UserTask(): any {
        return this.userTask || {};
    }

    public async GetTaskConfig() {
        if (!this.taskConfig) {
            const resData: ResponseGenerator = await getNoviceTaskConfig().catch(error => error);
            if (resData.code === 0) {
                this.taskConfig = resData.data;
                CommonData.Novice = this;
            }
        }
    }

    public async GetUserNoviceTask() {
        if (CommonData.UserInfoData) {
            const resData: ResponseGenerator = await getUserNoviceTask(CommonData.UserInfoData.UID).catch(error => error);
            if (resData.code === 0) {
                this.userTask = resData.data;
                CommonData.Novice = this;
            }
        }
    }

    public async ReceiveaWardTask(tasktype: string, receiveFunc: any) {
        if (CommonData.UserInfoData) {
            const resData: ResponseGenerator = await receiveaWardTask({
                tasktype,
                uid: CommonData.UserInfoData.UID
            }).catch(error => error);
            if (resData.code === 0) {
                this.GetUserNoviceTask();
                Toast.show({
                    content: 'Received successfully!',
                    maskClassName: 'custom-tip',
                })
                receiveFunc();
            }
            else {
                Toast.show(resData.msg)

                // CommonData.Common.PopTips = resData.msg;
            }
        }
    }

    public Quit() {
        this.userTask = null;
    }
}