import NewHttpNet from "../api/HttpNetMg";
import { ResponseGenerator } from "../api/types";
import { CommonData } from "./Common";

// 获取VIP列表
export const getVipListAPI = async () => {
    return NewHttpNet({
        url: '/vip/getall',
        method: "post",
    });
}

export default class VipData {
    private static instance: any = null;
    public static get Instance(): VipData {
        if (this.instance == null) {
            this.instance = new VipData();
        }
        return this.instance;
    }

    private config: any;
    public get Config(): any {
        return this.config;
    }

    public async GetConfig() {
        // if (!this.config) {
        const resData: ResponseGenerator = await getVipListAPI().catch(error => error);
        if (resData.code === 0) {
            this.config = resData.data;
            CommonData.Vip = this;
        }
        // }
    }
}