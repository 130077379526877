import classnames from 'classnames/bind'
import style from './index.module.scss'
import { useNavigate } from 'react-router-dom'
const className = classnames.bind(style)

const Panel = ({ hidePanel }: { hidePanel: any }) => {
    const router = useNavigate();
    const jumpCashback = () => {
        router('/memberShip');
        setTimeout(() => {
            const element = document.getElementById('cashback');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }
    const jumpluckCode = () => {
        router('/everySign');
        setTimeout(() => {
            const element = document.getElementById('luckcode');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }
    const recentGame = () => {
        hidePanel()
        router('/', { replace: true });
        setTimeout(() => {
            const element = document.getElementById('recentgame');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }
    return (
        <div className={className('pt20', 'posr', 'w100')} >
            {/* <div className={className('df', 'jcb', 'aic', 'pl25', 'pr25',)} >
                <img className={className('logn_img')} src="/images/home/logo.png" alt="" />
                <div className={className('df', 'jcb', 'aic', 'posr')} >
                    <div   >
                        <img className={className('posa', 'avatar')} src="/images/panel/avatar.png" alt="" />
                        <div className={className('wallet', 'df', 'jcc', 'aic', 'mr20', 'posr')} >
                            <img className={className('gold-egg', 'mr10')} src="/images/share/gold-egg.png" alt="" />
                            <span className={className('fz33', 'cFFBE00')} >13456.78</span>
                            <span className={className('posa', 'add-icon')} >+</span>
                        </div>
                    </div>
                    <div className={className('posr', 'ml40')} >
                        <img className={className('email')} src="/images/panel/email.png" alt="" />
                        <span className={className('red-dot')} ></span>
                    </div>
                </div>
            </div> */}
            <div className={className('',)} >
                <div className={className('fff', 'df', 'aic', 'fdc', 'b272624')} >
                    <div onClick={() => { router('/memberShip') }} className={className('vip-bonus', 'color-box', 'fz28')} >
                        VIP BONUS
                    </div>
                    <div onClick={jumpCashback} className={className('cash-back', 'color-box', 'fz28')} >
                        CASH BACK
                    </div>
                    <div onClick={() => { router('/rotationPlate') }} className={className('spin', 'color-box', 'fz28')} >
                        Spin to win <span className={className('cFFBE00')} >1 BTC</span>
                    </div>
                    <div onClick={jumpluckCode} className={className('coupon', 'color-box', 'fz28')} >
                        Coupon collection
                    </div>
                    <div onClick={() => router('/')} className={className('game-center', 'color-box', 'fz28')} >
                        Games <br />
                        Center
                    </div>
                    <div onClick={() => router('/everySign')} className={className('promotion', 'color-box', 'fz28')} >
                        Promotions <br />
                        Center
                    </div>
                    <div className={className('c8A', 'df', 'fdc', 'ais', 'w100')}>
                        <p onClick={() => router('/')} className={className('')}><span className={className('iconfont', 'pl20', 'mr20', 'icon-shouye')} ></span>
                            <span className={className('fz28', 'c8A')} >Start      </span>
                        </p>
                        <p onClick={recentGame} className={className('')}><span className={className('iconfont', 'pl20', 'mr20', 'icon-youxi')} ></span>
                            <span className={className('fz28', 'c8A')} >Recent games</span>
                        </p>
                        <p onClick={() => router('/activity')} className={className('')}><span className={className('iconfont', 'pl20', 'mr20', 'icon-zuixin1')} ></span>
                            <span className={className('fz28', 'c8A')} >Recommend</span>
                        </p>
                        <p onClick={() => router('/everyDaySign')} className={className('')}><span className={className('iconfont', 'pl20', 'mr20', 'icon-mubiao')} ></span>
                            <span className={className('fz28', 'c8A')} >Daily Tasks</span>
                        </p>
                        <p onClick={() => router('/everySign')} className={className('')}><span className={className('iconfont', 'pl20', 'mr20', 'icon-jiangbei')} ></span>
                            <span className={className('fz28', 'c8A')} >Extra Bonus</span>
                        </p>
                        <p onClick={() => hidePanel()} className={className('')}><span className={className('iconfont', 'pl20', 'mr20', 'icon-fuzhi02')} ></span>
                            <span className={className('fz28', 'c8A')} >Pagcor Leg</span>
                        </p>
                        {/* <p onClick={() => router('/')} className={className('')}><span className={className('iconfont', 'pl20', 'mr20', 'icon-youxiang')} ></span>
                            <span className={className('fz28', 'c8A')} >Contact US</span>
                        </p> */}
                    </div>
                </div>
                {/* <div className={className('right-box', 'b272624', 'pl30', 'pr30', 'fz28', 'pt25')} >
                    <p>
                        asfd
                    </p>
                    <p className={className('c8A', 'sdf')} >阿拉山口大姐夫肯德基噶科技是打发阿萨德离开家噶寄快递发撒电极法啊发个链接</p>
                </div> */}
            </div>
        </div>
    )
}

export default Panel;