import classnames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
/**
 * tool
 */
import style from './index.module.scss';
/**
 * utils
 */
const className = classnames.bind(style);
const navList = [
  { label: 'login', value: 1 },
  { label: 'register', value: 2 }
]
// className={className('')}

interface PropsTarget {
  type: number,
  onChange: (value: number) => void
}
export default function Switch(props: PropsTarget) {
  const { t } = useTranslation();
  const { type, onChange } = props;
  return (
    <section className={className('switch_container')}>
      {navList.map((item: any) => (
        <div
          className={className('item', { active: type === item.value }, 'w265')}
          key={item.value}
          onClick={() => onChange(item.value)}>
          {item.label == 'login' ? <span className={className('iconfont', 'mr5', 'icon-denglu1',)}></span> : <span className={className('iconfont', 'mr5', 'icon-bianji')}></span>}
          {t(`login.${item.label}`)}
        </div>
      ))}
    </section>
  )
}
