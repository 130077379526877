import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { getlanguageJson } from '../utils/language';

export const resources = getlanguageJson();

i18n.use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'en', // Set the default language to English
    fallbackLng: 'en', // 如果检测到 LNG 不可用，请使用 EN
    resources,
    returnNull: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })