/**
 * tool
 */
import { useEffect } from 'react';

export function SetAmfeFlexible(){
  useEffect(() => {
    import('amfe-flexible');
  }, [])
}

export function fullscreen(){
  // 动态设置全屏模式
  if(navigator.standalone)
  {
    // 如果已将网站添加到主屏幕，则通过meta标签禁用地址栏和工具栏
    const meta = document.createElement("meta");
    meta.name = "apple-mobile-web-app-capable";
    meta.content = "yes";
    document.head.appendChild(meta);
    // 动态设置全屏模式
    window.addEventListener("load", function() {
        setTimeout(function() {
          window.scrollTo(0, 1);
        }, 0);
    });
  }
  else{
    document.addEventListener('load', function(){
      window.scrollTo(0, 1);
    }, false);
  }
}

//进入全屏
export function requestFullScreen() {
   var de = document.documentElement;
   if (de.requestFullscreen) {
       de.requestFullscreen();
   } else if (de.mozRequestFullScreen) {
       de.mozRequestFullScreen();
   } else if (de.webkitRequestFullScreen) {
       de.webkitRequestFullScreen();
   }
}

//退出全屏
export function exitFullscreen() {
    var de = document;
    if (de.exitFullscreen) {
        de.exitFullscreen();
    } else if (de.mozCancelFullScreen) {
        de.mozCancelFullScreen();
    } else if (de.webkitCancelFullScreen) {
        de.webkitCancelFullScreen();
    }
}

export function SetImgSrcById(imgid, imgsrc){
  document.getElementById(imgid).src = imgsrc;
}