import { useEffect, useRef } from 'react';
import classnames from 'classnames/bind';
import style from './index.module.scss';
import { useTranslation } from 'react-i18next'
import { CommonData } from '../../Common/Common';
const className = classnames.bind(style);

const ConfirmPopup = () => {
  /* ref */
  const refMask = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation()

  /* redux */
  const gamePopupData = CommonData.Common.GamePopupData;
  console.log("=========>>>>gamePopupData: ", gamePopupData);
  const {
    kind = 0,
    title = 'pop title',          // 标题
    message = 'pop message',      // 消息内容
    messageStyle = {},            // 消息内容Style
    minMsg = '',                  // 二级内容
    minMsgStyle = {},               // 二级内容style
    cancelText = t('wallet.cancel'),        // 取消按钮文本
    confirmText = t('wallet.confirm'),      // 确定按钮文本
    cancalCallback = () => ({}),    // 取消按钮回调
    confirmCallback = () => ({}),   // 确定按钮回调
    cancelState = true,           // 是否显示取消按钮
    confirmState = true           // 是否显示确定按钮
} = gamePopupData

  /* effect */
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    }
  }, []);

  const onClosePop = (event: any) => {
    cancalCallback();
    event.stopPropagation();
  }
  const onConfirmPop = (event: any) => {
    confirmCallback();
    event.stopPropagation();
  }

  return (
    <>
      <div className={className("bg")} id="confirmPopup">
        <div ref={refMask} className={className("container")}>
          <div className={className('popup_title')}>{title}</div>
          <div className={className('popup_content_wrap')}>
            <div className={className('popup_content')} style={{alignItems: minMsg === '' ? 'center' : 'flex-start'}}>
              {!message.includes('\n') && <span style={messageStyle}>{kind > 0 ? t(`${message}`) : (message)}</span>}
              {message.includes('\n') &&       // 换行
                <div style={{...messageStyle, marginBottom: '10px'}} >
                  {message.split('\n').map((item:string, i: number) =>
                    <p key={i}>{item}</p>
                  )}
                </div>
              }
              <span className={className('min_hint_msg')} style={Object.assign({}, minMsgStyle, {display: minMsg === '' ? 'none' : 'block'})}>{minMsg}</span>
            </div>
            <div className={className('popup_btn_wrap')}>
              {cancelState && <div className={className('cancel_btn')} onClick={(e) => {onClosePop(e)}}>{cancelText}</div>}
              {confirmState && <div className={className('confirm_btn')} onClick={(e) => {onConfirmPop(e)}}>{confirmText}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmPopup;
