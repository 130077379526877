import { Toast } from "antd-mobile";
import NewHttpNet from "../api/HttpNetMg";
import { ResponseGenerator } from "../api/types";
import { CommonData } from "./Common";

function getRankingData(ndata: any) {
    return NewHttpNet({
        url: "/rank/getrank",
        method: "post",
        data: { ...ndata },
    });
}

export default class RankingData {
    private static instance: any = null;
    public static get Instance(): RankingData {
        if (this.instance == null) {
            this.instance = new RankingData();
        }
        return this.instance;
    }

    private mapRank: any;
    private rankingType: number;
    private rankingList: any;
    constructor() {
        this.mapRank = null;
        this.rankingType = 0;
        this.rankingList = null;
    }

    public get RankingType(): number {
        return this.rankingType;
    }

    public set RankingType(v: number) {
        this.rankingType = v;
        switch (v) {
            case 0:
                this.rankingList = this.BetRank;
                break;
            case 1:
                this.rankingList = this.WealthRank;
                break;
            default:
                this.rankingList = this.RechargeRank;
        }
        CommonData.Rank = this;
    }

    public get RankingList(): any {
        return this.rankingList;
    }

    // 下注输赢榜
    public get BetRank(): any {
        let betrank = null;
        if (this.mapRank) {
            betrank = this.mapRank.bet?.ranklist;
        }
        return betrank !== null ? betrank : [];
    }

    // 财富榜
    public get WealthRank(): any {
        let wealthrank = null;
        if (this.mapRank) {
            wealthrank = this.mapRank.wealth?.ranklist;
        }
        return wealthrank !== null ? wealthrank : [];
    }

    // 充值榜
    public get RechargeRank(): any {
        let rechargerank = null;
        if (this.mapRank) {
            rechargerank = this.mapRank.recharge?.ranklist;
        }
        return rechargerank !== null ? rechargerank : [];
    }

    public async GetRankingData() {
        if (!this.mapRank) {
            const resData: ResponseGenerator = await getRankingData({ ranktype: 'all', timetype: 'week' }).catch(e => e);
            if (resData.code === 0) {
                console.log();
                this.mapRank = resData.data?.MapRank;
                this.RankingType = 0;
            }
            else {
                Toast.show(resData.msg)

                // CommonData.Common.PopTips = resData.msg;
            }
        }
    }
}