/**
 * tool
 */
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import classnames from 'classnames/bind'
import style from './index.module.scss'
import Input from '../../../../components/Input'
import ForgetPwd from '../../../../components/Popup/ForgetPwdPop'
import useSignInMain from '../../../../hooks/login/useSignInMain'
import { AreaCode } from '../../../../Common/StaticData'
import { CommonData } from '../../../../Common/Common'
import SignNameInput from '../SignNameInput'
import useSignNameInput from '../../../../hooks/login/useSignNameInput';
import { Dialog, Radio, Toast, Modal } from 'antd-mobile'
import ForgetPwdPop from '../../../../components/Popup/ForgetPwdPop'
import UserInfoClass from '../../../../Common/UserInfoData'
import ModPassword from '../../../../pages/user/components/modPassword'

const btnBg = '/images/common/opera_btn_bg.png';
const ImgFbBG = '/images/components/authButton/facebook-bg.png'
const ImgGmailBG = '/images/components/authButton/google-bg.png'

const className = classnames.bind(style)

interface popinter {
  showGoogleLogin: (type: string) => void
}

const SignInLayout = (reference: popinter) => {
  const { showGoogleLogin } = reference;
  /* i18n */
  const { t } = useTranslation()
  /* state */
  const [showForgetPwdPop, setShowForgetPwdPop] = useState(false);
  // 国际区号添加
  const [catway, setcatway] = useState(1);
  const [toggleSec, settoggleSec] = useState(false);
  const [interDomain, setinterDomain] = useState(63);


  /* hooks  */
  const { onLogin, onUpdateLoginForm, loginForm, invalidLogin } = useSignInMain();
  const { password } = loginForm

  // const { nameInput, invalidName, onUpdateNameInput, isValidNameInput, GetUserNames } = useSignNameInput();

  // const onCheckLogin = () => {
  //   if (isValidNameInput()) {
  //   onLogin(GetUserNames());
  //   }
  // }

  let [toggleShow, setToggleShow] = useState(false);


  const modifyPwd = () => {
    Modal.show({
      // content: <ForgetPwdPop />,
      content: <ModPassword location='login' />,
      closeOnMaskClick: true,
      className: 'forgetPwd',
      // confirmText: <CancelIcon />
    })
  }
  const CancelIcon = () => {
    return (
      <span className={className('df', 'iconfont', 'icon-qvxiao', 'fff')}></span>
    )
  }

  // form logic
  const [inputPwd, setinputPwd] = useState('');
  const [inputAccount, setInputAccount] = useState('');
  const [isAgreed, setIsAgreed] = useState(true);

  //form validate
  const onCheckLogin = () => {

    if (inputAccount == '') {
      Toast.show({
        content: t('formValidate.mustHaveAccount'),
        maskClassName: 'custom-tip',
      })
      return
    } else {
      // 检查是否全为数字且长度等于10位
      if (/^\d+$/.test(inputAccount)) {
        if (inputAccount.length != 11) {
          Toast.show({
            content: t('formValidate.phoneLengthShort'),
            maskClassName: 'custom-tip',
          })
          return
        }
      } else if (!/@/.test(inputAccount)) {
        Toast.show({
          content: t('formValidate.emailFormatErr'),
          maskClassName: 'custom-tip',
        })
        return
      }


    }
    if (inputPwd == '') {
      Toast.show({
        content: t('login.plsInputPwd'),
      })
      return
    }
    if (!isAgreed) {
      Toast.show({
        content: t('formValidate.plsTickTerms'),
      })
      return
    }

    localStorage.setItem('loginName', inputAccount)
    localStorage.setItem('loginPwd', inputPwd)
    if (CommonData.Common.RequestLoading) return;
    CommonData.Common.RequestLoading = true;
    if (/@/.test(inputAccount)) {
      UserInfoClass.Instance.UserLogin({ loginname: inputAccount, password: inputPwd, regway: 0 });
    } else {
      UserInfoClass.Instance.UserLogin({ loginname: interDomain + inputAccount, password: inputPwd, regway: 0 });

    }
  }


  useEffect(() => {
    let loginName = localStorage.getItem('loginName')
    let loginPwd = localStorage.getItem('loginPwd')
    if (loginName) {
      setInputAccount(loginName)
    }
    if (loginPwd) {
      setinputPwd(loginPwd)
    }
  }, [])

  return (
    <>
      <div className={className('login_wrap')}>
        <div className={className('df', 'jcb', 'aic', 'mb30', 'h52', 'fff', 'pr40', 'logintype')} >
          <div onClick={() => { setcatway(1) }} className={className('df', 'aic')} >
            <span className={className('wh43', 'cat-type', catway == 1 ? 'sel' : '')} ></span>
            <span className={className('fz28', catway == 1 ? 'c8079F7' : '')} >{t('remain.phoneway')}</span>
          </div>
          <div onClick={() => { setcatway(2) }} className={className('df', 'aic')} >
            <span className={className('wh43', 'cat-type', catway == 2 ? 'sel' : '')} ></span>
            <span className={className('fz28', catway == 2 ? 'c8079F7' : '')} >{t('remain.emailway')}</span>
          </div>
        </div>
        <p className={className('fz28', 'fff', '')}>{catway === 1 ? t('remain.phoneNum') : t('remain.emailNum')}</p>
        {
          catway === 1 ?
            <div className={className('df', 'aic', 'bblack', 'bra15', 'mt31')} >
              <div onClick={() => settoggleSec(!toggleSec)} className={className('f1', 'posr', 'df', 'aic', 'pl20', 'left-drawdown')} >
                <span className={className('fz28', 'fff', 'mr35')} >+ {interDomain}</span>
                <span className={className('iconfont', 'icon-gengduo04-copy', 'fz28', 'fff', toggleSec ? 'transformTop' : 'transformBottom')} ></span>
                <div className={className('posa', toggleSec ? '' : 'dn', 'fz28', 'domainCode')} >
                  <div onClick={() => { settoggleSec(false); setinterDomain(63) }} className={className('mb70')} >+63</div>
                  <div onClick={() => { settoggleSec(false); setinterDomain(86) }} className={className('mb70')} >+86</div>
                  <div onClick={() => { settoggleSec(false); setinterDomain(96) }} className={className('mb70')} >+96</div>
                </div>
              </div>
              <input value={inputAccount} onChange={(e) => setInputAccount(e.target.value)} type="text" placeholder={t('login.plsInputPhone')} className={className('h64', 'fff', 'input-style', 'phone-input', 'pl20')} />
            </div> :
            <div className={className('posr', 'mt31', 'h64')} >
              <input value={inputAccount} onChange={(e) => setInputAccount(e.target.value)} type="text" placeholder={t('login.plsInputEmail')} className={className('h64', 'fff', 'input-style', 'login-form-input', 'pl20', 'posa')} />
            </div>
        }

        {/* </div> */}
        <div className={className('posr', '', 'mt20')}>
          <p className={className('forget_password', 'fz30', 'posa')}>
            <span onClick={modifyPwd}> {t('login.forgetPwd')} </span>
          </p>
        </div>
        <p className={className('fz30', 'fff', '', 'mt20', 'mb20')}>{t('remain.formPwd')}</p>
        <div className={className('posr', 'h64')}>
          <input value={inputPwd} onChange={(e) => setinputPwd(e.target.value)} type={toggleShow ? 'text' : 'password'} placeholder={t('login.plsInputPwd')} className={className('fff', 'input-style', 'login-form-input', 'pl20', 'h64', 'sninput')} />
          <div className={className('posa', 'h64', 'df', 'aic', 'jce', 'eye-wrap', '')} >
            <span onClick={() => setToggleShow(!toggleShow)} className={className('iconfont', toggleShow ? 'icon-yingcang' : 'icon-kanjian', 'toggleShow', 'login-form')}></span>
          </div>
        </div>
        <div style={{ 'wordWrap': 'break-word' }} className={className('df', 'aic', '', 'mt40', 'wrap-content')}>
          <Radio checked={isAgreed} onClick={() => setIsAgreed(!isAgreed)} />
          <span className={className('c8A', 'fz28', 'ml10', 'tick-accord')}>
            {t('login.agreeDsc')}
            <span className={className('c5C63FF')}>&nbsp;{t('login.loginTerm')}</span>
          </span>
        </div>
        <div className={className('df', 'jcc', 'mt40')}>
          <button
            className={className('btn_start')}
            // style={{ filter: `grayscale(${CommonData.Common.RequestLoading ? 1 : 0})`, background: `url(${btnBg}) 0 0 / 100% 100%` }}
            onClick={onCheckLogin}>
            {t('login.startGame')}
          </button>
        </div>

        <span className={className('dib', 'split-line')}></span>
        <p className={className('fz36', 'tac', 'c8A', 'mb20')}>
          {t('login.quickLogin')}
        </p>
        <div className={className('df', 'jcc', 'pb40')}>
          <img className={className('quick-login', 'mr10')} src='/images/home/google.png' alt={'facebook'} onClick={() => { }} />
          <img className={className('quick-login')} src='/images/home/facebook.png' alt={'facebook'} onClick={() => { }} />
          {/* <img className={className('quick-login')} src='/images/home/wechat.png' alt={'facebook'} onClick={() => { showGoogleLogin('facebook') }} /> */}
          {/* <img className={className('quick-login')} src='/images/home/tiktok.png' alt={'facebook'} onClick={() => { showGoogleLogin('facebook') }} /> */}
        </div>
      </div>
      {/* {showForgetPwdPop && (<ForgetPwd hidePop={() => { setShowForgetPwdPop(false) }} />)} */}
    </>
  )
}

export default SignInLayout
