import { useState } from "react";
import UserInfoClass from "./UserInfoData";
import { RechargeData } from "./RechargeData";
import BindCardsData from "./BindCardsData";
import CommonClass from "./CommonClass";
import NoviceTaskData from "./NoviceTaskData";
import RankingData from "./RankingData";
import VerificationCode from "./VerificationCode";
import RechargeOrderData from "./RechargeOrderData";
import WithdrawOrderData from "./WithdrawOrderData";
import NoticeData from "./NoticeData";
import MailData from "./MailData";
import RotationData from "./RotationData";
import CheckinData from "./CheckinData";
import GameManger from './GameManger';
import AgentData from "./AgentData";
import FirstChargeData from "./FirstChargeData";
import VipData from "./VipData";
import RecordMoneyData from "./RecordMoneyData";

export function CommonState(stateObj: any) {
    const [comstate, comSetState] = useState(stateObj);
    return { comstate, comSetState };
}

enum tpState {
    common = 0,// 通用配置
    gamemg, // 游戏数据
    user, // 玩家个人信息
    wallet,// 钱包
    cards,// 绑卡
    novice,// 新手活动
    rotation,// 每日大转盘
    checkin,// 每日签到
    rank, // 排行榜
    code,// 验证码
    orderr,// 充值记录
    orderw,// 提现记录
    coinFlow,// 金币流水
    notice,// 系统邮件公告
    mail, // 个人邮件
    agent, // 全民代理
    firstpay,// 首充
    vip,
    record, // 游戏数据相关记录
    length // 枚举长度
}

export class CommonData {
    public static Comstates: any[];
    public static ComSetStates: any[];

    public static Init() {
        this.Comstates = [];
        this.ComSetStates = [];
        for (let index = 0; index < tpState.length; index++) {
            const { comstate, comSetState } = CommonState({ Data: null });
            this.Comstates[index] = comstate;
            this.ComSetStates[index] = comSetState;
        }
    }

    public static get Common(): CommonClass {
        return CommonClass.Instance;
    }

    public static set Common(v: CommonClass) {
        this.ComSetStates[tpState.common]({ Data: v });
    }

    public static get GameMg(): GameManger {
        return GameManger.Instance;
    }

    public static set GameMg(v: GameManger) {
        this.ComSetStates[tpState.gamemg]({ Data: v });
    }

    public static get UserInfoData(): UserInfoClass {
        return this.Comstates[tpState.user]?.Data;
    }

    public static set UserInfoData(v: any) {
        const userinfodt = v ? UserInfoClass.Instance : null;
        if (userinfodt) {
            userinfodt.InitData(v, userinfodt);
        }
        else {
            UserInfoClass.Instance.Quit();
            RechargeOrderData.Instance.Quit();
            WithdrawOrderData.Instance.Quit();
            BindCardsData.Instance.Quit();
            NoticeData.Instance.Quit();
            MailData.Instance.Quit();
            RotationData.Instance.Quit();
            NoviceTaskData.Instance.Quit();
            AgentData.Instance.Quit();
        }
        this.ComSetStates[tpState.user]({ Data: userinfodt });
    }

    public static get WalletData(): RechargeData {
        return RechargeData.Instance;
    }

    public static set WalletData(v: any) {
        this.ComSetStates[tpState.wallet]({ Data: v });
        if (v === null) {
            RechargeData.Instance = null;
        }
    }

    public static get CardsData(): BindCardsData {
        return BindCardsData.Instance;
    }

    public static set CardsData(v: any) {
        this.ComSetStates[tpState.cards]({ Data: v });
        if (v === null) {
            BindCardsData.Instance = null;
        }
    }

    public static get Novice(): NoviceTaskData {
        return NoviceTaskData.Instance;
    }

    public static set Novice(v: NoviceTaskData) {
        this.ComSetStates[tpState.novice]({ Data: v });
    }

    public static get Rotation(): RotationData {
        return RotationData.Instance;
    }

    public static set Rotation(v: RotationData) {
        this.ComSetStates[tpState.rotation]({ Data: v });
    }

    public static get Checkin(): CheckinData {
        return CheckinData.Instance;
    }

    public static set Checkin(v: CheckinData) {
        this.ComSetStates[tpState.checkin]({ Data: v });
    }

    public static get Rank(): RankingData {
        return RankingData.Instance;
    }

    public static set Rank(v: RankingData) {
        this.ComSetStates[tpState.rank]({ Data: v });
    }

    public static get Code(): VerificationCode {
        return VerificationCode.Instance;
    }

    public static set Code(v: VerificationCode) {
        this.ComSetStates[tpState.code]({ Data: v });
    }

    public static get Orderr(): RechargeOrderData {
        return RechargeOrderData.Instance;
    }

    public static set Orderr(v: RechargeOrderData) {
        this.ComSetStates[tpState.orderr]({ Data: v });
    }
    public static get CoinFlow(): RechargeOrderData {
        return RechargeOrderData.Instance;
    }

    public static set CoinFlow(v: RechargeOrderData) {
        this.ComSetStates[tpState.coinFlow]({ Data: v });
    }

    public static get Orderw(): WithdrawOrderData {
        return WithdrawOrderData.Instance;
    }

    public static set Orderw(v: WithdrawOrderData) {
        this.ComSetStates[tpState.orderw]({ Data: v });
    }

    public static get Notice(): NoticeData {
        return NoticeData.Instance;
    }

    public static set Notice(v: NoticeData) {
        this.ComSetStates[tpState.notice]({ Data: v });
    }

    public static get Mail(): MailData {
        return MailData.Instance;
    }

    public static set Mail(v: MailData) {
        this.ComSetStates[tpState.mail]({ Data: v });
    }

    public static get Agent(): AgentData {
        return AgentData.Instance;
    }

    public static set Agent(v: AgentData) {
        this.ComSetStates[tpState.agent]({ Data: v });
    }

    public static get FirstPay(): FirstChargeData {
        return FirstChargeData.Instance;
    }

    public static set FirstPay(v: FirstChargeData) {
        this.ComSetStates[tpState.firstpay]({ Data: v });
    }

    public static get Vip(): VipData {
        return VipData.Instance;
    }

    public static set Vip(v: VipData) {
        this.ComSetStates[tpState.vip]({ Data: v });
    }


    public static get Record(): RecordMoneyData {
        return RecordMoneyData.Instance;
    }

    public static set Record(v: RecordMoneyData) {
        this.ComSetStates[tpState.record]({ Data: v });
    }
}